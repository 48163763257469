<template>
  <!-- <hello-world /> -->
  <div class="px-2">
    <v-app-bar
      color="white"
      light
      flat
    >

      <div>
        <v-img class="logo_img"
          src="../assets/legallync-logo-dark.png"
        ></v-img>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-container class="auth_container">
      <v-row>
        <v-col cols="12" lg="6">
          <div class="form_container">
            <h1 class="auth_title">Register Now</h1>
            <p class="auth_subtitle font-weight-medium">Legal connection made easy!</p>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="auth_form"
            >
              <div>
                <v-text-field
                  v-model="email"
                  label="Email"
                  :rules="[rules.required, rules.emailRules]"
                  filled
                  dense
                  class="some-style"
                ></v-text-field>
              </div>
              <div>
                <v-text-field
                  v-model="password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  label="Password"
                  filled
                  dense
                  class="some-style"
                  @click:append="show = !show"
                ></v-text-field>
              </div>
              <div>
                <p class="font-weight-medium">By clicking any button below, you acknowledge that you have read the <router-link class="body_link" to="">Privacy Policy</router-link> and agree to the <router-link class="body_link" to="">Terms of Service.</router-link></p>
              </div>
              <v-btn
                color="#023E7D"
                :disabled="!valid"
                large
                block
                elevation="0"
                class="mt-5 client_btn"
                @click="clientReg"
              >
                Continue as Client
              </v-btn>
              <v-btn
                color="#099FB2"
                :disabled="!valid"
                large
                block
                outlined
                elevation="0"
                class="mt-5 auth_btn"
                @click="attorneyReg"
              >
                Continue as Attorney
              </v-btn>
            </v-form>
            <p class="font-weight-medium">Do you already have an account? <router-link class="body_link" to="/">Login here</router-link></p>
          </div>
        </v-col>
        <v-col lg="6" class="d-flex justify-center align-center">
          <div class="main_img d-none d-sm-none d-md-flex">
            <v-img
              src="../assets/auth-img.png"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  // import HelloWorld from '../components/HelloWorld'

  export default {
    // name: 'Home',

    components: {
      // HelloWorld,
    },
    data () {
      return {
        valid: true,
        show: false,
        email: '',
        password: '',
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Minimum 8 characters password',
          emailRules: v => /.+@.+\..+/.test(v) || 'Email must be valid',
        },
      }
    },

    methods: {
      clientReg () {
        let formstatus = this.$refs.form.validate()
        if(formstatus != true) return
        let role = 'client'
        this.$store.commit('setUserRole', role);
        this.$router.push({
            name: 'ClientReg',
            params: {
              clientEmail: this.email,
              clientPassword: this.password
            }
        });
      },

      attorneyReg () {
        let formstatus = this.$refs.form.validate()
        if(formstatus != true) return
        let role = 'attorney'
        this.$store.commit('setUserRole', role);
        this.$router.push({
            name: 'AttorneyReg',
            params: {
              attorneyEmail: this.email,
              attorneyPassword: this.password
            }
        });
      },
    },

  }
</script>

<style scoped>
  .logo_img {
    width: 160px;
  }

  .auth_container {
    margin-top: 10vh;
  }

  .auth_title {
    color: #023E7D;
  }

  .auth_subtitle {
    color: #585F6C;
  }

  .auth_form {
    margin-top: 10vh;
  }

  .form_container {
    padding-right: 10vw;
  }

  .body_link {
    text-decoration: none;
    color: #023E7D;
  }

  .client_btn {
    color: #fff;
  }

  .auth_btn {
    margin-bottom: 50px;
  }

  .main_img {
    max-width: 80%;
  }

  .some-style >>> .v-input__slot::before {
    border-style: none !important;
  }

  @media (max-width: 768px) {
    .form_container {
      padding-right: 0;
    }
  }
  
</style>