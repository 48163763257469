<template>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar
            flat
          >
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="calendarCurrentView('day')">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="calendarCurrentView('week')">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="calendarCurrentView('month')">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <v-card-text>
                <span>{{ selectedEvent.date | formatDate}}</span>
                <p class="app_title">Appointment with {{ selectedEvent.name }}</p>
                <v-chip
                  dark
                  :color="selectedEvent.color"
                >
                  <b>{{ selectedEvent.from }} - {{ selectedEvent.to }}</b>
                </v-chip>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                small
                dark
                text
                color="#023E7D"
                class="mt-2"
                elevation="0"
                @click="initialMeeting(selectedEvent.chat)"
                >
                Start Meeting
                <v-icon
                    right
                    small
                    dark
                >
                    mdi-open-in-new
                </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
</template>

<script>
import moment from 'moment'

  export default {
    data: () => ({
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day'
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      currentDate: '',
    //   names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }),
    mounted () {
      this.$refs.calendar.checkChange()
      this.currentDate = moment().format("YYYY-MM-D")
      this.getCalendarData(this.currentDate);
    },
    methods: {
      initialMeeting(item) {
        console.log(item)
        let chat_object = item
        this.$router.push({
            name: 'AttorneyMeet',
            params: {
            displayName: chat_object.display_name,
            roomName: chat_object.room_name,
            email: chat_object.email,
            password: chat_object.password,
            }
        });
      },
      getCalendarData(v) {
          let calendarView = this.$refs.calendar.type
          this.$store
          .dispatch("getAttorneyCalendar", {
            date: v,
            viewType: calendarView,
          })
          .then(() => {
              this.$refs.calendar.checkChange();
              this.updateRange();
          });
      },
      calendarCurrentView(v) {
        this.type = v
        this.$nextTick(() => {
            this.currentDate = this.$refs.calendar.value;
            if(this.currentDate != null) {
                this.getCalendarData(this.currentDate);
            } else {
                this.currentDate = moment().format("YYYY-MM-D")
                this.getCalendarData(this.currentDate);
            }
        });
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
        this.$nextTick(() => {
            this.currentDate = this.$refs.calendar.value;
            this.getCalendarData(this.currentDate);
        });
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
        this.$nextTick(() => {
            this.currentDate = this.$refs.calendar.start;
            this.getCalendarData(this.currentDate);
        });
      },
      prev () {
        this.$refs.calendar.prev()
        this.$nextTick(() => {
            this.currentDate = this.$refs.calendar.value;
            this.getCalendarData(this.currentDate);
        });
      },
      next () {
        this.$refs.calendar.next()
        this.$nextTick(() => {
            this.currentDate = this.$refs.calendar.value;
            this.getCalendarData(this.currentDate);
        });
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      updateRange () {
        const events = []
        // console.log(attorneyCalendar)
        // console.log(this.attorneyCalendar)

        // const min = new Date(`${start.date}T00:00:00`)
        // const max = new Date(`${end.date}T23:59:59`)
        // const days = (max.getTime() - min.getTime()) / 86400000
        // const eventCount = this.rnd(days, days + 20)

        // for (let i = 0; i < eventCount; i++) {
        //   const allDay = this.rnd(0, 3) === 0
        //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        //   const second = new Date(first.getTime() + secondTimestamp)

        //   events.push({
        //     name: this.names[this.rnd(0, this.names.length - 1)],
        //     start: first,
        //     end: second,
        //     color: this.colors[this.rnd(0, this.colors.length - 1)],
        //     timed: !allDay,
        //   })
        // }
        for (let i = 0; i < this.attorneyCalendar.length; i++) {
          
          // const dates = this.attorneyCalendar[i].date
          // console.log(dates)
          // const splitedDate = dates.split("T")[0];
          // const min = new Date(`${splitedDate}T${moment(this.attorneyCalendar[i].from, ["h:mm A"]).format("HH:mm")}:00`);
          // const max = new Date(`${splitedDate}T${moment(this.attorneyCalendar[i].to, ["h:mm A"]).format("HH:mm")}:00`);

          const startParsedDate = new Date(this.attorneyCalendar[i].date);

          let minTimeFrom = this.attorneyCalendar[i].from;
          if (minTimeFrom.length === 5) {
              minTimeFrom += ":00";
          }
          const minForm = startParsedDate.toISOString().split("T")[0];
          const minDateTimeFrom = `${minForm + 'T' + minTimeFrom + '.000Z'}`;
          const min = new Date(minDateTimeFrom)

          let minTimeTo = this.attorneyCalendar[i].to;
          if (minTimeTo.length === 5) {
              minTimeTo += ":00";
          }
          const maxTo = startParsedDate.toISOString().split("T")[0];
          const maxDateTimeTo = `${maxTo + 'T' + minTimeTo + '.000Z'}`;
          const max = new Date(maxDateTimeTo)

          
          // const client_name = `${this.attorneyCalendar[i].first_name} ${this.attorneyCalendar[i].last_name}`;
          const client_id = this.attorneyCalendar[i].client_id
          const case_id = this.attorneyCalendar[i].case_id
          // const from = this.attorneyCalendar[i].from
          // const to = this.attorneyCalendar[i].to
          const date = this.attorneyCalendar[i].date
          const status = this.attorneyCalendar[i].status
          const chat_object = {};
          const spoken_language = this.attorneyCalendar[i].spoken_language


          events.push({
            name: status,
            start: min,
            end: max,
            from: minTimeFrom,
            to: minTimeTo,
            date: date,
            timed: true,
            caseId: case_id,
            clientId: client_id,
            chat: chat_object,
            language: spoken_language,
            color: status == 'booked' ? "blue" : "grey",
          })
        }

        this.events = events
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
    computed: {
      attorneyCalendar() {
        return this.$store.state.attorneyCalendar.calendars.data;
      },

      attorneyAppointments() {
          return this.$store.state.attorneyAppointments;
      },
    }
  }
</script>

<style scoped>
.app_title {
  font-size: 18px;
  font-weight: 600;
}
</style>