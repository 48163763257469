<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      //
    }),
  };
</script>

<style>

  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Arvo:wght@400;700&display=swap');

  #app {
    font-family: 'Arvo', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Raleway', sans-serif;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .v-main {
      padding: 0 !important;
    }
  }

</style>
