<template>
  <!-- <hello-world /> -->
  <div>
    <v-container
    fluid
    >
      <v-row>
        <v-col cols="12" lg="6" class="left_side">
          <v-app-bar
            color="white"
            light
            flat
            class="auth_container"
          >

            <div>
              <v-img class="logo_img"
                src="../assets/legallync-logo-dark.png"
              ></v-img>
            </div>

            <v-spacer></v-spacer>
          </v-app-bar>
          <div class="form_container">
            <h1 class="auth_title">Welcome back!</h1>
            <p class="auth_subtitle font-weight-medium">Legallync where legal connections are made!</p>

            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="auth_form"
            >
              <div>
                <v-text-field
                  v-model="email"
                  label="Email"
                  :rules="[rules.required, rules.emailRules]"
                  filled
                  dense
                  class="some-style"
                ></v-text-field>
              </div>
              <div>
                <v-text-field
                  v-model="password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  label="Password"
                  filled
                  dense
                  class="some-style"
                  @click:append="show = !show"
                ></v-text-field>
              </div>
              <div class="text-end">
                <p class="font-weight-medium"><router-link class="body_link" to="/forgot-password">Forgot password?</router-link></p>
              </div>
              <!-- <v-btn
                color="#023E7D"
                large
                block
                :loading="loading"
                :disabled="!valid"
                elevation="0"
                class="mt-5 auth_btn white--text"
                @click="validate"
              >
                Login
              </v-btn> -->
              <v-btn
                color="#023E7D"
                large
                block
                elevation="0"
                class="mt-5 auth_btn white--text"
                :loading="loading"
                @click="validate"
              >
                Login
              </v-btn>
            </v-form>
            <p class="font-weight-medium">I don’t have an account? <a class="body_link" @click="sheet = true">Register</a></p>
          </div>
        </v-col>
        <v-col cols="12" lg="6" class="right_side d-none d-sm-none d-md-block">
          <div class="right_side-subtopic">
            <p>Simply log in and access the expertise you need for your legal matters.</p>
          </div>
          <div class="right_side-avatar">
            <v-avatar class="me-1" size="32">
              <img
                src="https://res.cloudinary.com/dwkagby2h/image/upload/v1699265132/general/people8_vxh4ah.png"
                alt="John"
              >
            </v-avatar>
            <v-avatar class="me-1" size="32">
              <img
                src="https://res.cloudinary.com/dwkagby2h/image/upload/v1699265133/general/people7_f8nf7n.png"
                alt="John"
              >
            </v-avatar>
            <v-avatar class="me-1" size="32">
              <img
                src="https://res.cloudinary.com/dwkagby2h/image/upload/v1699265131/general/people3_ebittv.png"
                alt="John"
              >
            </v-avatar>
            <v-avatar class="me-1" size="32">
              <img
                src="https://res.cloudinary.com/dwkagby2h/image/upload/v1699265131/general/people2_syijgl.png"
                alt="John"
              >
            </v-avatar>
          </div>
          <div>
            <h2 class="right_side-topic">Your Trusted Gateway to Legal Expertise</h2>
          </div>
          <v-img
              class="desktop_img"
              src="../assets/legallync-desktop.png"
            ></v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="sheet"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="sheet_topic">What are you registering as?</p>
            </v-col>
            <v-col cols="12">
              <router-link class="sheet_link" to="/client-reg">
              <div class="sheet_link-box d-flex justify-start align-center">
                <div class="me-4">
                  <h4 class="sheet_subtopic">Client Registration</h4>
                  <p class="sheet_text">Create a Client Account for personalized legal assistance from an experienced attorney. Get started today!</p>
                </div>
                <v-btn
                  icon
                  color="#023E7D"
                  class="me-4"
                >
                  <v-icon>mdi-arrow-right-circle-outline</v-icon>
                </v-btn>
              </div>
              </router-link>
            </v-col>
            <v-col cols="12">
              <router-link class="sheet_link" to="/attorney-reg">
              <div class="sheet_link-box d-flex justify-start align-center">
                <div class="me-4">
                  <h4 class="sheet_subtopic">Attorney Registration</h4>
                  <p class="sheet_text">Create a LegalLync Attorney Account today to connect with new clients and grow your practice. Get started today!</p>
                </div>
                <v-btn
                  icon
                  color="#023E7D"
                  class="me-4"
                >
                  <v-icon>mdi-arrow-right-circle-outline</v-icon>
                </v-btn>
              </div>
              </router-link>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-bottom-sheet 
    v-model="sheet"
    hide-overlay
    >
      <v-sheet
        height="250px"
        class="pb-3"
      >
        <div class="pt-6">
          
        </div>
      </v-sheet>
    </v-bottom-sheet> -->

    <!-- verify email -->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="550"
    >
      <v-card>
        <v-card-title>
          Unverified Email
        </v-card-title>
        <v-card-text>{{message}} <router-link class="body_link" to="/verification">Please click this link to verify your email</router-link></v-card-text>
      </v-card>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :color="bgColor"
      top
      right
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
              Close
          </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  // import HelloWorld from '../components/HelloWorld'

  export default {
    // name: 'Home',

    components: {
      // HelloWorld,
    },

    created: function () {
      // this.$store.dispatch('getPlans')
    },

    data () {
      return {
        sheet: false,
        show: false,
        valid: true,
        loading: false,
        dialog: false,
        email: '',
        password: '',
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 6 || 'Minimum 8 characters password',
          emailRules: v => /.+@.+\..+/.test(v) || 'Email must be valid',
          // emailMatch: () => (`The email and password you entered don't match`),
        },

        snackbar: false,
        message: '',
        bgColor: '',
      }
    },

    methods: {
      validate () {
        let formstatus = this.$refs.form.validate()
        if(formstatus != true) return
        this.loading = true
        this.$store.dispatch('userLogin', {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          if(error.response.status === 401) {
            this.message = error.response.data.message
            this.dialog = true
          } else {
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
          }
        })
      },
    }

  }
</script>

<style scoped>
  .left_side {
    padding: 0 5vw;
  }
  .right_side {
    background-color: #EBF3FF;
    height: 100vh;
    position: relative;
  }
  .right_side-avatar {
    max-width: 400px;
    margin: 5px auto;
  }
  .right_side-subtopic {
    font-size: 18px;
    max-width: 400px;
    margin: 10px auto;
    color: #023E7D;
  }
  .right_side-topic {
    font-size: 30px;
    max-width: 400px;
    color: #023E7D;
    margin: auto;
    margin-top: 40px;
  }
  .sheet_topic {
    font-size: 23px;
    color: #023E7D;
  }
  .sheet_link {
    color: #023E7D;
    text-decoration: none;
  }
  .sheet_link-box :hover {
    background-color: #f7f7f7;
  }
  .sheet_text {
    font-size: 14px;
  }
  .logo_img {
    width: 160px;
  }

  .auth_container {
    padding-left: 0;
    margin-bottom: 10vh;
  }

  .auth_title {
    color: #023E7D;
  }

  .auth_subtitle {
    color: #585F6C;
  }

  .auth_form {
    margin-top: 10vh;
  }

  .form_container {
    padding-right: 10vw;
  }

  .body_link {
    text-decoration: none;
    color: #023E7D;
  }
  .auth_btn {
    margin-bottom: 50px;
  }
  .desktop_img {
    max-width: 60%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .some-style >>> .v-input__slot::before {
    border-style: none !important;
  }

  @media (max-width: 768px) {
    .form_container {
      padding-right: 0;
    }
  }
  
</style>
