import Vue from "vue";
import Vuex from "vuex";
import Router from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import createPersistedState from "vuex-persistedstate";

import router from "@/router/index";

Vue.use(Vuex, VueAxios, axios, Router);

export default new Vuex.Store({
	plugins: [createPersistedState()],
	state: {
		token: localStorage.getItem("access_token") || null,
		verifyToken: "",
		userData: {},
		allCase: [],
		openCount: 0,
		openCases: [],
		matchedCount: 0,
		matchedCases: [],
		retainedCount: 0,
		retainedCases: [],
		bothCases: [],
		clientAppointments: [],
		attorneyAppointments: [],
	},
	getters: {
		loggedIn(state) {
			return state.token !== null;
		},
	},
	mutations: {
		setToken(state, token) {
			state.token = token;
			state.verifyToken = "";
			state.userData = {};
			state.allCase = [];
			state.openCount = 0;
			state.openCases = [];
			state.matchedCount = 0;
			state.matchedCases = [];
			state.retainedCount = 0;
			state.retainedCases = [];
			state.bothCases = [];
			state.clientAppointments = [];
			state.attorneyAppointments = [];
		},

		destroyToken(state) {
			state.token = null;
		},

		setVerifyToken(state, verifyToken) {
			state.verifyToken = verifyToken;
		},

		setUserData(state, userData) {
			state.userData = userData;
		},

		setAllCase(state, allCase) {
			state.allCase = allCase.slice().reverse();
		},

		setOpenCount(state, openCount) {
			state.openCount = openCount;
		},

		setOpenCases(state, openCases) {
			state.openCases = openCases.slice().reverse();
		},

		setMatchedCount(state, matchedCount) {
			state.matchedCount = matchedCount;
		},

		setMatchedCases(state, matchedCases) {
			state.matchedCases = matchedCases.slice().reverse();
		},

		setRetainedCount(state, retainedCount) {
			state.retainedCount = retainedCount;
		},

		setRetainedCases(state, retainedCases) {
			state.retainedCases = retainedCases.slice().reverse();
		},

		setBothCases(state, bothCases) {
			state.bothCases = bothCases.slice().reverse();
		},

		setClientAppointments(state, clientAppointments) {
			state.clientAppointments = clientAppointments;
		},

		setAttorneyAppointments(state, attorneyAppointments) {
			state.attorneyAppointments = attorneyAppointments;
		},
	},
	actions: {
		//system activities
		destroyToken(context) {
			if (context.getters.loggedIn) {
				localStorage.removeItem("access_token");
				context.commit("destroyToken");
			}
		},

		// Post endpoints
		registration(context, data) {
			console.log(data);
			return new Promise((resolve, reject) => {
				let headers = {
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.post(
						"/auth/register",
						{
							role: data.role,
							image_url: data.img,
							email: data.email,
							password: data.password,
							first_name: data.firstName,
							last_name: data.lastName,
							zip: data.zipCode,
							city: data.city,
							state: data.state,
							country: data.country,

							dob: data.dob,
							contact_method: data.contact,

							spoken_language: data.languages,
							about: data.about,

							licensed_areas: data.licensedAreas,
							attorney_type: data.typeAttorney,
							practice_area: data.practiceAreas,

							phone: data.phone,
							website: data.website,
							twitter: data.twitter,
							facebook: data.facebook,
							instagram: data.instagram,
							linkedin: data.linkedin,

							contigency: data.contingency,
							hourly_rate: data.hourlyRate,
							fixed_rate: data.fixedRate,
							payment_method: data.paymentMethod,
						},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						if (response.status === 200) {
							console.log("success");
							context.commit("setVerifyToken", response.data.data.verify_email);
							router.push({
								name: "Verification",
							});
							resolve(true);
						}
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		addUserToWaitlist(context, data) {
			console.log(data);
			return new Promise((resolve, reject) => {
				let headers = {
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.post(
						"/auth/waitlist",
						{
							email: data.email,
							school_name: data.school,
							first_name: data.firstName,
							last_name: data.lastName,
							bar_exam_date: data.dob,
							role: data.role,
						},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		userLogin(context, data) {
			return new Promise((resolve, reject) => {
				let headers = {
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.post("/auth/login", data, {
						headers: headers,
					})
					.then((response) => {
						console.log(response);
						if (response.status === 200) {
							const token = response.data.token;
							localStorage.setItem("access_token", token);
							context.commit("setToken", token);
							router.push({
								name: "Loading",
							});
						}
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						// console.log(error.response)
						if (error.response.status === 401) {
							context.commit(
								"setVerifyToken",
								error.response.data.data.verify_email
							);
						}
						reject(error);
					});
			});
		},

		createCase(context, data) {
			console.log(data);
			return new Promise((resolve, reject) => {
				let headers = {
					Authorization: `Bearer ${context.state.token}`,
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.post(
						"/client/case",
						{
							case_type: data.types,
							why_need_attorney: data.reason,
							case_practice_area: data.practiceArea,
							case_name: data.caseName,
							date_of_incident: data.date,
							location_incident: data.locationIncident,
							case_description: data.caseDetail,
						},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						reject(error);
					});
			});
		},

		setCalendar(context, data) {
			return new Promise((resolve, reject) => {
				let headers = {
					Authorization: `Bearer ${context.state.token}`,
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.post(
						"/calendar/availabilities",
						{
							Monday: data.Monday,
							Tuesday: data.Tuesday,
							Wednesday: data.Wednesday,
							Thursday: data.Thursday,
							Friday: data.Friday,
							Saturday: data.Saturday,
							Sunday: data.Sunday,
						},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						reject(error);
					});
			});
		},

		bookAppointment(context, data) {
			console.log(data);
			return new Promise((resolve, reject) => {
				let headers = {
					Authorization: `Bearer ${context.state.token}`,
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.post(
						"/calendar/book-as-client",
						{
							date: data.date,
							from: data.from,
							to: data.to,
							attorney_id: data.attorney_id,
							case_id: data.case_id,
							status: data.status,
						},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						reject(error);
					});
			});
		},

		allAttorneys(context) {
			return new Promise((resolve, reject) => {
				let headers = {
					Authorization: `Bearer ${context.state.token}`,
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.post(
						"/client/case/all-attorney",
						{},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						reject(error);
					});
			});
		},

		sendLimitation(context, data) {
			return new Promise((resolve, reject) => {
				let headers = {
					Authorization: `Bearer ${context.state.token}`,
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.post(
						`/attorney/statute-of-limitation/${data.caseId}`,
						{
							statute_of_limitation: data.date,
						},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						reject(error);
					});
			});
		},

		sendProgress(context, data) {
			return new Promise((resolve, reject) => {
				let headers = {
					Authorization: `Bearer ${context.state.token}`,
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.post(
						`/attorney/case-progress/${data.caseId}`,
						{
							title: data.title,
							notes: data.notes,
							date: data.date,
						},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						reject(error);
					});
			});
		},

		imgCloudUpload(context, file) {
			var cloudinary_url = "https://api.cloudinary.com/v1_1/dwkagby2h/upload";
			var cloudinary_upload_preset = "pjkj2trc";
			console.log(file);
			var formData = new FormData();
			formData.append("file", file);
			formData.append("upload_preset", cloudinary_upload_preset);

			return new Promise((resolve, reject) => {
				let headers = { "Content-Type": "application/x-www-form-urlencoded" };
				axios
					.post(cloudinary_url, formData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		// Put endpoints
		verifyEmail(context, data) {
			console.log(data);
			const headers = {
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.put(
						`/auth/verify-email?token=${context.state.verifyToken}`,
						{
							otp: data.code,
						},
						{ headers: headers }
					)
					.then((response) => {
						console.log(response);
						if (response.status === 200) {
							router.push({
								name: "Verified",
							});
							resolve(response);
						}
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		async sendClientRequest(context, data) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.put(
						`/client/case/${data.caseId}/${data.attorneyId}/send-match-request`,
						{},
						{ headers: headers }
					)
					.then((response) => {
						// context.commit('setCases', response.data.data)
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async acceptAttorneyRequest(context, data) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.put(
						`/client/case/${data.caseId}/${data.attorneyId}/accept-match-request`,
						{},
						{ headers: headers }
					)
					.then((response) => {
						// context.commit('setCases', response.data.data)
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async sendAttorneyRequest(context, caseId) {
			return new Promise((resolve, reject) => {
				let headers = {
					Authorization: `Bearer ${context.state.token}`,
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.put(
						`/attorney/case/${caseId}/send-match-request`,
						{},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						reject(error);
					});
			});
		},

		async acceptClientRequest(context, caseId) {
			return new Promise((resolve, reject) => {
				let headers = {
					Authorization: `Bearer ${context.state.token}`,
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.put(
						`/attorney/case/${caseId}/accept-match-request`,
						{},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						reject(error);
					});
			});
		},

		async retainAttorney(context, data) {
			return new Promise((resolve, reject) => {
				let headers = {
					Authorization: `Bearer ${context.state.token}`,
					"Access-Control-Allow-Origin": "*",
					"Content-type": "Application/json",
				};
				axios
					.put(
						`/client/case/${data.case_id}/${data.attorney_id}/retain-attorney`,
						{},
						{
							headers: headers,
						}
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						reject(error);
					});
			});
		},

		// Get endpoints
		async getUser(context) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/auth`, { headers: headers })
					.then((response) => {
						context.commit("setUserData", response.data.data.user);
						const userRole = response.data.data.user.role;
						if (userRole === "client") {
							setTimeout(() => {
								router.push({
									path: "/client",
								});
							}, 5000);
						}
						if (userRole === "attorney") {
							setTimeout(() => {
								router.push({
									path: "/attorney",
								});
							}, 5000);
						}

						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async getCities(context, data) {
			console.log(data);
			let headers = {
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/auth/zip-codes/primary-city?key_word=${data}`, {
						headers: headers,
					})
					.then((response) => {
						// console.log(response)
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async getZipCodes(context, data) {
			console.log(data);
			let headers = {
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(
						`/auth/zip-codes?primary_city=${data.primary_city}&state=${data.state}`,
						{ headers: headers }
					)
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async getPractice() {
			let headers = {
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/auth/practice-areas`, { headers: headers })
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async getClientCases(context) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/client/all-cases`, { headers: headers })
					.then((response) => {
						console.log(response);
						var openCheck = "open";
						var matchedCheck = "matched";
						var retainedCheck = "retained";
						var allCases = response.data.data;

						// for (let i = 0; i < allCases.length; i++) {
						//   allCases[i].attorney_count = allCases[i].potential_attorneys_id.length;
						// }

						var openCases = allCases.filter((i) =>
							openCheck.includes(i.status)
						);
						var matchedCases = allCases.filter((i) =>
							matchedCheck.includes(i.status)
						);
						var retainedCases = allCases.filter((i) =>
							retainedCheck.includes(i.status)
						);
						context.commit("setAllCase", allCases);
						context.commit("setOpenCount", openCases.length);
						context.commit("setOpenCases", openCases);
						context.commit("setMatchedCount", matchedCases.length);
						context.commit("setMatchedCases", matchedCases);
						context.commit("setRetainedCount", retainedCases.length);
						context.commit("setRetainedCases", retainedCases);

						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async getAttorneyCases(context) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/attorney/case/`, { headers: headers })
					.then((response) => {
						console.log(response);
						var openCheck = "open";
						var matchedCheck = "matched";
						var retainedCheck = "retained";
						var allCases = response.data.cases;

						for (let i = 0; i < allCases.length; i++) {
							let headers = {
								Authorization: `Bearer ${context.state.token}`,
								"Access-Control-Allow-Origin": "*",
								"Content-type": "Application/json",
							};
							axios
								.get(`/attorney/client-profile/${allCases[i].client_id}`, {
									headers: headers,
								})
								.then((response) => {
									allCases[i].first_name = response.data.client.first_name;
									allCases[i].last_name = response.data.client.last_name;
									// console.log(response)
								})
								.catch((error) => {
									console.log(error);
								});
						}
						// console.log(allCases)

						var openCases = allCases.filter((i) =>
							openCheck.includes(i.status)
						);
						var matchedCases = allCases.filter((i) =>
							matchedCheck.includes(i.status)
						);
						var retainedCases = allCases.filter((i) =>
							retainedCheck.includes(i.status)
						);
						var bothCases = allCases.filter(
							(i) =>
								retainedCheck.includes(i.status) ||
								matchedCheck.includes(i.status)
						);

						context.commit("setOpenCount", openCases.length);
						context.commit("setOpenCases", openCases);
						context.commit("setMatchedCount", matchedCases.length);
						context.commit("setMatchedCases", matchedCases);
						context.commit("setRetainedCount", retainedCases.length);
						context.commit("setRetainedCases", retainedCases);
						context.commit("setBothCases", bothCases);

						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async getAttorneyById(context, data) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/client/case/attorney-profile/${data}`, { headers: headers })
					.then((response) => {
						// context.commit('setCases', response.data.data)
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async getAvailability(context) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/calendar/availabilities`, { headers: headers })
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async getAttorneyAvailability(context, attorneyId) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/calendar/availabilities/${attorneyId}`, { headers: headers })
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async getClientAppointments(context) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/calendar/appointments`, { headers: headers })
					.then((response) => {
						console.log(response);
						var clientAppointments = response.data.data;

						for (let i = 0; i < clientAppointments.length; i++) {
							let headers = {
								Authorization: `Bearer ${context.state.token}`,
								"Access-Control-Allow-Origin": "*",
								"Content-type": "Application/json",
							};
							axios
								.get(`/client/case/${clientAppointments[i].case_id}`, {
									headers: headers,
								})
								.then((response) => {
									clientAppointments[i].case_name =
										response.data.data.case.case_name;
									console.log(response);
								})
								.catch((error) => {
									console.log(error);
								});
						}

						for (let i = 0; i < clientAppointments.length; i++) {
							let headers = {
								Authorization: `Bearer ${context.state.token}`,
								"Access-Control-Allow-Origin": "*",
								"Content-type": "Application/json",
							};
							axios
								.get(
									`/client/case/attorney-profile/${clientAppointments[i].attorney_id}`,
									{ headers: headers }
								)
								.then((response) => {
									clientAppointments[i].first_name =
										response.data.attorney.first_name;
									clientAppointments[i].last_name =
										response.data.attorney.last_name;
									console.log(response);
								})
								.catch((error) => {
									console.log(error);
								});
						}
						console.log(clientAppointments);

						setTimeout(() => {
							context.commit("setClientAppointments", clientAppointments);
						}, 3000);

						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async getAttorneyAppointments(context) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/calendar/attorney/all-bookings`, { headers: headers })
					.then((response) => {
						console.log(response);
						var attorneyAppointments = response.data.data;

						for (let i = 0; i < attorneyAppointments.length; i++) {
							let headers = {
								Authorization: `Bearer ${context.state.token}`,
								"Access-Control-Allow-Origin": "*",
								"Content-type": "Application/json",
							};
							axios
								.get(
									`/attorney/client-profile/${attorneyAppointments[i].client_id}`,
									{ headers: headers }
								)
								.then((response) => {
									attorneyAppointments[i].first_name =
										response.data.client.first_name;
									attorneyAppointments[i].last_name =
										response.data.client.last_name;
									attorneyAppointments[i].spoken_language =
										response.data.client.spoken_language;
									console.log(response);
								})
								.catch((error) => {
									console.log(error);
								});
						}

						console.log(attorneyAppointments);

						setTimeout(() => {
							context.commit("setAttorneyAppointments", attorneyAppointments);
						}, 3000);

						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		async attorneyGetCaseById(context, caseId) {
			console.log(caseId);
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.get(`/attorney/case/${caseId}`, { headers: headers })
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},

		//delete endpoints
		async cancelAppointment(context, appointmentId) {
			let headers = {
				Authorization: `Bearer ${context.state.token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-type": "Application/json",
			};
			return new Promise((resolve, reject) => {
				axios
					.delete(`/calendar/delete-as-client/${appointmentId}`, {
						headers: headers,
					})
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
		},
	},
	modules: {},
});
