<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12" md="8" lg="8">
                    <h3 class="topic text-capitalize">Hello! {{userData.last_name}}</h3>
                    <p class="subtopic">Let’s find you clients</p>
                    <v-row class="my-4">
                        <v-col cols="12" lg="4">
                            <v-card
                            color="#FFF7CC"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{attorneyAppointments.length}}</h2>
                                    <span class="card_figure-text text-caption">Appointment</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-card
                            color="#B7EBF1"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{matchedCount}}</h2>
                                    <span class="card_figure-text text-caption">Matched Cases</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-card
                            color="#FAEDD9"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{retainedCount}}</h2>
                                    <span class="card_figure-text text-caption">Retained  Cases</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <h4 class="topic mb-3">Transactions</h4>
                    <v-chip
                        class="mr-2"
                        :color="tab === 0 ? '#099FB2' : '#B5E2E8'"
                        :text-color="tab === 0 ? 'white' : 'grey darken-2'"
                        @click="tab = 0"
                    >
                        All
                    </v-chip>
                    <v-chip
                        class="mr-2"
                        :color="tab === 1 ? '#099FB2' : '#B5E2E8'"
                        :text-color="tab === 1 ? 'white' : 'grey darken-2'"
                        @click="tab = 1"
                    >
                        Pending
                    </v-chip>
                    <v-chip
                        class="mr-2"
                        :color="tab === 2 ? '#099FB2' : '#B5E2E8'"
                        :text-color="tab === 2 ? 'white' : 'grey darken-2'"
                        @click="tab = 2"
                    >
                        Completed
                    </v-chip>
                    <v-row class="my-3">
                        <v-col cols="12">
                            <v-responsive
                            class="overflow-y-auto"
                            max-height="400"
                            >
                                <v-responsive>
                                    <v-tabs-items v-model="tab">
                                        <v-tab-item>
                                            <div v-if="transactions.length > 0">
                                                <div
                                                v-for="(item, i) in transactions"
                                                :key="i"
                                                >
                                                <div class="mt-2 attorney_card d-flex justify-space-between">
                                                    <div class="attorney_card-box d-flex align-center">
                                                        <v-avatar size="34" color="#DCE8F8" class="me-4">
                                                            <v-icon color="#C3D6EF" dark>
                                                            mdi-receipt-text
                                                            </v-icon>
                                                        </v-avatar>
                                                        <span>{{ item.client_name }}</span>
                                                    </div>
                                                    <div class="attorney_card-box d-flex align-center">
                                                    <span class="list_text me-12">{{ item.transaction.updatedAt | formatDate }}</span>
                                                    <span class="list_text me-4">${{ item.transaction.amount }}</span>
                                                    <v-btn
                                                    icon
                                                    color="#023E7D"
                                                    @click="selectedTxn(item)"
                                                    >
                                                    <v-icon>mdi-chevron-right</v-icon>
                                                    </v-btn>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div v-else class="text-center d-flex justify-center">
                                                <v-card flat width="400">
                                                    <v-icon
                                                    size="100"
                                                    color="#D4D4D4"
                                                    >
                                                    mdi-package-variant
                                                    </v-icon>
                                                    <p class="placeholder_text">Nothing to see here! You don't have any case, create a case and it will show up on this list.</p>
                                                </v-card>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div v-if="pendingTxn.length > 0">
                                                <div
                                                v-for="(item, i) in pendingTxn"
                                                :key="i"
                                                >
                                                <div class="mt-2 attorney_card d-flex justify-space-between">
                                                    <div class="attorney_card-box d-flex align-center">
                                                        <v-avatar size="34" color="#DCE8F8" class="me-4">
                                                            <v-icon color="#C3D6EF" dark>
                                                            mdi-receipt-text
                                                            </v-icon>
                                                        </v-avatar>
                                                        <span>{{ item.client_name }}</span>
                                                    </div>
                                                    <div class="attorney_card-box d-flex align-center">
                                                    <span class="list_text me-4">${{ item.transaction.amount }}</span>
                                                    <v-btn
                                                    icon
                                                    color="#023E7D"
                                                    @click="selectedTxn(item)"
                                                    >
                                                    <v-icon>mdi-chevron-right</v-icon>
                                                    </v-btn>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div v-else class="text-center d-flex justify-center">
                                                <v-card flat width="400">
                                                    <v-icon
                                                    size="100"
                                                    color="#D4D4D4"
                                                    >
                                                    mdi-package-variant
                                                    </v-icon>
                                                    <p class="placeholder_text">Nothing to see here! You don't have any case, create a case and it will show up on this list.</p>
                                                </v-card>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div v-if="paidTxn.length > 0">
                                                <div
                                                v-for="(item, i) in paidTxn"
                                                :key="i"
                                                >
                                                <div class="mt-2 attorney_card d-flex justify-space-between">
                                                    <div class="attorney_card-box d-flex align-center">
                                                        <v-avatar size="34" color="#DCE8F8" class="me-4">
                                                            <v-icon color="#C3D6EF" dark>
                                                            mdi-receipt-text
                                                            </v-icon>
                                                        </v-avatar>
                                                        <span>{{ item.client_name }}</span>
                                                    </div>
                                                    <div class="attorney_card-box d-flex align-center">
                                                    <span class="list_text me-12">{{ item.transaction.updatedAt | formatDate }}</span>
                                                    <span class="list_text me-4">${{ item.transaction.amount }}</span>
                                                    <v-btn
                                                    icon
                                                    color="#023E7D"
                                                    @click="selectedTxn(item)"
                                                    >
                                                    <v-icon>mdi-chevron-right</v-icon>
                                                    </v-btn>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div v-else class="text-center d-flex justify-center">
                                                <v-card flat width="400">
                                                    <v-icon
                                                    size="100"
                                                    color="#D4D4D4"
                                                    >
                                                    mdi-package-variant
                                                    </v-icon>
                                                    <p class="placeholder_text">Nothing to see here! You don't have any case, create a case and it will show up on this list.</p>
                                                </v-card>
                                            </div>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-responsive>
                            </v-responsive>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" lg="4" class="right_sidebar">
                    <ApptCard />
                    <CaseCard />
                </v-col>
                
            </v-row>
        </v-container>

        <!-- open case -->
        <v-dialog
        v-model="newCaseDialog"
        max-width="420"
        >
        <v-card class="py-4" color="#023E7D">
            <v-card-title class="mb-4">
                <v-avatar size="40" color="#DCE8F8">
                    <v-icon color="#023E7D" dark>
                        mdi-briefcase-outline
                    </v-icon>
                </v-avatar>
            </v-card-title>
            <v-card-text>
                <h3 class="dialog_title">New Prospective Cases.</h3>
                <p class="dialog_subtitle">Sed egestas, dui a accumsan sagittis, sapien mauris placerat  eu bi purus eros felis viverra nisi nec tincidunt ull amcorper. Donec ac blandit felis.</p>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="white"
                text
                @click="openCaseDialog = false"
            >
                Later
            </v-btn>
            <v-btn
                color="white"
                dark
                depressed
                route to="/client/cases"
                class="dialog_btn"
            >
                View Now
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- invoice preview -->
        <v-dialog
        v-model="invoicePreview"
        max-width="700"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Invoice Preview
                </v-card-title>
            </div>
            <v-card-text v-if="invoiceLoaded === false" class="mt-8">
                <v-row>
                    <v-col lg="3">
                        <h5>Client Name</h5>
                        <div class="case_text">
                            {{ invoiceDetails.client_name }}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Invoice No</h5>
                        <div class="case_text">
                            {{ invoiceDetails.invoice_no }}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Due Date</h5>
                        <div class="case_text">
                            {{ invoiceDetails.due_date | formatDate }}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Case Type</h5>
                        <div class="case_text">
                            {{ invoiceDetails.service_rendered }}
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Case ID</h5>
                        <div class="case_text">
                            #{{ invoiceDetails.case_id }}
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Note</h5>
                        <div class="case_text">
                            {{ invoiceDetails.comment }}
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Description
                                </th>
                                <th class="text-left">
                                    Cost
                                </th>
                                </tr>
                            </thead>
                            <tbody v-if="invoiceDetails.services.length > 0">
                                <tr
                                v-for="(item, i) in invoiceDetails.services"
                                :key="i"
                                >
                                <td>{{ item.description }}</td>
                                <td>${{ item.cost }}</td>
                                </tr>
                            </tbody>
                            <div
                            v-else 
                            class="mt-4"
                            >
                            <v-card
                            flat 
                            width="480"

                            class="d-flex jusity-start align-center"
                            >
                                <v-icon
                                size="50"
                                color="#D4D4D4"
                                class="me-2"
                                >
                                mdi-view-list-outline
                                </v-icon>
                                <span class="placeholder_text">There's currently no service item to display, click the add service button below to add</span>
                            </v-card>
                            </div>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <span class="text_caption me-2">Total</span>
                        <span class="invoice_total display-1">${{ invoiceDetails.amount }}</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-else>
                <v-row>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="3">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="6">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col lg="6">
                        <v-skeleton-loader
                        type="list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12">
                        <v-skeleton-loader
                        type="image, table-tfoot"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ApptCard from '../../components/attorney/AppointmentCardAttorney.vue'
import CaseCard from '../../components/attorney/CaseCardAttorney.vue'
import Sidebar from '../../components/attorney/SidebarAttorney.vue'
import Navbar from '../../components/attorney/NavbarAttorney.vue'

  export default {

    components: {
        ApptCard, CaseCard, Sidebar, Navbar
    },

    created: function () {
    //   setTimeout(() => {
    //       this.newCaseDialog = true
    //   }, 5000);
        this.$store.dispatch('getAttorneyCases')
        this.$store.dispatch('getAllTransaction')
    },

    data () {
      return {
        newCaseDialog: false,
        tab: 0,
        invoicePreview: false,
        invoiceLoaded: false,
        invoiceDetails: {},
      }
    },

    methods: {
        selectedTxn(item) {
            console.log(item)
            this.invoicePreview = true
            this.invoiceLoaded= true
            this.$store.dispatch('getInvoiceById', item.transaction.invoice_id)
            .then((response) => {
                console.log(response)
                this.invoiceLoaded= false
                this.invoiceDetails = response.data.data.invoice
            })
            .catch((error) => {
                this.invoiceLoaded= false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        }
    },

    computed: {
        userData() {
            return this.$store.state.userData;
        },
        openCount() {
            return this.$store.state.openCount;
        },
        matchedCount() {
            return this.$store.state.matchedCount;
        },
        retainedCount() {
            return this.$store.state.retainedCount;
        },
        attorneyAppointments() {
            return this.$store.state.attorneyAppointments;
        },
        transactions() {
            return this.$store.state.transactions;
        },
        pendingTxn() {
            return this.$store.state.pendingTxn;
        },
        paidTxn() {
            return this.$store.state.paidTxn;
        },
    }

  }
</script>

<style scoped>
    .topic {
        color: #023E7D;
    }

    .subtopic {
        color: #7E889A;
    }

    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }

    .card_figure-text {
        color: #023E7D;
    }

    .attorney_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }

    .attorney_card-box {
        height: 34px;
    }

    .card_dheading {
        color: #FFFFFF;
        margin-top: 25px;
        margin-left: 8px;
    }

    .list_text {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
    }

    .dialog_title {
        font-size: 25px;
        color: #FFF;
        margin-bottom: 20px;
    }

    .dialog_subtitle {
        color: #FFF;
    }

    .dialog_btn {
        color: #023E7D;
    }
    .right_sidebar {
        border-left: 1px solid #e9ecef;
    }
    .placeholder_text {
        color: #A7A7A7;
    }
</style>