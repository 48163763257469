<template>
    <div class="main d-flex align-center">
        <v-card
        class="mx-auto"
        max-width="400"
        elevation="1"
        >
            <div class="subs_banner text-center">
                <v-avatar
                size="30"
                class="mb-2"
                >
                <img
                    src="../assets/legallync-logo-img.jpg"
                    alt="legal lync logo"
                >
                </v-avatar>
                <p class="subs_banner-text">Subscribe and start matching with clients today!</p>
            </div>
            <v-card-text
                class="price_box"
                style="position: relative;"
            >
                <v-card
                class="pa-4 plan_box d-flex justify-start"
                elevation="0"
                color="#023E7D"
                >
                    <v-avatar
                    rounded
                    size="60"
                    class="me-4"
                    >
                    <img
                        src="../assets/plan-img.jpg"
                        alt="plan image"
                    >
                    </v-avatar>
                    <div>
                        <h2 class="mb-2 plan_heading">{{ pickedPlan.plan_name }}</h2>
                        <span class="plan_subheading"><span class="plan_sign">$</span><span class="plan_cost">{{ pickedPlan.plan_price }}</span>/month</span>
                    </div>
                </v-card>
                <div class="pt-4 pb-2 subs_item d-flex justify-space-between">
                    <span class="subs_item-text">Subtotal</span>
                    <span class="subs_item-cost">${{ pickedPlan.plan_price }}.00</span>
                </div>
                <div class="pt-2 pb-4 subs_item d-flex justify-space-between">
                    <span class="subs_item-text">Legal Lync Discount</span>
                    <span class="subs_item-cost">$0.00</span>
                </div>
                <hr />
                <div class="py-4 subs_total d-flex justify-space-between">
                    <span class="subs_total-text">Total Amount</span>
                    <span class="subs_total-cost">${{ pickedPlan.plan_price }}.00</span>
                </div>
                <div class="py-4 subs_info">
                    <span class="subs_info-text">To finalize your subscription, enter your card details and clicking the button below to complete your payment.</span>
                </div>
                <div>
                    <stripe-element-card
                        ref="elementRef"
                        :pk="publishableKey"
                        hidePostalCode
                        @token="tokenCreated"
                    />
                    <v-btn
                        color="#FFD700"
                        light
                        large
                        block
                        elevation="0"
                        class="mt-5"
                        :loading="loading"
                        @click="submit"
                    >
                    <b>Make Payment</b>
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>

        <!-- select plan dialog -->
        <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
        >
            <v-card class="pt-6">
                <v-card-text class="dialog_text">
                    <v-icon
                    large
                    color="grey"
                    class="mb-2"
                    >
                    mdi-alert-circle-outline
                    </v-icon>
                    <p>Hi there! kindly click the button below to select a subscription plan.</p>
                    <div class="d-flex justify-end">
                        <v-btn
                            color="#023E7D"
                            rounded
                            outlined
                            to="/plans"
                        >
                            Back
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
    import { StripeElementCard } from '@vue-stripe/vue-stripe';
    export default {

        components: {
            StripeElementCard,
        },

        mounted: function () {
        },

        data () {
            this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
            return {
                token: null,
                loading: false,
                dialog: false
            };
        },
        methods: {
            tokenCreated (token) {
                    console.log(token);
                    this.token = token;
                    // handle the token
                    // send it to your server
                    this.sendTok()
            },
            
            submit () {
                this.$refs.elementRef.submit();
            },

            sendTok() {
                this.loading = true;
                this.$store.dispatch('subscriptionToken', {
                    token_id: this.token.id,
                    user_id: this.userId,
                    product_amount: this.pickedPlan.plan_price,
                    product_price_id: this.pickedPlan.plan_id,
                    product_name: this.pickedPlan.plan_name,
                })
                .then((response) => {
                    this.loading = false;
                    console.log(response)
                    console.log('success good request')
                    
                    this.$router.push({
                        path: '/payment-success',
                    })
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error)
                    console.log("error wrong request")
                    this.$router.push({
                        path: '/payment-canceled',
                    })
                })
            }
        },
        computed: {
            userId() {
                return this.$store.state.user.id;
            },

            pickedPlan() {
                return this.$store.state.pickedPlan;
            }
        }

    };
</script>

<style scoped>

    .main {
        height: 100vh;
    }

    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .5;
        position: absolute;
        width: 100%;
    }

    .subs_banner {
        background-color: #003165;
        padding-top: 30px;
        height: 180px;
    }

    .subs_banner-text {
        color: #FFF;
        font-size: 14px;
        max-width: 250px;
        margin: auto;
    }

    .plan_box {
        margin-top: -70px;
    }

    .plan_heading {
        font-size: 1.2rem;
        font-weight: 600;
        color: #FFF;
    }

    .plan_subheading {
        font-size: .9rem;
        color: #FFF;
        margin-bottom: 0;
    }

    .plan_cost {
        font-size: 1.5rem;
        font-weight: 900;
        font-family: 'Arvo', sans-serif;
        color: #FFF;
    }

    .subs_item-text {
        font-size: 16px;
        font-weight: 600;
    }

    .subs_item-cost {
        font-size: 18px;
        font-weight: 300;
        font-family: 'Arvo', sans-serif;
    }

    .subs_total-text {
        font-size: 16px;
        font-weight: 600;
    }

    .subs_total-cost {
        font-size: 22px;
        font-weight: 900;
        font-family: 'Arvo', sans-serif;
    }

    .subs_info-text {
        font-size: 12px;
    }
    .price_box {
        margin-top: 5px;
    }
    .dialog_text {
        font-size: 18px;
        font-weight: 600;
        color: grey;
    }

</style>