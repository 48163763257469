<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12">
                    <h3 class="topic">Settings</h3>
                    <div class="mt-12 d-flex justify-start">
                        <v-avatar rounded size="80" color="#DCE8F8" class="me-4">
                            <v-icon size="60" color="#85A4C7" dark>
                                mdi-account
                            </v-icon>
                        </v-avatar>
                        <div class="pt-6">
                            <div class="d-flex justify-start">
                                <h3 class="topic text-capitalize">{{userDetails.last_name}} {{userDetails.first_name}}</h3>
                                <v-btn
                                icon
                                small
                                color="#023E7D"
                                @click="editDialog = true"
                                >
                                <v-icon small>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </div>
                            <span>{{userDetails.email}}</span>
                        </div>
                    </div>

                    <v-row class="list_section">
                        <v-col cols="12">
                            <div class="pa-4 profile_info">
                                <!-- <div class="d-flex justify-end">
                                    <v-chip
                                    color="#666666"
                                    outlined
                                    >
                                    <h4 class="me-1 btn_text">Profile Information</h4>
                                    <v-btn
                                    icon
                                    small
                                    color="#666666"
                                    >
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    </v-chip>
                                </div> -->
                                <v-row class="mt-2 details_card">
                                    <v-col cols="5">
                                        <h5>Address</h5>
                                        <span>{{ userDetails.address }}</span>
                                    </v-col>
                                    <v-col cols="3">
                                        <h5>State</h5>
                                        <span>{{ userDetails.state }}</span>
                                    </v-col>
                                    <v-col cols="2">
                                        <h5>Postal code</h5>
                                        <span>{{ userDetails.zipcode }}</span>
                                    </v-col>
                                    <v-col cols="2">
                                        <h5>Birthday</h5>
                                        <span>{{ userDetails.dob | formatDate }}</span>
                                    </v-col>
                                    
                                    <v-col cols="12">
                                        <h5>Language</h5>
                                        <span
                                        v-for="(item, i) in userDetails.spoken_language"
                                        :key="i"
                                        >
                                            <span>{{ item }}, </span>
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="list_section">
                        <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="34" color="#DCE8F8" class="me-4">
                                        <v-icon color="#023E7D" dark>
                                          mdi-information-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">About Us</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                        <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="34" color="#DCE8F8" class="me-4">
                                        <v-icon color="#023E7D" dark>
                                          mdi-logout-variant
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">Logout</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                        <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="34" color="#DCE8F8" class="me-4">
                                        <v-icon color="#023E7D" dark>
                                          mdi-message-alert-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">Send Feedback</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    
                </v-col>
                
            </v-row>
        </v-container>

        <!-- profile dialog -->
        <v-dialog
        v-model="editDialog"
        max-width="700"
        >
        <v-card>
            <v-card-title class="card_title">
                Update Profile
            </v-card-title>
            <v-card-text class="mt-8">
                <v-row>
                    <!-- <v-col cols="12">
                        <v-avatar
                        size="80"
                        rounded
                        class="mb-4"
                        >
                        <img
                            src="../../assets/avatar.jpg"
                            alt="John"
                        >
                        </v-avatar>
                        <v-btn
                        icon
                        color="#023E7D"
                        class="mt-12"
                        >
                        <v-icon>mdi-plus-box</v-icon>
                        </v-btn>
                    </v-col> -->
                    <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="firstName"
                            label="First Name"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="lastName"
                            label="Last Name"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="address"
                            label="Address"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-select
                            v-model="selectedLanguages"
                            :items="languages"
                            label="Select Language"
                            hide-details="auto"
                            filled
                            multiple
                            class="some-style"
                            >
                            <template v-slot:selection="{ index }">
                                <span
                                v-show="index === 0"
                                class="grey--text body-2"
                                >
                                {{ selectedLanguages.length }} selected
                                </span>
                            </template>
                            </v-select>
                            <v-chip
                            v-for="(item, i) in selectedLanguages"
                            :key="i"
                            class="mt-2 me-2"
                            close
                            small
                            color="#023E7D"
                            text-color="white"
                            @click:close="removeLang(selectedLanguages, item)"
                            >
                            {{item}}
                            </v-chip>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    label="MMDDYYYY"
                                    append-icon="mdi-calendar-blank-outline"
                                    hide-details="auto"
                                    hint="Most be 18 and above"
                                    persistent-hint
                                    filled
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    class="some-style"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="date"
                                no-title
                                scrollable
                                :max="maxDate"
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    <v-col cols="12">
                        <v-btn 
                        class="mt-4" 
                        color="#023E7D" 
                        depressed 
                        dark 
                        block
                        :loading="profileLoader"
                        @click="updateProfile"
                        >
                            Update Details
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- snackbar -->
        <v-snackbar
        v-model="snackbar"
        :color="bgColor"
        top
        right
        >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import Sidebar from '../../components/client/SidebarClient.vue'
import Navbar from '../../components/client/NavbarClient.vue'

import moment from 'moment'

  export default {
    components: {
        Sidebar, 
        Navbar
    },

    mounted () {
      this.calculateDateForAge()
    },

    data () {
      return {
        editDialog: false,
        firstName: '',
        lastName: '',
        address: '',
        selectedLanguages: [],
        languages: ['English', 'Spanish', 'French'],
        menu: false,
        date: null,
        maxDate: '',
        profileLoader: false,

        snackbar: false,
        message: '',
        bgColor: '',
      }
    },

    methods: {
        updateProfile() {
            this.profileLoader = true
            this.$store.dispatch('updateClientProfile', {
                first_name: this.firstName,
                last_name: this.lastName,
                address: this.address,
                dob: this.date,
                spoken_language: this.selectedLanguages,
            })
            .then(() => {
            this.profileLoader = false
            this.$store.dispatch('getUserDetails')
            this.snackbar = true
            this.message = 'Profile successfully updated'
            this.bgColor = 'success'
            this.profileInfo = false
            })
            .catch((error) => {
            this.profileLoader = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        calculateDateForAge() {
            const dateFor18YearsAgo = moment().subtract(18, 'years');
            this.maxDate = dateFor18YearsAgo.format('YYYY-MM-DD');
        },

        removeLang(selectedLanguages, item) {
            var index = selectedLanguages.indexOf(item);
            if (index > -1) {
                selectedLanguages.splice(index, 1);
            }
            return selectedLanguages;
        },
    },

    computed: {
        userDetails() {
            return this.$store.state.userData.user;
        },
    }

  }
</script>

<style scoped>
    .topic {
        color: #023E7D;
    }

    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }

    .card_figure-text {
        color: #023E7D;
    }

    .attorney_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }

    .attorney_card-box {
        height: 34px;
    }

    .card_dheading {
        color: #FFFFFF;
        margin-top: 25px;
        margin-left: 8px;
    }

    .list_text {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
    }

    .card_lheading {
        color: #023E7D;
    }

    .card_lsubheading {
        color: #585F6C;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }

    .card_dsubheading {
        color: #FFFFFF;
        font-size: 12px;
    }

    .body_llink {
        color: #023E7D;
        font-size: 14px;
        text-decoration: none;
    }

    .body_dlink {
        color: #FFFFFF;
        font-size: 14px;
        text-decoration: none;
    }
    .list_section {
        margin-top: 25px;
    }
    .title_bg {
        background-color: #023E7D;
        color: #fff;
    }
    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }
    .profile_info {
        background-color: #FBFBFB;
    }
</style>