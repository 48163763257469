import { eventBus } from './EventBus';

export default {
  install(Vue, options) {
    const ws = new WebSocket(options.websocketUrl);

    ws.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "create_case") {
        console.log('receive create case')
        eventBus.$emit("createCaseMessage", data);
      }
      if (data.type === "⁠client_match_request") {
        console.log('receive client match')
        eventBus.$emit("clientMatchMessage", data);
      }
      if (data.type === "attorney_match_request") {
        console.log('receive attorney match')
        eventBus.$emit("attorneyMatchMessage", data);
      }
      if (data.type === "attorney_setup") {
        console.log('receive attorney setup')
        eventBus.$emit("attorneySetupMessage", data);
      }
      if (data.type === "chat") {
        eventBus.$emit("chatMessage", data);
      }
    });

    // Assign WebSocket instance to Vue prototype
    Vue.prototype.$ws = ws;

    // Listen for events to send data through WebSocket
    // eventBus.$on("sendWebSocketData", (data) => {
    //     console.log('ws call')
    //     ws.send(JSON.stringify(data));
    // });
  },
};
