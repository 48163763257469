import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPwd.vue'
import Loading from '../views/Loading.vue'
import Logout from '../views/Logout.vue'
import Verification from '../views/Verification.vue'
import Verified from '../views/Verified.vue'
import Waitlist from '../views/Waitlist.vue'
import BarInfo from '../views/BarInfo.vue'
import Register from '../views/Register.vue'

import AttorneyPlans from '../views/AttorneyPlans.vue'
import ClientPlans from '../views/ClientPlans.vue'
import ClientMeet from '../views/client/ClientMeet.vue'
import AttorneyMeet from '../views/attorney/AttorneyMeet.vue'
import ClientReg from '../views/client/ClientReg.vue'
import AttorneyReg from '../views/attorney/AttorneyReg.vue'
// import BaseClient from '../views/client/BaseClient.vue'
// import BaseAttorney from '../views/attorney/BaseAttorney.vue'
import DashboardClient from '../views/client/DashboardClient.vue'
import CasesClient from '../views/client/CasesClient.vue'
import AppointmentsClient from '../views/client/AppointmentsClient.vue'
import SettingsClient from '../views/client/SettingsClient.vue'
import DashboardAttorney from '../views/attorney/DashboardAttorney.vue'
import CasesAttorney from '../views/attorney/CasesAttorney.vue'
import AppointmentsAttorney from '../views/attorney/AppointmentsAttorney.vue'
import TransactionsAttorney from '../views/attorney/TransactionsAttorney.vue'
import SettingsAttorney from '../views/attorney/SettingsAttorney.vue'

import Subscription from '../views/Subscription.vue'
import SuccessPayment from '../views/SuccessPayment.vue'
import ThanksPage from '../views/ThanksPage.vue'
import CancelPayment from '../views/CancelPayment.vue'
import InvoicePayment from '../views/InvoicePayment.vue'

import Wstest from '../views/client/Wstest.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/wstest',
    name: 'Wstest',
    component: Wstest
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/attorney-plans',
    name: 'AttorneyPlans',
    component: AttorneyPlans,
    props: true,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/client-plans',
    name: 'ClientPlans',
    component: ClientPlans,
    props: true,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/loading',
    name: 'Loading',
    component: Loading,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/invoice-payment',
    name: 'InvoicePayment',
    component: InvoicePayment,
    query:{},
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription,
    props: true,
  },
  {
    path: '/payment-success',
    name: 'SuccessPayment',
    component: SuccessPayment
  },
  {
    path: '/thanks',
    name: 'thanksPage',
    component: ThanksPage
  },
  {
    path: '/payment-canceled',
    name: 'CancelPayment',
    component: CancelPayment
  },
  {
    path: '/verification',
    name: 'Verification',
    component: Verification
  },
  {
    path: '/verified',
    name: 'Verified',
    component: Verified
  },
  {
    path: '/waitlist',
    name: 'Waitlist',
    component: Waitlist
  },
  {
    path: '/bar-info',
    name: 'BarInfo',
    component: BarInfo
  },
  {
    path: '/register',
    name: 'Register',
    props: true,
    component: Register,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/client-meet',
    name: 'ClientMeet',
    props: true,
    component: ClientMeet,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: '/attorney-meet',
    name: 'AttorneyMeet',
    props: true,
    component: AttorneyMeet,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: '/client-reg',
    name: 'ClientReg',
    props: true,
    component: ClientReg,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/attorney-reg',
    name: 'AttorneyReg',
    props: true,
    component: AttorneyReg,
    meta: {
      requiresVisitor: true,
    },
  },

  // client route
  {
    path: '/client/Dashboard',
    name: 'DashboardClient',
    component: DashboardClient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/client/cases',
    name: 'CasesClient',
    component: CasesClient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/client/appointments',
    name: 'AppointmentsClient',
    component: AppointmentsClient,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/client/settings',
    name: 'SettingsClient',
    component: SettingsClient,
    meta: {
      requiresAuth: true,
    },
  },

  // attorney router
  {
    path: '/attorney/dashboard',
    name: 'DashboardAttorney',
    component: DashboardAttorney,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/attorney/cases',
    name: 'CasesAttorney',
    component: CasesAttorney,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/attorney/appointments',
    name: 'AppointmentsAttorney',
    component: AppointmentsAttorney,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/attorney/transactions',
    name: 'TransactionsAttorney',
    component: TransactionsAttorney,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/attorney/settings',
    name: 'SettingsAttorney',
    component: SettingsAttorney,
    meta: {
      requiresAuth: true,
    },
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
