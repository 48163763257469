<template>
    <div>
        <!-- <v-card 
        class="pa-2 mt-6"
        flat
        color="#023E7D"
        >
            <v-card-title class="d-flex justify-end">
                <router-link to="" class="body_dlink">
                    Case Progress
                    <v-icon
                        right
                        dark
                        small
                    >
                        mdi-chevron-right
                    </v-icon>
                </router-link>
            </v-card-title>
            <v-card-text v-if="retainedCases[0].case_progress.length > 0">
                <div v-for="(item, i) in retainedCases[0].case_progress.slice(-1)" :key="i">
                    <div class="mb-4 d-flex justify-start">
                        <div>
                            <div class="line"></div>
                            <span class="badge">
                                <v-icon color="white">mdi-checkbox-multiple-marked-circle</v-icon>
                            </span>
                        </div>
                        <h4 class="card_dheading">{{item.title}}</h4>
                    </div>
                    <span class="card_dsubheading">
                        {{item.notes}}
                    </span>
                </div>
            </v-card-text>
            <v-card-text v-else>
                <v-icon size="30" color="#B0CFF4">
                    mdi-checkbox-multiple-marked-circle
                </v-icon>
                <p class="placeholder_text">There's no recent appointment available, to create one click the recent appointment button above.</p>
            </v-card-text> 
        </v-card> -->
    </div>
</template>

<script>
  export default {

    components: {

    },

    data () {
      return {
        
      }
    },

    created: function () {

    },

    methods: {
        
    },

    computed: {
        retainedCases() {
            return this.$store.state.retainedCases;
        },
    }

  }
</script>

<style scoped>
    .line {
        width: 1px;
        height: 20px;
        background-color: #FFFFFF;
        margin-bottom: 5px;
        margin-left: 10px;
    }

    /* .badge {
        padding: 4px 8px;
        background-color: #FFFFFF;
        color: #023E7D;
        font-weight: bold;
        border-radius: 100%;
    } */

    .card_dheading {
        color: #FFFFFF;
        margin-top: 25px;
        margin-left: 8px;
    }

    .card_dsubheading {
        color: #FFFFFF;
        font-size: 12px;
    }

    .body_dlink {
        color: #FFFFFF;
        font-size: 14px;
        text-decoration: none;
    }

    .placeholder_text {
        color: #B0CFF4;
        font-size: 12px;
    }
</style>