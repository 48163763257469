<template>
  <!-- <hello-world /> -->
  <div class="px-2">
    <v-app-bar
      color="white"
      light
      flat
    >

      <div>
        <v-img class="logo_img"
          src="../../assets/legallync-logo-dark.png"
        ></v-img>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-container class="auth_container">
        <h2 class="auth_title">Attorney Setup</h2>
        <v-row>
            <v-col cols="12">
                <v-stepper class="elevation-0" v-model="e1">
                    <v-stepper-header class="elevation-0">
                        <v-stepper-step
                            :complete="e1 > 1"
                            step="1"
                            color="#023E7D"
                        >
                            Personal Information
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="e1 > 2"
                            step="2"
                            color="#023E7D"
                        >
                            Practice Information
                        </v-stepper-step>

                        <!-- <v-divider></v-divider>

                        <v-stepper-step 
                            :complete="e1 > 3"
                            step="3"
                            color="#023E7D"
                        >
                            Contact Information
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step 
                            step="4"
                            color="#023E7D"
                        >
                            Plan & Fees
                        </v-stepper-step> -->
                    </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-card
                                class="mb-12"
                                flat
                                min-height="400px"
                                >
                                    <v-form
                                    ref="form1"
                                    v-model="valid1"
                                    lazy-validation
                                    class="regForm1"
                                    >                                       
                                        <v-row>
                                            <v-col cols="12" lg="2"></v-col>
                                            <v-col cols="12" lg="8">
                                                <v-row>
                                                    <v-col cols="12" lg="12">
                                                        <input type="file" ref="file" style="display: none" @change="onFileSelected" >
                                                        <v-avatar class="avatar_tag" rounded :size="avatarSize" 
                                                                    @click="$refs.file.click()">
                                                            <v-img :src="imgPlaceholder" alt="" accept="image/*" aspect-ratio="1.1"></v-img>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" class="py-0">
                                                        <p>What is your name?</p>
                                                    </v-col>
                                                    <v-col cols="12" lg="6">
                                                        <v-text-field
                                                        v-model="firstName"
                                                        label="First Name"
                                                        :rules="[rules.required]"
                                                        filled
                                                        dense
                                                        hide-details="auto"
                                                        class="some-style"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6">
                                                        <v-text-field
                                                        v-model="lastName"
                                                        label="Last Name"
                                                        :rules="[rules.required]"
                                                        filled
                                                        dense
                                                        hide-details="auto"
                                                        class="some-style"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6">
                                                        <v-text-field
                                                        v-model="phone"
                                                        label="Phone Number"
                                                        :rules="[rules.required]"
                                                        filled
                                                        dense
                                                        hide-details="auto"
                                                        class="some-style"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6">
                                                        <v-text-field
                                                        v-model="email"
                                                        label="Email"
                                                        :rules="[rules.required, rules.emailRules]"
                                                        filled
                                                        dense
                                                        hide-details="auto"
                                                        class="some-style"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6">
                                                        <v-text-field
                                                        v-model="password"
                                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :rules="[rules.required, rules.min]"
                                                        :type="show ? 'text' : 'password'"
                                                        label="Password"
                                                        filled
                                                        dense
                                                        class="some-style"
                                                        @click:append="show = !show"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6"></v-col>
                                                    <v-col cols="12" lg="6">
                                                        <p>Where do you live?</p>
                                                        <v-autocomplete
                                                        v-model="zipCode"
                                                        label="Zip Code"
                                                        :rules="[rules.required]"
                                                        @keyup="zipCodeCheck"
                                                        return-object
                                                        type="number"
                                                        item-text="zip_code"
                                                        filled
                                                        :items="zipCodeList"
                                                        :loading="isLoading"
                                                        dense
                                                        class="some-style"
                                                        @change="zipCodeSelected"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" class="d-flex align-end">
                                                        <v-text-field
                                                        v-model="city"
                                                        label="City, State"
                                                        :rules="[rules.required]"
                                                        readonly
                                                        filled
                                                        dense
                                                        class="some-style"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6">
                                                        <p>What language do you speak?</p>
                                                        <v-select
                                                        v-model="selectedLanguages"
                                                        :items="languages"
                                                        label="Select Language"
                                                        :rules="[rules.objRequired]"
                                                        filled
                                                        multiple
                                                        hide-details="auto"
                                                        class="some-style"
                                                        >
                                                        <template v-slot:selection="{ index }">
                                                            <span
                                                            v-show="index === 0"
                                                            class="grey--text body-2"
                                                            >
                                                            {{ selectedLanguages.length }} selected
                                                            </span>
                                                        </template>
                                                        </v-select>
                                                        <v-chip
                                                        v-for="(item, i) in selectedLanguages"
                                                        :key="i"
                                                        class="mt-2 me-2"
                                                        close
                                                        small
                                                        color="#023E7D"
                                                        text-color="white"
                                                        @click:close="removeLang(selectedLanguages, item)"
                                                        >
                                                        {{item}}
                                                        </v-chip>
                                                    </v-col>
                                                    <v-col cols="12" lg="6">
                                                        <p class="font-weight-medium">When is your date of birth?</p>
                                                        <v-menu
                                                            ref="menu"
                                                            v-model="menu"
                                                            :close-on-content-click="false"
                                                            :return-value.sync="date"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="dateFormatted"
                                                                label="MMDDYYYY"
                                                                append-icon="mdi-calendar-blank-outline"
                                                                filled
                                                                readonly
                                                                :rules="[rules.required, ageCheck]"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                class="some-style"
                                                            ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                            v-model="date"
                                                            no-title
                                                            scrollable
                                                            >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="menu = false"
                                                            >
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="$refs.menu.save(date)"
                                                            >
                                                                OK
                                                            </v-btn>
                                                            </v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" lg="12">
                                                        <p>Tell us more about you</p>
                                                        <v-textarea
                                                        v-model="about"
                                                        name="input-7-1"
                                                        filled
                                                        label="About"
                                                        auto-grow
                                                        class="some-style"
                                                        ></v-textarea>
                                                    </v-col>
                                                </v-row>
                                                <div class="mt-4 text-end">
                                                    <v-btn
                                                    color="#023E7D"
                                                    large
                                                    dark
                                                    @click="nextStep"
                                                    class="reg_btn"
                                                    elevation="0"
                                                    >
                                                    Next
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" lg="2"></v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card
                                class="mb-12"
                                flat
                                min-height="400px"
                                >
                                    <v-form
                                    ref="form2"
                                    v-model="valid2"
                                    lazy-validation
                                    class="regForm2"
                                    >
                                        <v-row>
                                            <v-col cols="12" lg="2"></v-col>
                                            <v-col cols="12" lg="8">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <p>What states are you licensed to practice?</p>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <span v-show="licenses.length === 0" class="caption_text">No licensed information added</span>
                                                        <v-card v-for="(item, i) in licenses" :key="i" flat outlined class="px-4 py-2 mb-1 d-flex justify-space-between">
                                                            <div>{{item.state}}</div>
                                                            <div>{{item.bar_email}}</div>
                                                            <div>
                                                                <v-menu offset-y>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                        icon
                                                                        small
                                                                        class="mr-2"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        >
                                                                        <v-icon small>mdi-eye</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-list class="pa-4">
                                                                        <div class="mb-2">
                                                                            <h6>Bar Email</h6>
                                                                            <span>{{item.bar_email}}</span>
                                                                        </div>
                                                                        <div class="mb-2">
                                                                            <h6>State</h6>
                                                                            <span>{{item.state}}</span>
                                                                        </div>
                                                                        <div class="mb-2">
                                                                            <h6>Bar Number</h6>
                                                                            <span>{{item.bar_number}}</span>
                                                                        </div>
                                                                        <div class="mb-2">
                                                                            <h6>Bar Admission Year</h6>
                                                                            <span>{{item.bar_admission_year}}</span>
                                                                        </div>
                                                                        <div class="mb-2">
                                                                            <h6>Education</h6>
                                                                            <span>{{item.education}}</span>
                                                                        </div>
                                                                    </v-list>
                                                                </v-menu>
                                                                <v-btn
                                                                icon
                                                                small
                                                                @click="removeLicense(licenses, item)"
                                                                >
                                                                <v-icon small>mdi-trash-can</v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col 
                                                    v-show="licensesFormStatus === true"
                                                    cols="12" 
                                                    lg="6"
                                                    >
                                                        <v-autocomplete
                                                        v-model="barState"
                                                        label="State"
                                                        item-text="name"
                                                        hide-details="auto"
                                                        filled
                                                        :items="states"
                                                        return-object
                                                        dense
                                                        class="some-style"
                                                        >
                                                        <template v-slot:selection="{item}">
                                                            <span class="text-capitalize">{{ item.name }}</span>
                                                        </template>
                                                        <template v-slot:item="{item}">
                                                            <span class="text-capitalize">{{ item.name }}</span>
                                                        </template>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col
                                                    v-show="licensesFormStatus === true" 
                                                    cols="12" 
                                                    lg="6" 
                                                    >
                                                        <v-text-field
                                                        v-model="barEmail"
                                                        label="Verify Bar Email"
                                                        hide-details="auto"
                                                        filled
                                                        dense
                                                        class="some-style"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col 
                                                    v-show="licensesFormStatus === true"
                                                    cols="12" 
                                                    lg="3"
                                                    >
                                                        <v-text-field
                                                        v-model="barNum"
                                                        label="Bar Number"
                                                        hide-details="auto"
                                                        type="number"
                                                        filled
                                                        dense
                                                        class="some-style"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col 
                                                    v-show="licensesFormStatus === true"
                                                    cols="12" 
                                                    lg="3"
                                                    >
                                                        <v-text-field
                                                        v-model="barYear"
                                                        label="Bar Admission Year"
                                                        hide-details="auto"
                                                        type="number"
                                                        filled
                                                        dense
                                                        class="some-style"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col 
                                                    v-show="licensesFormStatus === true"
                                                    cols="12" 
                                                    lg="6"
                                                    >
                                                        <v-text-field
                                                        v-model="education"
                                                        label="Education"
                                                        hide-details="auto"
                                                        filled
                                                        dense
                                                        class="some-style"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" class="pt-5 d-flex justify-space-between">
                                                        <v-btn 
                                                        v-show="licensesFormStatus === false"
                                                        color="#023E7D" 
                                                        text
                                                        @click="licensesFormStatus = !licensesFormStatus"
                                                        >
                                                            <v-icon
                                                                left
                                                                dark
                                                            >
                                                                mdi-plus-circle-outline
                                                            </v-icon>
                                                            Add license area 
                                                        </v-btn>
                                                        <v-btn 
                                                        v-show="licensesFormStatus === true"
                                                        color="#023E7D" 
                                                        class="white--text"
                                                        elevation="0"
                                                        :disabled="!licenseCheck"
                                                        @click="addLicense"
                                                        >
                                                            <!-- <v-icon
                                                                left
                                                                dark
                                                            >
                                                                mdi-plus-circle-outline
                                                            </v-icon> -->
                                                            Add license
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-divider></v-divider>
                                                    </v-col>
                                                    <v-col cols="12" lg="6">
                                                        <p>What type of attorney are you?</p>
                                                        <v-autocomplete
                                                        v-model="types"
                                                        :items="roles"
                                                        :rules="[rules.objRequired]"
                                                        label="Select type of attorney"
                                                        multiple
                                                        filled
                                                        hide-details="auto"
                                                        class="some-style"
                                                        >
                                                        <template v-slot:selection="{ item, index }">
                                                            <span
                                                            v-if="index === 0"
                                                            class="grey--text body-2"
                                                            >
                                                            {{ types.length  }} selected
                                                            </span>
                                                        </template>
                                                        </v-autocomplete>
                                                        <v-chip
                                                        v-for="(item, i) in types"
                                                        :key="i"
                                                        class="mt-2 me-2"
                                                        small
                                                        close
                                                        color="#023E7D"
                                                        text-color="white"
                                                        @click:close="removeArea(types, item)"
                                                        >
                                                        {{item}}
                                                        </v-chip>
                                                    </v-col>
                                                    <v-col cols="12" lg="6">
                                                        <p>What's your practice area?</p>
                                                        <v-autocomplete
                                                        v-model="areas"
                                                        :items="practiceList"
                                                        label="Select practice area"
                                                        :item-text="item => item.name"
                                                        :rules="[rules.objRequired]"
                                                        filled
                                                        multiple
                                                        return-object
                                                        hide-details="auto"
                                                        class="some-style"
                                                        >
                                                        <template v-slot:selection="{ item, index }">
                                                            <span
                                                            v-if="index === 0"
                                                            class="grey--text body-2"
                                                            >
                                                            {{ areas.length  }} selected
                                                            </span>
                                                        </template>
                                                        </v-autocomplete>
                                                        <v-chip
                                                        v-for="(item, i) in areas"
                                                        :key="i"
                                                        class="mt-2 me-2"
                                                        small
                                                        close
                                                        color="#023E7D"
                                                        text-color="white"
                                                        @click:close="removeArea(areas, item)"
                                                        >
                                                        {{item.name}}
                                                        </v-chip>
                                                    </v-col>
                                                </v-row>
                                                <div class="mt-4 d-flex justify-space-between">
                                                    <v-btn 
                                                    text
                                                    large
                                                    @click="e1 = 1"
                                                    class="mr-2 reg_btn"
                                                    >
                                                    Back
                                                    </v-btn>

                                                    <!-- <v-btn
                                                    color="#023E7D"
                                                    large
                                                    dark
                                                    @click="e1 = 3"
                                                    class="reg_btn"
                                                    elevation="0"
                                                    >
                                                    Next
                                                    </v-btn> -->
                                                    <v-btn
                                                    color="#023E7D"
                                                    :loading="loading"
                                                    :disabled="loading"
                                                    large
                                                    class="reg_btn white--text"
                                                    elevation="0"
                                                    @click="imgUpload"
                                                    >
                                                    Create profile
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" lg="2"></v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                            </v-stepper-content>

                            <!-- <v-stepper-content step="3">
                                <v-card
                                class="mb-12"
                                flat
                                min-height="400px"
                                >
                                    
                                    <v-row>
                                        <v-col cols="12" lg="2"></v-col>
                                        <v-col cols="12" lg="8">
                                            <v-row>
                                                <v-col cols="12" lg="6">
                                                    <p>Provide contact and social information</p>
                                                    <v-text-field
                                                    v-model="phone"
                                                    label="Phone Number"
                                                    filled
                                                    dense
                                                    class="some-style"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" lg="6" class="d-flex align-end">
                                                    <v-text-field
                                                    v-model="website"
                                                    label="Website"
                                                    filled
                                                    dense
                                                    class="some-style"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" lg="6">
                                                    <p>Add social media</p>
                                                    <v-text-field
                                                    v-model="twitter"
                                                    label="Twitter URL"
                                                    filled
                                                    dense
                                                    class="some-style"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" lg="6" class="d-flex align-end">
                                                    <v-text-field
                                                    v-model="facebook"
                                                    label="Facebook URL"
                                                    filled
                                                    dense
                                                    class="some-style"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" lg="6">
                                                    <v-text-field
                                                    v-model="instagram"
                                                    label="Instagram URL"
                                                    filled
                                                    dense
                                                    class="some-style"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" lg="6">
                                                    <v-text-field
                                                    v-model="linkedin"
                                                    label="Linkedin URL"
                                                    filled
                                                    dense
                                                    class="some-style"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-btn 
                                            text
                                            large
                                            @click="e1 = 2"
                                            class="mr-2 reg_btn"
                                            >
                                            Back
                                            </v-btn>
                                            <v-btn
                                            color="#023E7D"
                                            dark
                                            large
                                            @click="e1 = 4"
                                            class="reg_btn"
                                            elevation="0"
                                            >
                                            Next
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" lg="2"></v-col>
                                    </v-row>

                                </v-card>

                            </v-stepper-content> -->

                            <!-- <v-stepper-content step="4">
                                <v-card
                                class="mb-12"
                                flat
                                min-height="400px"
                                >

                                    <v-row>
                                        <v-col cols="12" lg="2"></v-col>
                                        <v-col cols="12" lg="8">
                                            <v-row>
                                                <v-col cols="12">
                                                    <p>What are your fees?</p>
                                                </v-col>
                                                <v-col cols="12" lg="6">
                                                    <div class="d-flex align-start">
                                                        <v-checkbox
                                                        v-model="enabledContigency"
                                                        dense
                                                        color="#023E7D"
                                                        hide-details="auto"
                                                        class="mr-2 mt-3"
                                                        ></v-checkbox>
                                                        <v-text-field
                                                        v-model="contigency"
                                                        label="Contigency"
                                                        suffix="%"
                                                        placeholder="0"
                                                        persistent-placeholder
                                                        type="number"
                                                        :background-color="enabledContigency ? 'light-blue lighten-5' : 'grey lighten-4'"
                                                        filled
                                                        dense
                                                        class="some-style"
                                                        :disabled="!enabledContigency"
                                                        ></v-text-field>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" lg="6">
                                                    <div class="d-flex align-start">
                                                        <v-checkbox
                                                        v-model="enabledHourly"
                                                        dense
                                                        color="#023E7D"
                                                        hide-details="auto"
                                                        class="mr-2 mt-3"
                                                        ></v-checkbox>
                                                        <v-text-field
                                                        v-model="hourlyRate"
                                                        label="Hourly Rate"
                                                        prefix="$"
                                                        placeholder="0"
                                                        persistent-placeholder
                                                        type="number"
                                                        :background-color="enabledHourly ? 'light-blue lighten-5' : 'grey lighten-4'"
                                                        filled
                                                        dense
                                                        class="some-style"
                                                        :disabled="!enabledHourly"
                                                        ></v-text-field>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" lg="6">
                                                    <div class="d-flex align-start">
                                                        <v-checkbox
                                                        v-model="enabledFixed"
                                                        dense
                                                        color="#023E7D"
                                                        hide-details="auto"
                                                        class="mr-2 mt-3"
                                                        ></v-checkbox>
                                                        <v-text-field
                                                        v-model="fixedRate"
                                                        label="Fixed Rate"
                                                        prefix="$"
                                                        placeholder="0"
                                                        persistent-placeholder
                                                        type="number"
                                                        :background-color="enabledFixed ? 'light-blue lighten-5' : 'grey lighten-4'"
                                                        filled
                                                        dense
                                                        class="some-style"
                                                        :disabled="!enabledFixed"
                                                        ></v-text-field>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" lg="6"></v-col>
                                                <v-col cols="12" lg="6">
                                                    <P>What payment methods are accepted?</p>
                                                    <v-select
                                                    v-model="methods"
                                                    :items="paymentMethods"
                                                    label="Select payment method"
                                                    filled
                                                    class="some-style"
                                                    hide-details="auto"
                                                    multiple
                                                    >
                                                    <template v-slot:selection="{ item, index }">
                                                        <span
                                                        v-if="index === 0"
                                                        class="grey--text body-2"
                                                        >
                                                        {{ methods.length  }} selected
                                                        </span>
                                                    </template>
                                                    </v-select>
                                                    <v-chip
                                                    v-for="(item, i) in methods"
                                                    :key="i"
                                                    class="mt-2 me-2"
                                                    small
                                                    close
                                                    color="#023E7D"
                                                    text-color="white"
                                                    @click:close="removeMethod(methods, item)"
                                                    >
                                                    {{item}}
                                                    </v-chip>
                                                </v-col>
                                            </v-row>
                                            <v-btn 
                                            text
                                            large
                                            @click="e1 = 3"
                                            class="mr-2 reg_btn"
                                            :disabled="loading"
                                            >
                                            Back
                                            </v-btn>

                                            <v-btn
                                            color="#023E7D"
                                            :loading="loading"
                                            :disabled="loading"
                                            large
                                            class="reg_btn white--text"
                                            elevation="0"
                                            @click="imgUpload"
                                            >
                                            Create profile
                                            </v-btn>
                                        </v-col>
                                        <v-col lg="2"></v-col>
                                    </v-row>
                                </v-card>
                            </v-stepper-content> -->
                        </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :color="bgColor"
      top
      right
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
              Close
          </v-btn>
      </template>
    </v-snackbar>
    
  </div>
</template>

<script>

  export default {
    created: function () {
        this.getPractice()
        this.getAllStates()
        console.log(this.licenseCheck)
    },

    components: {
    },
    data: vm => ({
        e1: 1,
        loading: false,
        zipLoading: false,
        isLoading: false,
        menu: false,
        role: 'attorney',
        img: null,
        imgUrl: '',
        imgPlaceholder: 'https://res.cloudinary.com/dzsshr0iz/image/upload/v1652961985/Ilerah/avatar-ph_pce6l3.jpg',
        selectedFile: null,
        avatarSize: 80,
        show: false,
        email: '',
        password: '',
        rules: {
          required: value => !!value || 'Required.',
          objRequired: v => Boolean(Object.keys(v || {})[0]) || "Required.",
          min: v => v.length >= 8 || 'Minimum 8 characters password.',
          emailRules: v => /.+@.+\..+/.test(v) || 'Email must be valid.',
        },
        firstName: '',
        lastName: '',
        zipCode: null,
        city: null,
        languages: ['English', 'Spanish', 'French'],
        selectedLanguages: [],
        about: '',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

        education: '',
        barYear: '',
        barNum: '',
        barEmail: '',
        barState: null,
        licenses: [],
        roles: ['Criminal Defense', 'Civil Plaintiff', 'Civil Defense', 'Transactional'],
        types: [],
        practice: ['Bankruptcy', 'Business', 'Constitutional', 'Criminal Defense', 'Employment and Labor', 'Entertainment', 'Estate Planning', 'Family', 'Immigration', 'Intellectual Property', 'Personal Injury', 'Tax'],
        areas: [],

        phone: '',
        website: '',
        // address: '',
        twitter: '',
        facebook: '',
        instagram: '',
        linkedin: '',

        enabledContigency: false,
        enabledHourly: false,
        enabledFixed: false,
        contigency: '',
        hourlyRate: '',
        fixedRate: '',
        paymentMethods: ['Bank Deposit', 'Card', 'Cash'],
        methods: [],

        snackbar: false,
        message: '',
        bgColor: '',
        cityList: [],
        states: [],
        zipCodeList: [],
        practiceList: [],
        citySearch: null,
        zipSearch: null,
        barCitySearch: null,
        licensesFormStatus: false,
        valid1: true,
        valid2: true,
        location: {}
    }),

    watch:{
        date() {
            this.dateFormatted = this.formatDate(this.date)
        },

        zipSearch(val) {
            val && val !== this.city && this.querySelections(val)
        },

        citySearch(val) {
            val && val !== this.city && this.querySelections(val)
        },

        barCitySearch(val) {
            val && val !== this.barState && this.querySelection(val)
        },
    },

    methods: {
        nextStep() {
            let formstatus = this.$refs.form1.validate()
            if(formstatus != true) return   
            this.e1 = 2
        },

        ageCheck() {
            let selected = new Date(this.date);
            const today = new Date();
            
            let ageDifference = today.getFullYear() - selected.getFullYear();
            const monthDiff = today.getMonth() - selected.getMonth();
            
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < selected.getDate())) {
                ageDifference--;
            }

            if (ageDifference >= 18) {
                return true;
            } else {
                return 'You need to be 18+ to register.';
            }
        },

        zipCodeCheck(v) {
            this.isLoading = true

            this.$store.dispatch('getCities', v.srcElement._value)
            .then((response) => {
                this.isLoading = false
                this.zipCodeList = response.data.data
            })
            .catch((error) => {
                this.isLoading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgcolor = 'error'
            })
        },

        querySelections(v) {
            this.isLoading = true

            this.$store.dispatch('getCities', v)
            .then((response) => {
                this.isLoading = false
                this.zipCodeList = response.data.data
                // this.cities.forEach(cities=>{
                //     this.cityList.push({
                //         location: cities.primary_city+ ' ' + cities.state,
                //         primary_city: cities.primary_city,
                //         state: cities.state,
                //     })
                // })
            })
            .catch((error) => {
                this.isLoading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgcolor = 'error'
            })
        },

        zipCodeSelected() {
            this.location = this.zipCode
            this.city = `${this.zipCode.primary_city}, ${this.zipCode.state}`
        },

        getAllStates() {
            this.$store.dispatch('getStates')
            .then((response) => {
                console.log(response)
                this.states = response.data.data
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgcolor = 'error'
            })
        },

        onFileSelected(event){
            try{
                this.selectedFile = event.target.files[0]
                this.imgPlaceholder = URL.createObjectURL(this.selectedFile)
                const reader = new FileReader();
                reader.onload = () => {
                    this.img = reader.result
                };
                reader.readAsDataURL(this.selectedFile);

            } catch {
                this.img = null
            }
        },

        imgUpload() {
            let formstatus = this.$refs.form2.validate()
            if(formstatus != true) return

            this.loading = true
            if(this.selectedFile != null) {
            this.$store.dispatch('imgCloudUpload', this.selectedFile)
            .then((response) => {
                this.imgUrl = response.data.secure_url
                this.createProfile()
            })
            .catch(() => {
                console.log('an error occured')
                // this.loading = false
                // this.snackbar = true
                // this.message = error.response.data.error
                // this.bgColor = 'error'
            })
            } else {
                this.createProfile()
            }
        },

        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },

        removeLang(selectedLanguages, item) {
            var index = selectedLanguages.indexOf(item);
            if (index > -1) {
                selectedLanguages.splice(index, 1);
            }
            return selectedLanguages;
        },

        removeMethod(methods, item) {
            var index = methods.indexOf(item);
            if (index > -1) {
                methods.splice(index, 1);
            }
            return methods;
        },

        removeType(types, item) {
            var index = types.indexOf(item);
            if (index > -1) {
                types.splice(index, 1);
            }
            return types;
        },

        removeArea(areas, item) {
            var index = areas.indexOf(item);
            if (index > -1) {
                areas.splice(index, 1);
            }
            return areas;
        },

        addLicense() {
            if(this.licensesFormStatus == true) {
                this.licenses.push({
                state: this.barState.name + ' ' + this.barState.abbreviation,
                bar_email: this.barEmail,
                bar_number: this.barNum,
                bar_admission_year: this.barYear,
                education: this.education
                })

                this.barState = null
                this.barEmail = ''
                this.barNum = ''
                this.barYear = ''
                this.education = ''
                this.licensesFormStatus = false
            }
        },

        removeLicense(licenses, item) {
            var index = licenses.indexOf(item);
            if (index > -1) {
                licenses.splice(index, 1);
            }
            return licenses;
        },

        createProfile() {
            let finalAreas = [];
            this.areas.forEach(areas=>{
                finalAreas.push(
                `${areas.name}`,
                )
            })
            this.loading = true
            this.$store.dispatch('registration', {
            img: this.imgUrl,
            role: this.role,
            email: this.email,
            password: this.password,
            firstName: this.firstName,
            lastName: this.lastName,
            zipCode: this.location.zip_code,
            city: this.location.primary_city,
            state: this.city,
            country: 'US',
            languages: this.selectedLanguages,
            about: this.about,
            licensedAreas: this.licenses,
            typeAttorney: this.types,
            practiceAreas: finalAreas,
            phone: this.phone,
            website: this.website,
            dob: new Date(this.date),
            // address: this.address,
            twitter: this.twitter,
            facebook: this.facebook,
            instagram: this.instagram,
            linkedin: this.linkedin,

            contingency: this.contigency,
            hourlyRate: this.hourlyRate,
            fixedRate: this.fixedRate,
            paymentMethod: this.methods
            })
            .then((response) => {
                console.log(response)
                this.loading = false
                let userDetail = 'attorney'
                this.$store.commit('setUser', userDetail);
            })
            .catch((error) => {
                this.loading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgcolor = 'error'
            })
        },

        getPractice() {
            this.loading = true
            this.$store.dispatch('getPractice', {
            })
            .then((response) => {
            this.loading = false
            this.practiceList = response.data.data
            })
            .catch((error) => {
            this.loading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgcolor = 'error'
            })
        },

        getZipCodes() {
            this.zipLoading = true
            this.$store.dispatch('getZipCodes', this.city)
            .then((response) => {
            this.zipLoading = false
            this.zipCodeList = response.data.data
            })
            .catch((error) => {
            this.zipLoading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgcolor = 'error'
            })
        },
    },

    computed: {
        licenseCheck() {  
            return (
                this.education != '' && this.barYear != '' && this.barNum != '' && this.barState != null
            )
        },
    }

  }
</script>

<style scoped>
    .logo_img {
        width: 160px;
    }

    .auth_container {
        margin-top: 5vh;
    }

    .auth_title {
        color: #023E7D;
    }

    .reg_btn {
        margin-top: 30px;
    }

    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }

    .avatar_tag {
        cursor: pointer; 
    }

    .caption_text {
        font-size: 13px;
        color: grey;
    }

</style>