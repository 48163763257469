<template>
    <!-- <hello-world /> -->
    <div class="px-2 main">
      <v-app-bar
        color="#FFF"
        flat
      >
  
        <div>
          <v-img class="logo_img"
            src="../assets/legallync-logo-light.png"
          ></v-img>
        </div>
  
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-container class="auth_container">
        <v-row>
          <v-col cols="12" lg="8">
            <p class="status_heading">Subscription Successful! Nice to have you onboard.</p>
          </v-col>
          <v-col cols="12" lg="4" class="d-flex align-center justify-center">
            <v-icon
            color="#023E7D"
            size="200"
            >
            mdi-check-decagram
            </v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="8">
            <p class="status_subheading">Your subscription is confirmed, granting you access to connect with clients seamlessly. Get ready to elevate your practice and efficiently connect with clients seeking your expertise.</p>
            <router-link to="/"><span class="status_link">Sign In and Enjoy</span></router-link>
          </v-col>
          <v-col cols="12" lg="4"></v-col>
        </v-row>
      </v-container>
    </div>
</template>
  
<script>

export default {

    components: {

    },

    data () {
    return {

    }
    },

    methods: {

    },

}
</script>
  
<style scoped>

    .main {
        height: 100vh;
    }

    .auth_container {
        padding-top: 20vh;
    }

    .logo_img {
        width: 160px;
    }

    .status_heading {
        font-size: 4rem;
        font-weight: 900;
        color: #023E7D;
        line-height: 5rem;
    }

    .status_subheading {
        font-size: 1.2rem;
        font-weight: 600;
        color: #023E7D;
    }

    .status_link {
        font-size: 1.2rem;
        font-weight: 600;
        color: #023E7D;
        text-decoration-line: underline;
        text-decoration-color: #023E7D;
    }

    @media (max-width: 768px) {

    }

</style>