<template>
    <div>
        <v-app-bar
        color="white"
        light
        flat
        >
        <div>
            <v-img class="logo_img"
            src="../assets/legallync-logo-dark.png"
            ></v-img>
        </div>
        <v-spacer></v-spacer>
        </v-app-bar>
    <div class="main_body pa-4 d-flex align-center">
        <div class="text-center box">
            <h4 class="heading">Forgot Password?</h4>
            <span class="subheading">No worries! Simply enter your email, and we'll send you instructions on how to reset your password.</span>
            <v-form class="mt-6 mb-4">
                <v-text-field
                  v-model="email"
                  label="Email"
                  :rules="[rules.required, rules.emailRules]"
                  filled
                  dense
                  class="some-style"
                ></v-text-field>
                <v-btn
                block
                large
                color="#023E7D"
                elevation="0"
                class="white--text"
                :loading="loading"
                :disabled="loading"
                @click="submit"
                >
                Send
                </v-btn>
            </v-form>
            <router-link class="body_link" to="/">Back to login</router-link>
        </div>

        <!-- snack bar -->
        <v-snackbar
        v-model="snackbar"
        :color="bgcolor"
        dark
        right
        >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            icon
            v-bind="attrs"
            @click="snackbar = false"
            >
            <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
        </template>
        </v-snackbar>
    </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        loading: false,
        email: '',
        rules: {
          required: value => !!value || 'Required.',
          emailRules: v => /.+@.+\..+/.test(v) || 'Email must be valid',
          // emailMatch: () => (`The email and password you entered don't match`),
        },
        snackbar: false,
        message: '',
        bgcolor: '',
    }),

    methods: {
        submit() {
            this.loading = true
            this.$store.dispatch('forgotPassword', {
                email: this.email,
                action: 'reset-password'
            })
            .then(() => {
            this.loading = false
            this.message = 'Reset password link sent to your email'
            this.snackbar = true
            this.bgcolor = 'success'
            this.resetDialog = false
            })
            .catch((error) => {
            this.loading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgcolor = 'error'
            })
        },
    }
};
</script>

<style scoped>
.main_body {
    height: 100vh;
}
.logo_img {
    width: 160px;
}
.box {
    width: 400px;
    margin: auto;
}
.main_img {
    width: 50px;
    margin-bottom: 20px;
}
.heading {
    font-weight: 800;
    font-size: 25px;
}
.subheading {
    font-size: 14px;
}
.body_link {
    text-decoration: none;
    font-size: 14px;
    color: #023E7D;
}
.some-style >>> .v-input__slot::before {
    border-style: none !important;
  }

</style>