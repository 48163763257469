<template>
    <div class="main_body d-flex align-center justify-center">
        <div class="text-center">
            <v-avatar size="60" color="#023E7D" class="mb-2">
                <v-icon size="40" dark>
                    mdi-credit-card
                </v-icon>
            </v-avatar>
            <h2 class="mt-4 mb-2 mail_heading font-weight-bold">Invoice Payment</h2>
            <p class="mail_subheading">
                Enter the details below and click on the pay now button to make a secure and hassle-free payment of your invoice.
            </p>
            <div class="pt-6 pb-4">
                <stripe-element-card
                    ref="elementRef"
                    :pk="publishableKey"
                    hidePostalCode
                    @token="tokenCreated"
                />
                <v-btn
                    color="#023E7D"
                    large
                    block
                    elevation="0"
                    class="mt-5 auth_btn white--text"
                    :loading="loading"
                    @click="submit"
                >
                    <b>Pay now</b>
                </v-btn>
            </div>
            <span class="text_caption">Powered by <b>Stripe</b></span>
        </div>
    </div>
</template>
  
<script>
  import { StripeElementCard } from '@vue-stripe/vue-stripe';
  export default {
    components: {
      StripeElementCard,
    },
    mounted() {
        this.invoice_id = this.$route.query.invoice_id;
        this.invoice_token = this.$route.query.token;
        this.getInvoice()
    },

    data () {
      this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
      return {
        token: null,
        loading: false,

        invoice_id: '',
        invoice_token: '',
        invoice_no: '',
        client_email: '',
        attorney_email: '',
        amount: 0,
        service_rendered: '',
        case_id: '',
        due_date: '',
        service_hour: 0,
        client_name: '',
        attorney_name: '',
        attorney_id: '',
        invoiceDetails: {}
      };
    },
    methods: {
        getInvoice() {
            this.$store.dispatch('getInvoiceDetails', {
                invoiceId: this.invoice_id,
                invoiceToken: this.invoice_token,
            })
            .then((response) => {
                this.loading = false;
                this.invoiceDetails = response.data.data.invoice
            })
            .catch((error) => {
                this.loading = false;
                console.log(error)
            })
        },
        submit () {
            this.loading = true;
            this.$refs.elementRef.submit();
        },
        tokenCreated (token) {
                console.log(token);
                this.token = token;
                // handle the token
                // send it to your server
                this.sendTok()
        },
        sendTok() {
            this.$store.dispatch('sendToken', {
                token_id: this.token.id,
                invoice_token: this.invoice_token,
                invoice_id: this.invoice_id,
                invoice_no: this.invoiceDetails.invoice_no,
                client_email: this.invoiceDetails.client_email,
                attorney_email: this.invoiceDetails.attorney_email,
                amount: this.invoiceDetails.amount * 100,
                service_rendered: this.invoiceDetails.service_rendered,
                case_id: this.invoiceDetails.case_id,
                due_date: this.invoiceDetails.due_date,
                service_hour: this.invoiceDetails.service_hour,
                client_name: this.invoiceDetails.client_name,
                attorney_name: this.invoiceDetails.attorney_name,
                attorney_id: this.invoiceDetails.attorney_id,
            })
            .then(() => {
                this.loading = false;
                this.$router.push({
                    name: 'thanksPage',
                });
            })
            .catch((error) => {
                this.loading = false;
                console.log(error)
            })
        }
    }
  };
</script>

<style scoped>

.main_body {
    height: 100vh;
}
.mail_subheading {
    max-width: 560px;
    margin: auto;
}
.body_link {
/* text-decoration: none; */
    color: #023E7D;
    text-decoration: none;
}
.text_caption {
    font-size: 12px;
    color: grey;
}

</style>