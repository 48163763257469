<template>
  <div class="main_body d-flex align-center justify-center">
      <div class="text-center">
            <v-avatar size="60" color="#EBF3FF" class="mb-2">
                <v-icon size="40" light color="#023E7D">
                    mdi-handshake
                </v-icon>
            </v-avatar>
            <h2 class="mt-4 mb-2 mail_heading font-weight-bold">Email verification successful</h2>
            <p class="mail_subheading">
                Welcome to legallync! it is with great excitment we are really happy to have you, click the link at the to log into your account.
            </p>
            <div class="mt-4">
                <v-progress-circular
                indeterminate
                :width="2"
                class="me-1"
                size="18"
                color="#023E7D"
                ></v-progress-circular>
                <span class="redirect_text">
                    {{ message }}
                </span>
            </div>
      </div>
  </div>
</template>

<script>
    export default {
        components: {

        },

        data () {
            return {
                message: ''
            }
        },

        created: function () {
            if(this.userDetail == 'attorney') {
                this.message = 'Redirecting to payment setup...'
                setTimeout(() => {
                    this.$router.push({
                        path: '/attorney-plans',
                    })
                }, 3000);
            } else {
                this.message = 'Redirecting to log in..'
                setTimeout(() => {
                    this.$router.push({
                        path: '/client-plans',
                    })
                }, 3000);
            }
            
        },

        methods: {

        },

        computed: {
            userDetail() {
                return this.$store.state.userDetail;
            },
        }

    }
</script>

<style scoped>

.main_body {
    height: 100vh;
}

.mail_subheading {
    max-width: 560px;
    margin: auto;
}

.body_link {
/* text-decoration: none; */
    color: #023E7D;
    text-decoration: none;
}
.redirect_text {
    color: #023E7D;
    font-size: 12px;
}

</style>
