<template>
    <!-- <hello-world /> -->
    <div class="px-2">
        <v-app-bar
            color="white"
            light
            flat
        >
            <div>
            <v-img class="logo_img"
                src="../assets/legallync-logo-dark.png"
            ></v-img>
            </div>
    
            <v-spacer></v-spacer>
        </v-app-bar>
        <v-container class="auth_container">
            <v-row class="attorney_row">
                <v-col cols="12" class="text-center">
                    <h2 class="plan_title">Explore Our Plans: Unlock Exceptional Value!</h2>
                    <p class="plan_subtitle">Discover our range of plans tailored to fit your needs and budget. Get ready to experience premium legal solutions at prices that redefine affordability.</p>
                </v-col>
            </v-row>
            <v-row class="attorney_row-cards">
                <v-col 
                cols="12"
                >
                    <div 
                    class="d-flex justify-space-around"
                    >
                        <div
                        v-for="(item, i) in planTest"
                        :key="i"
                        :class="['attorney_plan-card', item.name]"
                        >
                            <div
                            class="d-flex justify-start"
                            >
                                <div class="price_area">
                                    <v-chip
                                    outlined
                                    label
                                    color="#023E7D"
                                    >
                                    <span class="plan_heading">{{ item.name }}</span>
                                    </v-chip>
                                    <!-- <span class="plan_heading">{{ clientPlan.name }}</span> -->
                                    <div class="price_box">
                                        <span class="plan_symbol">$</span>
                                        <span class="plan_cost">{{ item.price }}</span>
                                    </div>
                                </div>
                                <div>
                                    <div
                                    v-for="(item, i) in item.attributes"
                                    :key="i"
                                    >
                                        <div class="d-flex align-center">
                                            <v-icon
                                            size="15"
                                            :color="item.status ? '#34C759' : '#C60202'"
                                            class="me-2"
                                            >
                                            {{ item.status ? 'mdi-check' : 'mdi-close' }}
                                            </v-icon>
                                            <span class="my-1 plan_list-text">{{ item.name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <v-btn 
                                block 
                                large
                                dark
                                class="mt-4"
                                color="#023E7D"
                                elevation="0"
                                @click="subscribe(item)"
                                >
                                    <b>continue to {{ item.name }}</b>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
  
<script>
  
    export default {
        props: ['user_id'],
  
        components: {
        //
        },

        created: function () {
            this.$store.dispatch('getPlans')
        },

        data () {
            return {
                planTest: [
                    {
                        id: "prod_OTM9WFPr2XLOSV",
                        name: "Basic",
                        default_price: "price_1NgPRgDXJZsebuL2cOYkTjz7",
                        price: 99,
                        attributes: [
                            {name: "Auto Match (Unlimited Request Per day)", status: true},
                            {name: "Matching Via Search (1 Request Per day)", status: true},
                            {name: "Appointments & Video Calls", status: true},
                            {name: "Invoicing & Payment", status: true},
                            {name: "Profile Boost", status: false},
                            {name: "Advertisement", status: false},
                            {name: "Case Tracking", status: false},
                        ]
                    },
                    {
                        id: "prod_NSFT7dQFRqLWUT",
                        name: "Premium",
                        default_price: "price_1MhKz1DXJZsebuL2MIUi0E6C",
                        price: 125,
                        attributes: [
                            {name: "Auto Match (Unlimited Request Per day)", status: true},
                            {name: "Matching Via Search (Unlimited Request Per day)", status: true},
                            {name: "Appointments & Video Calls", status: true},
                            {name: "Invoicing & Payment", status: true},
                            {name: "Profile Boost", status: true},
                            {name: "Advertisement", status: true},
                            {name: "Case Tracking", status: true},
                        ]
                    },
                ]
            }
        },
    
        methods: {
            subscribe(item) {
                this.$router.push({
                    name: 'Subscription'
                });
                let plan = {
                        plan_id: item.default_price,
                        plan_name: item.name,
                        plan_price: item.price
                    }
                this.$store.commit('setSelectedPlan', plan);
            },
        },

        computed: {
            plans() {
                return this.$store.state.plans;
            },
        }
  
    }
</script>

<style scoped>

    .logo_img {
        width: 160px;
    }

    .plan_title {
        font-size: 2.5rem;
        line-height: 3rem;
        font-weight: 900;
        max-width: 550px;
        margin: auto;
        margin-bottom: 20px;
        color: #023E7D;
    }

    .plan_subtitle {
        font-size: 1rem;
        max-width: 700px;
        margin: auto;
        margin-bottom: 20px;
    }

    .plan_heading {
        font-size: 1.2rem;
        font-weight: 600;
        color: #023E7D;
    }

    .plan_subheading {
        font-size: 1rem;
        color: #023E7D;
        margin-bottom: 0;
    }
    .price_box {
        margin-top: 10px;
    }
    .plan_symbol {
        font-size: 2rem;
        font-family: 'Arvo', sans-serif;
        color: #023E7D;
    }
    .plan_cost {
        font-size: 5rem;
        font-family: 'Arvo', sans-serif;
        color: #023E7D;
    }
    .plan_list-text {
        font-size: .9rem;
    }
    .price_area {
        margin-right: 50px;
    }
    .client_plan-card {
        padding: 30px;
        border-radius: 10px;
        background-color: #FFFEF0;
    }
    .attorney_plan-card {
        padding: 30px;
        border-radius: 10px;
    }
    .Basic {
        background-color: #FFFCEE;
    }
    .Premium {
        background-color: #DEFCFF;
    }

</style>
  