<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12">
                    <h3 class="topic">Settings</h3>
                    <div class="mt-12 d-flex justify-start">
                        <v-avatar rounded size="80" color="#DCE8F8" class="me-4">
                            <v-icon size="60" color="#85A4C7" dark>
                                mdi-account
                            </v-icon>
                        </v-avatar>
                        <div class="pt-6">
                            <div class="d-flex justify-start">
                                <h3 class="main_topic text-capitalize">{{userDetails.user.last_name}} {{userDetails.user.first_name}}</h3>
                                <!-- <v-btn
                                icon
                                small
                                color="#023E7D"
                                >
                                <v-icon small>mdi-pencil-outline</v-icon>
                                </v-btn> -->
                            </div>
                            <span>{{userDetails.user.email}}</span>
                        </div>
                    </div>
                    <v-row class="info_section">
                        <v-col lg="6">
                            <div class="pa-4 profile_info">
                                <div class="d-flex justify-end">
                                    <v-chip
                                    color="#666666"
                                    outlined
                                    >
                                    <h4 class="me-1 btn_text">Profile Information</h4>
                                    <v-btn
                                    icon
                                    small
                                    color="#666666"
                                    @click="profileInfo"
                                    >
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    </v-chip>
                                </div>
                                <v-row class="mt-2 details_card">
                                    <v-col cols="5">
                                        <h5>State</h5>
                                        <span>{{ userDetails.user.state }}</span>
                                    </v-col>
                                    <v-col cols="3">
                                        <h5>Postal code</h5>
                                        <span>{{ userDetails.user.zipcode }}</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <h5>Birthday</h5>
                                        <span>{{ userDetails.user.dob | formatDate }}</span>
                                    </v-col>
                                    <v-col cols="5">
                                        <h5>Phone</h5>
                                        <span>{{ userDetails.user.phone }}</span>
                                    </v-col>
                                    <v-col cols="7">
                                        <h5>Language</h5>
                                        <span
                                        v-for="(item, i) in userDetails.user.spoken_language"
                                        :key="i"
                                        >
                                            <span class="text-capitalize">{{ item }}, </span>
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <h5>About</h5>
                                        <span>{{ userDetails?.profile?.contact_info?.about }}</span>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col lg="6">
                            <div class="pa-4 profile_info">
                                <div class="d-flex justify-end">
                                    <v-chip
                                    color="#666666"
                                    outlined
                                    >
                                    <h4 class="me-1 btn_text">Practice Information</h4>
                                    <v-btn
                                    icon
                                    small
                                    color="#666666"
                                    @click="practiceDetails"
                                    >
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    </v-chip>
                                </div>
                                <v-row class="mt-2 details_card">
                                    <v-col cols="12">
                                        <h5>Attorney Type</h5>
                                        <span
                                        v-for="(item, i) in userDetails.profile.attorney_type"
                                        :key="i"
                                        >
                                            <span>{{ item }}, </span>
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <h5>Practice Area</h5>
                                        <span
                                        v-for="(item, i) in userDetails.profile.practice_area"
                                        :key="i"
                                        >
                                            <span>{{ item }}, </span>
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <h5>Licensed Area</h5>
                                        <span
                                        v-for="(item, i) in userDetails.profile.licensed_areas"
                                        :key="i"
                                        >
                                            <span>{{ item.state }}, </span>
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col lg="12">
                            <div class="pa-4 profile_info">
                                <div class="d-flex justify-end">
                                    <v-chip
                                    color="#666666"
                                    outlined
                                    >
                                    <h4 class="me-1 btn_text">Contact & Fees</h4>
                                    <v-btn
                                    icon
                                    small
                                    color="#666666"
                                    @click="contactFee"
                                    >
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    </v-chip>
                                </div>
                                <v-row class="mt-2 details_card">
                                    <v-col cols="3">
                                        <h5>Website</h5>
                                        <span>{{ userDetails.profile.contact_info.website }}</span>
                                    </v-col>
                                    <v-col cols="3">
                                        <h5>Payment Method</h5>
                                        <span
                                        v-for="(item, i) in profileDetails.payment_plan.payment_method"
                                        :key="i"
                                        >
                                            <span class="text-capitalize">{{ item }}, </span>
                                        </span>
                                    </v-col>
                                    <v-col cols="2">
                                        <h5>Contigency</h5>
                                        <span>{{ profileDetails.payment_plan.contigency }}%</span>
                                    </v-col>
                                    <v-col cols="2">
                                        <h5>Hourly Rate</h5>
                                        <span>${{ profileDetails.payment_plan.hourly_rate }}</span>
                                    </v-col>
                                    <v-col cols="2">
                                        <h5>Fixed Rate</h5>
                                        <span>${{ profileDetails.payment_plan.fixed_rate }}</span>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="list_section">
                        <!-- <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="30" color="#DCE8F8" class="me-4">
                                        <v-icon size="18" color="#023E7D" dark>
                                          mdi-information-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">About Us</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col> -->
                        <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="30" color="#DCE8F8" class="me-4">
                                        <v-icon size="18" color="#023E7D" dark>
                                          mdi-message-alert-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">Send Feedback</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                        <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="30" color="#DCE8F8" class="me-4">
                                        <v-icon size="18" color="#023E7D" dark>
                                          mdi-credit-card-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span
                                    v-if="profileDetails?.has_stripe_account"
                                    class="topic font-weight-medium"
                                    >
                                    {{ stripeDetails?.bank_name }}{{ stripeDetails?.brand }} | <span>****{{ stripeDetails?.last4 }}</span>
                                    </span>
                                    <span 
                                    v-else
                                    class="topic font-weight-medium"
                                    >
                                    Stripe Setup
                                    </span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    v-if="profileDetails?.has_stripe_account"
                                    icon
                                    color="#023E7D"
                                    @click="secondaryCheck = true"
                                    >
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                    v-else
                                    icon
                                    color="#023E7D"
                                    @click="setupStripe = true"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                        <!-- <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="34" color="#DCE8F8" class="me-4">
                                        <v-icon color="#023E7D" dark>
                                          mdi-credit-card-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">Payment Information</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    @click="PaymentInfo = true"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col> -->
                        <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="30" color="#DCE8F8" class="me-4">
                                        <v-icon size="18" color="#023E7D" dark>
                                          mdi-logout-variant
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">Logout</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    to="/logout"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    
                </v-col>
                
            </v-row>
        </v-container>

        <!-- Stripe Setup -->
        <v-dialog
        v-model="setupStripe"
        max-width="500"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Stripe Setup
                </v-card-title>
            </div>
            <v-card-text 
            class="mt-8"
            >
                <v-row>
                    <v-col cols="12">
                        <p>What's your busniess website?</p>
                        <v-text-field
                        v-model="website"
                        label="Website"
                        filled
                        dense
                        hide-details="auto"
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-1">
                        <span>What's your Address and postal code?</span>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <v-text-field
                        v-model="address"
                        label="Address"
                        filled
                        dense
                        hide-details="auto"
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" class="d-flex align-end">
                        <v-text-field
                        v-model="postalCode"
                        label="Postal Code"
                        filled
                        dense
                        hide-details="auto"
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-text-field
                        v-model="state"
                        label="State"
                        filled
                        dense
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-btn 
                        block 
                        color="#023E7D" 
                        depressed 
                        dark
                        :loading="stripeLoader"
                        @click="createStripe"
                        >
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- secondary check -->
        <v-dialog
        v-model="secondaryCheck"
        persistent
        max-width="310"
        >
        <v-card>
            <v-card-title>
            Do you want to delete your stripe account setup?
            </v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                light
                class="me-2 optionCloseBtn"
                elevation="0"
                @click="cancelDialog = false"
            >
                No, i don’t
            </v-btn>
            <v-btn
                color="#053669"
                dark
                elevation="0"
                :loading="deleteStripeAcct"
                @click="deleteSetup"
            >
                Yes, i do
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>


        <!-- Payment Information -->
        <v-dialog
        v-model="PaymentInfo"
        max-width="700"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Payment Information
                </v-card-title>
            </div>
            <v-card-text class="mt-8">
                <v-row>
                    <v-col cols="12" lg="12">
                        <span>Account Details</span>
                        <v-list class="mt-3">
                            <v-list-item class="px-0">
                                <v-list-item-content class="py-0">
                                    <div class="attorney_card d-flex justify-space-between align-center">
                                        <div class="attorney_card-box">
                                            <h4 class="attorney_list-title">0048858084</h4>
                                            <span class="attorney_list-subtitle">Ameris Bank</span>
                                        </div>
                                        <v-menu bottom left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                            color="#023E7D"
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item>
                                            <v-list-item-title>Delete</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        </v-menu>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" lg="12">
                        <span>Add a new account details</span>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-select
                        v-model="bank"
                        :items="banks"
                        filled
                        label="Select Bank"
                        class="some-style"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-text-field
                        label="Account Number"
                        filled
                        dense
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-text-field
                        v-model="cosName"
                        label="Costumer's Name"
                        filled
                        dense
                        readonly
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-btn class="me-2" color="#023E7D" depressed outlined dark>
                            Cancel
                        </v-btn>
                        <v-btn color="#023E7D" depressed dark>
                            send invoice
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- Stripe Account Completion -->
        <v-dialog
        v-model="completeSetup"
        max-width="450"
        >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="mt-8">
                <v-row>
                    <v-col lg="12">
                        <p>Thank you for initializing your account! To complete your Stripe Express account setup, please click on the link below</p>
                        <p>
                            <a :href="stripeUrl" target="_blank" at @click="completeSetup = false, setupStripe = false">Complete stripe setup.</a>
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- edit profile info -->
        <v-dialog
        v-model="profileInfoDialog"
        max-width="700"
        >
            <v-card>
                <v-card-title class="card_title">
                Update Profile
                </v-card-title>
                <v-card-text class="mt-8">
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="firstName"
                            label="First Name"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="lastName"
                            label="Last Name"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="phone"
                            label="Phone Number"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    label="MMDDYYYY"
                                    append-icon="mdi-calendar-blank-outline"
                                    hide-details="auto"
                                    hint="Most be 18 and above"
                                    persistent-hint
                                    filled
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    class="some-style"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="date"
                                no-title
                                scrollable
                                :max="maxDate"
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-select
                            v-model="selectedLanguages"
                            :items="languages"
                            label="Select Language"
                            hide-details="auto"
                            filled
                            multiple
                            class="some-style"
                            >
                            <template v-slot:selection="{ index }">
                                <span
                                v-show="index === 0"
                                class="grey--text body-2"
                                >
                                {{ selectedLanguages.length }} selected
                                </span>
                            </template>
                            </v-select>
                            <v-chip
                            v-for="(item, i) in selectedLanguages"
                            :key="i"
                            class="mt-2 me-2"
                            close
                            small
                            color="#023E7D"
                            text-color="white"
                            @click:close="removeLang(selectedLanguages, item)"
                            >
                            {{item}}
                            </v-chip>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-textarea
                            v-model="about"
                            :row="2"
                            filled
                            label="About"
                            auto-grow
                            class="some-style"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-btn 
                            class="mt-4" 
                            color="#023E7D" 
                            depressed 
                            dark 
                            block
                            :loading="profileLoader"
                            @click="updateProfile"
                            >
                                Update Details
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- edit contact and fee info -->
        <v-dialog
        v-model="contactFeeDialog"
        max-width="700"
        >
            <v-card>
                <v-card-title class="card_title">
                Update Contact & Fee
                </v-card-title>
                <v-card-text class="mt-8">
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="site"
                            label="Website"
                            hide-details="auto"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-select
                            v-model="methods"
                            :items="paymentMethods"
                            label="Select payment method"
                            filled
                            class="some-style"
                            hide-details="auto"
                            multiple
                            >
                            <template v-slot:selection="{ item, index }">
                                <span
                                v-if="index === 0"
                                class="grey--text body-2"
                                >
                                {{ methods.length  }} selected
                                </span>
                            </template>
                            </v-select>
                            <v-chip
                            v-for="(item, i) in methods"
                            :key="i"
                            class="mt-2 me-2"
                            small
                            close
                            color="#023E7D"
                            text-color="white"
                            @click:close="removeMethod(methods, item)"
                            >
                            {{item}}
                            </v-chip>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <div class="d-flex align-start">
                                <v-checkbox
                                v-model="enabledContigency"
                                dense
                                color="#023E7D"
                                hide-details="auto"
                                class="mr-2 mt-3"
                                ></v-checkbox>
                                <v-text-field
                                v-model="contigency"
                                label="Contigency"
                                suffix="%"
                                placeholder="0"
                                hide-details="auto"
                                persistent-placeholder
                                type="number"
                                :background-color="enabledContigency ? 'light-blue lighten-5' : 'grey lighten-4'"
                                filled
                                class="some-style"
                                :disabled="!enabledContigency"
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <div class="d-flex align-start">
                                <v-checkbox
                                v-model="enabledHourly"
                                dense
                                color="#023E7D"
                                hide-details="auto"
                                class="mr-2 mt-3"
                                ></v-checkbox>
                                <v-text-field
                                v-model="hourlyRate"
                                label="Hourly Rate"
                                prefix="$"
                                placeholder="0"
                                hide-details="auto"
                                persistent-placeholder
                                type="number"
                                :background-color="enabledHourly ? 'light-blue lighten-5' : 'grey lighten-4'"
                                filled
                                class="some-style"
                                :disabled="!enabledHourly"
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <div class="d-flex align-start">
                                <v-checkbox
                                v-model="enabledFixed"
                                dense
                                color="#023E7D"
                                hide-details="auto"
                                class="mr-2 mt-3"
                                ></v-checkbox>
                                <v-text-field
                                v-model="fixedRate"
                                label="Fixed Rate"
                                prefix="$"
                                placeholder="0"
                                hide-details="auto"
                                persistent-placeholder
                                type="number"
                                :background-color="enabledFixed ? 'light-blue lighten-5' : 'grey lighten-4'"
                                filled
                                class="some-style"
                                :disabled="!enabledFixed"
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-btn 
                            class="mt-4" 
                            color="#023E7D" 
                            depressed 
                            dark 
                            block
                            :loading="profileLoader"
                            @click="updateContactFee"
                            >
                                Update Contact Info
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- edit Practice info -->
        <v-dialog
        v-model="practiceInfoDialog"
        max-width="700"
        >
            <v-card>
                <v-card-title class="card_title">
                Practice Information
                </v-card-title>
                <v-card-text class="mt-8">
                    <v-row>
                        <v-col cols="12">
                            <p>What states are you licensed to practice?</p>
                        </v-col>
                        <v-col cols="12">
                            <span v-show="licenses.length === 0" class="caption_text">No licensed information added</span>
                            <v-card v-for="(item, i) in licenses" :key="i" flat outlined class="px-4 py-2 mb-1 d-flex justify-space-between">
                                <div>{{item.state}}</div>
                                <div>{{item.bar_email}}</div>
                                <div>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                            icon
                                            small
                                            class="mr-2"
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon small>mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="pa-4">
                                            <div class="mb-2">
                                                <h6>Bar Email</h6>
                                                <span>{{item.bar_email}}</span>
                                            </div>
                                            <div class="mb-2">
                                                <h6>State</h6>
                                                <span>{{item.state}}</span>
                                            </div>
                                            <div class="mb-2">
                                                <h6>Bar Number</h6>
                                                <span>{{item.bar_number}}</span>
                                            </div>
                                            <div class="mb-2">
                                                <h6>Bar Admission Year</h6>
                                                <span>{{item.bar_admission_year}}</span>
                                            </div>
                                            <div class="mb-2">
                                                <h6>Education</h6>
                                                <span>{{item.education}}</span>
                                            </div>
                                        </v-list>
                                    </v-menu>
                                    <v-btn
                                    icon
                                    small
                                    @click="removeLicense(licenses, item)"
                                    >
                                    <v-icon small>mdi-trash-can</v-icon>
                                    </v-btn>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col 
                        v-show="licensesFormStatus"
                        cols="12" 
                        lg="6"
                        >
                            <v-autocomplete
                            v-model="barState"
                            label="State"
                            item-text="name"
                            hide-details="auto"
                            filled
                            :items="states"
                            return-object
                            dense
                            class="some-style"
                            >
                            <template v-slot:selection="{item}">
                                <span class="text-capitalize">{{ item.name }}</span>
                            </template>
                            <template v-slot:item="{item}">
                                <span class="text-capitalize">{{ item.name }}</span>
                            </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col
                        v-show="licensesFormStatus === true" 
                        cols="12" 
                        lg="6" 
                        >
                            <v-text-field
                            v-model="barEmail"
                            label="Verify Bar Email"
                            hide-details="auto"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col 
                        v-show="licensesFormStatus === true"
                        cols="12" 
                        lg="3"
                        >
                            <v-text-field
                            v-model="barNum"
                            label="Bar Number"
                            hide-details="auto"
                            type="number"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col 
                        v-show="licensesFormStatus === true"
                        cols="12" 
                        lg="3"
                        >
                            <v-text-field
                            v-model="barYear"
                            label="Bar Admission Year"
                            hide-details="auto"
                            type="number"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col 
                        v-show="licensesFormStatus === true"
                        cols="12" 
                        lg="6"
                        >
                            <v-text-field
                            v-model="education"
                            label="Education"
                            hide-details="auto"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-5 d-flex justify-space-between">
                            <v-btn 
                            v-show="licensesFormStatus === false"
                            color="#023E7D" 
                            text
                            @click="licensesFormStatus = !licensesFormStatus"
                            >
                                <v-icon
                                    left
                                    dark
                                >
                                    mdi-plus-circle-outline
                                </v-icon>
                                Add license area 
                            </v-btn>
                            <div class="justify-start align-center">
                                <v-btn 
                                v-show="licensesFormStatus === true"
                                color="#023E7D" 
                                class="me-2 white--text"
                                elevation="0"
                                :disabled="!licenseCheck"
                                @click="addLicense"
                                >
                                    Add license
                                </v-btn>
                                <v-btn v-show="licensesFormStatus" icon @click="licensesFormStatus = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <p>What type of attorney are you?</p>
                            <v-autocomplete
                            v-model="types"
                            :items="roles"
                            :rules="[rules.objRequired]"
                            label="Select type of attorney"
                            filled
                            multiple
                            return-object
                            hide-details="auto"
                            class="some-style"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <p>What's your practice area?</p>
                            <v-autocomplete
                            v-model="areas"
                            :items="practiceList"
                            label="Select practice area"
                            :item-text="item => item"
                            :rules="[rules.objRequired]"
                            filled
                            multiple
                            return-object
                            hide-details="auto"
                            class="some-style"
                            >
                            <template v-slot:selection="{ item, index }">
                                <span
                                v-if="index === 0"
                                class="grey--text body-2"
                                >
                                {{ areas.length  }} selected
                                </span>
                            </template>
                            </v-autocomplete>
                            <v-chip
                            v-for="(item, i) in areas"
                            :key="i"
                            class="mt-2 me-2"
                            small
                            close
                            color="#023E7D"
                            text-color="white"
                            @click:close="removeArea(areas, item)"
                            >
                            {{item}}
                            </v-chip>
                        </v-col>
                        <v-col cols="12">
                            <v-btn 
                            class="mt-4" 
                            color="#023E7D" 
                            depressed 
                            dark 
                            block
                            :loading="profileLoader"
                            @click="updatePracticeInfo"
                            >
                                Update Practice Info
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>


        <!-- snackbar -->
        <v-snackbar
        v-model="snackbar"
        :color="bgColor"
        top
        right
        >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import Sidebar from '../../components/attorney/SidebarAttorney.vue'
import Navbar from '../../components/attorney/NavbarAttorney.vue'

import moment from 'moment'

  export default {
    
    created: function () {
        this.getStripe()
        this.getPractice()
        this.getAllStates()
    },

    mounted () {
      this.calculateDateForAge()
    },

    components: {
        Sidebar, Navbar
    },

    data () {
      return {
        firstName: '',
        lastName: '',
        selectedLanguages: [],
        languages: ['English', 'Spanish', 'French'],
        phone: '',
        about: '',
        site: '',
        enabledContigency: false,
        enabledHourly: false,
        enabledFixed: false,
        contigency: '',
        hourlyRate: '',
        fixedRate: '',
        paymentMethods: ['Bank Deposit', 'Card', 'Cash'],
        methods: [],
        menu: false,
        date: null,
        rules: {
          required: value => !!value || 'Required.',
          objRequired: v => Boolean(Object.keys(v || {})[0]) || "Required.",
        },
        
        PaymentInfo: false,
        stripeLoader: false,
        bank: '',
        cosName: '',
        banks: [],
        setupStripe: false,
        website: '',
        address: '',
        state: '',
        postalCode: '',

        snackbar: false,
        message: '',
        bgColor: '',

        stripeUrl: '',
        completeSetup: false,

        stripeDetails: {},
        secondaryCheck: false,
        deleteStripeAcct: false,
        profileLoader: false,
        maxDate: '',

        contactFeeDialog: false,
        practiceInfoDialog: false,
        profileInfoDialog: false,
        barCitySearch: null,
        licensesFormStatus: false,
        education: '',
        barYear: '',
        barNum: '',
        barEmail: '',
        barState: null,
        states: [],
        licenses: [],
        roles: ['Criminal Defense', 'Civil Plaintiff', 'Civil Defense', 'Transactional'],
        types: null,
        practiceList: [],
        practice: ['Bankruptcy', 'Business', 'Constitutional', 'Criminal Defense', 'Employment and Labor', 'Entertainment', 'Estate Planning', 'Family', 'Immigration', 'Intellectual Property', 'Personal Injury', 'Tax'],
        areas: [],
      }
    },

    methods: {
        profileInfo() {
            this.firstName = this.userDetails.user.first_name
            this.lastName = this.userDetails.user.last_name
            this.phone = this.userDetails.user.phone
            this.selectedLanguages = this.userDetails.user.spoken_language
            this.date = (new Date(Date.now(this.userDetails.user.dob) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            this.profileInfoDialog = true
            this.about = this.userDetails.profile.contact_info.about
        },

        practiceDetails() {
            this.practiceInfoDialog = true
            this.licenses = this.userDetails.profile.licensed_areas;
            this.types = this.userDetails.profile.attorney_type;
            this.areas = this.userDetails.profile.practice_area;
            console.log(this.userDetails.profile.attorney_type)
            console.log(this.userDetails.profile.practice_area)
            // let practiceAreas  = this.userDetails.profile.practice_area
            // practiceAreas.forEach(areas=>{
            //     this.areas.push({
            //         name: areas,
            //         description: ''
            //     })
            // })
        },

        getAllStates() {
            this.$store.dispatch('getStates')
            .then((response) => {
                console.log(response)
                this.states = response.data.data
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgcolor = 'error'
            })
        },

        addLicense() {
            if(this.licensesFormStatus == true) {
                this.licenses.push({
                state: this.barState.name + ' ' + this.barState.abbreviation,
                bar_email: this.barEmail,
                bar_number: this.barNum,
                bar_admission_year: this.barYear,
                education: this.education
                })

                this.barState = null
                this.barEmail = ''
                this.barNum = ''
                this.barYear = ''
                this.education = ''
                this.licensesFormStatus = false
            }
        },

        removeLicense(licenses, item) {
            var index = licenses.indexOf(item);
            if (index > -1) {
                licenses.splice(index, 1);
            }
            return licenses;
        },

        contactFee() {
            this.site = this.userDetails.profile.contact_info.website;
            this.contigency = this.paymentPlan.contigency;
            this.hourlyRate = this.paymentPlan.hourly_rate;
            this.fixedRate = this.paymentPlan.fixed_rate;
            this.methods = this.paymentPlan.payment_method;
            this.contactFeeDialog = true
        },

        updateProfile() {
            this.profileLoader = true
            this.$store.dispatch('updateAttorneyProfile', {
                first_name: this.firstName,
                last_name: this.lastName,
                phone: this.phone,
                dob: new Date(this.date),
                spoken_language: this.selectedLanguages,
                about: this.about
            })
            .then(() => {
            this.profileLoader = false
            this.$store.dispatch('getUserDetails')
            this.snackbar = true
            this.message = 'Profile successfully updated'
            this.bgColor = 'success'
            this.profileInfoDialog = false
            })
            .catch((error) => {
            this.profileLoader = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        updateContactFee() {
            this.profileLoader = true
            this.$store.dispatch('updateAttorneyProfile', {
                contigency: this.contigency,
                hourly_rate: this.hourlyRate,
                fixed_rate: this.fixedRate,
                payment_method: this.methods,
                website: this.site,
            })
            .then(() => {
            this.profileLoader = false
            this.$store.dispatch('getUserDetails')
            this.snackbar = true
            this.message = 'Profile successfully updated'
            this.bgColor = 'success'
            this.contactFeeDialog = false
            })
            .catch((error) => {
            this.profileLoader = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        updatePracticeInfo() {
            let finalAreas = [];
            this.areas.forEach(areas=>{
                finalAreas.push(
                `${areas.name}`,
                )
            })
            this.profileLoader = true
            this.$store.dispatch('updateAttorneyProfile', {
                licensed_areas: this.licenses,
                attorney_type: this.types,
                practice_area: finalAreas
            })
            .then(() => {
            this.profileLoader = false
            this.$store.dispatch('getUserDetails')
            this.snackbar = true
            this.message = 'Profile successfully updated'
            this.bgColor = 'success'
            this.practiceInfoDialog = false
            })
            .catch((error) => {
            this.profileLoader = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        calculateDateForAge() {
            const dateFor18YearsAgo = moment().subtract(18, 'years');
            this.maxDate = dateFor18YearsAgo.format('YYYY-MM-DD');
        },

        getPractice() {
            this.loading = true
            this.$store.dispatch('getPractice', {
            })
            .then((response) => {
            this.loading = false
            let practiceAreas  = response.data.data
            practiceAreas.forEach(areas=>{
                this.practiceList.push(`${areas.name}`)
            })
            })
            .catch((error) => {
            this.loading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        removeLang(selectedLanguages, item) {
            var index = selectedLanguages.indexOf(item);
            if (index > -1) {
                selectedLanguages.splice(index, 1);
            }
            return selectedLanguages;
        },

        removeType(types, item) {
            var index = types.indexOf(item);
            if (index > -1) {
                types.splice(index, 1);
            }
            return types;
        },

        removeArea(areas, item) {
            var index = areas.indexOf(item);
            if (index > -1) {
                areas.splice(index, 1);
            }
            return areas;
        },

        deleteSetup() {
            this.deleteStripeAcct = true
            this.$store.dispatch('deleteStripeSetup')
            .then((response) => {
                console.log(response)
                this.getStripe()
                this.snackbar = true
                this.message = 'Stripe account deleted'
                this.bgColor = 'success'
                this.deleteStripeAcct = false
                this.secondaryCheck = false
                this.$store.dispatch('getUserDetails')
                // this.$store.dispatch('getUser')
            })
            .catch((error) => {
                this.deleteStripeAcct = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        getStripe() {
            this.$store.dispatch('getStripeAcct')
            .then((response) => {
                let stripeState =  response.data.data
                if('external_accounts' in stripeState) {
                    this.stripeDetails = response.data.data?.external_accounts?.data[0]
                } else {
                    this.stripeDetails = {bank_name: '', brand: '', last4: ''}
                }
                console.log('Stripe Details')
                console.log(this.stripeDetails)
                console.log(response.data.data)
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },
        createStripe() {
            this.stripeLoader = true
            this.$store.dispatch('createStripeAccount', {
                website_url: this.website,
                address_line1: this.address,
                state: this.state,
                address_postal_code: this.postalCode
            })
            .then((response) => {
                console.log(response)
                this.stripeLoader = false
                this.stripeUrl = response.data.data.url
                console.log(this.stripeUrl)
                this.snackbar = true
                this.message = 'Stripe account setup'
                this.bgColor = 'success'
                this.completeSetup = true
                this.$store.dispatch('getUserDetails')
            })
            .catch((error) => {
                this.stripeLoader = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },
    },

    computed: {
      userDetails() {
        return this.$store.state.userData;
      },

      profileDetails() {
        return this.$store.state.userData.profile;
      },

      paymentPlan() {
        return this.$store.state.userData.profile.payment_plan;
      },

      contactInfo() {
        return this.$store.state.userData.profile.payment_plan.contact_info;
      },

      licenseCheck() {  
            return (
                this.education != '' && this.barYear != '' && this.barNum != '' && this.barState != null
            )
        },
    }

  }
</script>

<style scoped>
    .main_topic {
        font-size: 20px;
        color: #023E7D;
    }
    .topic {
        color: #023E7D;
        font-size: 14px;
    }
    .btn_text {
        color: #666666;
        font-size: 14px;
    }
    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }
    .card_figure-text {
        color: #023E7D;
    }
    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }
    .attorney_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }
    .attorney_card-box {
        height: 34px;
    }
    .card_dheading {
        color: #FFFFFF;
        margin-top: 25px;
        margin-left: 8px;
    }
    .list_text {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
    }
    .card_lheading {
        color: #023E7D;
    }
    .card_lsubheading {
        color: #585F6C;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }
    .card_dsubheading {
        color: #FFFFFF;
        font-size: 12px;
    }
    .body_llink {
        color: #023E7D;
        font-size: 14px;
        text-decoration: none;
    }
    .body_dlink {
        color: #FFFFFF;
        font-size: 14px;
        text-decoration: none;
    }
    .info_section {
        margin-top: 25px;
    }
    .profile_info {
        background-color: #FBFBFB;
    }
    .list_section {
        margin-top: 15px;
    }
    .attorney_list-title {
        color: #023047;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
    }
    .attorney_list-subtitle {
        font-size: 13px;
    }
    .title_bg {
        background-color: #023E7D;
        color: #fff;
    }
    .profile_details {
        background-color: #FBFBFB;
    }

    .details_card {
        color: #023E7D;
    }

</style>