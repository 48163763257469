<template>
    <div>
        <v-btn
        small
        color="#023E7D"
        text
        @click="setCalenDialog = true"
        >
        <v-icon
            left
            small
        >
            mdi-plus-circle-outline
        </v-icon>
        Set Calendar
        </v-btn>
        
        <v-dialog
        v-model="setCalenDialog"
        scrollable
        max-width="900"
        >
        <v-card>
            <v-card-title class="card_title">
            consultation Setup
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-4" style="max-height: 500px;">

                <v-stepper class="elevation-0" v-model="e1">
                    <v-stepper-header class="elevation-0">
                        <v-stepper-step
                            :complete="e1 > 1"
                            step="1"
                            color="#023E7D"
                        >
                            Default Schedule
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="e1 > 2"
                            step="2"
                            color="#023E7D"
                        >
                            Block Date
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step 
                            :complete="e1 > 3"
                            step="3"
                            color="#023E7D"
                        >
                            Block Time
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-row>
                                <v-col lg="8">
                                    <v-row>
                                        <v-col cols="4">
                                            <div class="d-flex justify-start align-center">
                                                <h4 class="mb-2">Monday</h4>
                                                <v-btn
                                                class="me-2 mb-2"
                                                @click="selectDay('Monday')"
                                                icon
                                                >
                                                <v-icon
                                                >
                                                mdi-plus
                                                </v-icon>
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-chip
                                                v-show="item.day === 'Monday'"
                                                v-for="(item, i) in availableTime"
                                                :key="i"
                                                class="me-2 mb-2"
                                                close
                                                small
                                                color="#caf0f8"
                                                text-color="#023E7D"
                                                @click:close="removeTime(availableTime, item)"
                                                >
                                                <b>{{item.from | resetTime}} - {{item.to | resetTime}}</b>
                                                </v-chip>
                                            </div>
                                        </v-col>
                                        <v-col lg="4">
                                            <div class="d-flex justify-start align-center">
                                                <h4 class="mb-2">Tuesday</h4>
                                                <v-btn
                                                class="me-2 mb-2"
                                                @click="selectDay('Tuesday')"
                                                icon
                                                >
                                                <v-icon
                                                >
                                                mdi-plus
                                                </v-icon>
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-chip
                                                v-show="item.day === 'Tuesday'"
                                                v-for="(item, i) in availableTime"
                                                :key="i"
                                                class="me-2 mb-2"
                                                close
                                                small
                                                color="#caf0f8"
                                                text-color="#023E7D"
                                                @click:close="removeTime(availableTime, item)"
                                                >
                                                <b>{{item.from | resetTime}} - {{item.to | resetTime}}</b>
                                                </v-chip>
                                            </div>
                                        </v-col>
                                        <v-col lg="4">
                                            <div class="d-flex justify-start align-center">
                                                <h4 class="mb-2">Wednesday</h4>
                                                <v-btn
                                                class="me-2 mb-2"
                                                @click="selectDay('Wednesday')"
                                                icon
                                                >
                                                <v-icon
                                                >
                                                mdi-plus
                                                </v-icon>
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-chip
                                                v-show="item.day === 'Wednesday'"
                                                v-for="(item, i) in availableTime"
                                                :key="i"
                                                class="me-2 mb-2"
                                                close
                                                small
                                                color="#caf0f8"
                                                text-color="#023E7D"
                                                @click:close="removeTime(availableTime, item)"
                                                >
                                                <b>{{item.from | resetTime}} - {{item.to | resetTime}}</b>
                                                </v-chip>
                                            </div>
                                        </v-col>
                                        <v-col lg="4">
                                            <div class="d-flex justify-start align-center">
                                                <h4 class="mb-2">Thursday</h4>
                                                <v-btn
                                                class="me-2 mb-2"
                                                @click="selectDay('Thursday')"
                                                icon
                                                >
                                                <v-icon
                                                >
                                                mdi-plus
                                                </v-icon>
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-chip
                                                v-show="item.day === 'Thursday'"
                                                v-for="(item, i) in availableTime"
                                                :key="i"
                                                class="me-2 mb-2"
                                                close
                                                small
                                                color="#caf0f8"
                                                text-color="#023E7D"
                                                @click:close="removeTime(availableTime, item)"
                                                >
                                                <b>{{item.from | resetTime}} - {{item.to | resetTime}}</b>
                                                </v-chip>
                                            </div>
                                        </v-col>
                                        <v-col lg="4">
                                            <div class="d-flex justify-start align-center">
                                                <h4 class="mb-2">Friday</h4>
                                                <v-btn
                                                class="me-2 mb-2"
                                                @click="selectDay('Friday')"
                                                icon
                                                >
                                                <v-icon
                                                >
                                                mdi-plus
                                                </v-icon>
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-chip
                                                v-show="item.day === 'Friday'"
                                                v-for="(item, i) in availableTime"
                                                :key="i"
                                                class="me-2 mb-2"
                                                close
                                                small
                                                color="#caf0f8"
                                                text-color="#023E7D"
                                                @click:close="removeTime(availableTime, item)"
                                                >
                                                <b>{{item.from | resetTime}} - {{item.to | resetTime}}</b>
                                                </v-chip>
                                            </div>
                                        </v-col>
                                        <v-col lg="4">
                                            <div class="d-flex justify-start align-center">
                                                <h4 class="mb-2">Saturday</h4>
                                                <v-btn
                                                class="me-2 mb-2"
                                                @click="selectDay('Saturday')"
                                                icon
                                                >
                                                <v-icon
                                                >
                                                mdi-plus
                                                </v-icon>
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-chip
                                                v-show="item.day === 'Saturday'"
                                                v-for="(item, i) in availableTime"
                                                :key="i"
                                                class="me-2 mb-2"
                                                close
                                                small
                                                color="#caf0f8"
                                                text-color="#023E7D"
                                                @click:close="removeTime(availableTime, item)"
                                                >
                                                <b>{{item.from | resetTime}} - {{item.to | resetTime}}</b>
                                                </v-chip>
                                            </div>
                                        </v-col>
                                        <v-col lg="4">
                                            <div class="d-flex justify-start align-center">
                                                <h4 class="mb-2">Sunday</h4>
                                                <v-btn
                                                class="me-2 mb-2"
                                                @click="selectDay('Sunday')"
                                                icon
                                                >
                                                <v-icon
                                                >
                                                mdi-plus
                                                </v-icon>
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-chip
                                                v-show="item.day === 'Sunday'"
                                                v-for="(item, i) in availableTime"
                                                :key="i"
                                                class="me-2 mb-2"
                                                close
                                                small
                                                color="#caf0f8"
                                                text-color="#023E7D"
                                                @click:close="removeTime(availableTime, item)"
                                                >
                                                <b>{{item.from | resetTime}} - {{item.to | resetTime}}</b>
                                                </v-chip>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col lg="4">
                                    <p>Set Appointment Duration</p>
                                    <v-select
                                    v-model="duration"
                                    :items="durations"
                                    filled
                                    dense
                                    label="Duration"
                                    class="some-style"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <div class="d-flex justify-end">
                                <v-btn
                                class="me-2"
                                color="#023E7D"
                                :disabled="availableTimeCount == 0 ? true : false"
                                dark
                                text
                                @click="e1 = 2"
                                >
                                Skip
                                </v-btn>
                                <v-btn
                                color="#023E7D"
                                elevation="0"
                                dark
                                :loading="setCalendarLoader"
                                @click="setCalendar"
                                >
                                Save Schedule
                                </v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <span>Prevent unwanted appointments by selecting and blocking dates on your calendar either by selecting dates, selecting a range or both. Keep your availability in check and maintain control over your schedule.</span>
                            <v-expansion-panels accordion flat>
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header expand-icon="mdi-plus">
                                    <h4>Block date by selection</h4>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col lg="7">
                                            <v-date-picker
                                                color="#023E7D"
                                                v-model="dates"
                                                full-width
                                                multiple
                                                :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                            ></v-date-picker>
                                        </v-col>
                                        <v-col lg="5">
                                            <p>Save the selected date(s) on the calendar by clicking the button below</p>
                                            <div class="d-flex justify-end">
                                                <v-btn
                                                color="#023E7D"
                                                elevation="0"
                                                dark
                                                :loading="dateRandomLoader"
                                                @click="BlockDate('random')"
                                                >
                                                Save Selection
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header expand-icon="mdi-plus">
                                    <h4>Block date by range</h4>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col lg="7">
                                            <v-date-picker
                                                color="#023E7D"
                                                v-model="dateRange"
                                                full-width
                                                range
                                                :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                            ></v-date-picker>
                                        </v-col>
                                        <v-col lg="5">
                                            <p>Save the selected date range on the calendar by clicking the button below</p>
                                            <div class="d-flex justify-end">
                                                <v-btn
                                                color="#023E7D"
                                                elevation="0"
                                                dark
                                                :loading="dateRangeLoader"
                                                @click="BlockDate('range')"
                                                >
                                                Save Range
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            </v-expansion-panels>
                            <div class="mt-4 d-flex justify-space-between">
                                <v-btn
                                color="#023E7D"
                                dark
                                text
                                @click="e1 = 1"
                                >
                                <v-icon
                                    left
                                    dark
                                >
                                    mdi-arrow-left
                                </v-icon>
                                Edit default schedule
                                </v-btn>
                                <v-btn
                                color="#023E7D"
                                text
                                dark
                                @click="e1 = 3"
                                >
                                Want to block out time?
                                </v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <span>Easily reserve your time by selecting specific dates or date ranges. Maintain full control over your schedule, whether you need to allocate personal time, manage work hours, or avoid conflicting commitments. Keep your availability in check with just a few clicks.</span>
                            <v-expansion-panels accordion flat>
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header expand-icon="mdi-plus">
                                    <h4>Block date-time by selection</h4>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                    <v-col lg="6">
                                        <v-date-picker
                                            color="#023E7D"
                                            v-model="dateTime"
                                            full-width
                                            multiple
                                            :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                        ></v-date-picker>
                                    </v-col>
                                    <v-col lg="6">
                                        <h4>Add time phase</h4>
                                        <span>Set time(s) for the selected date(s) on the calendar</span>
                                        <div class="py-2">
                                            <v-chip
                                            v-show="item.day === 'Random'"
                                            v-for="(item, i) in availableTime"
                                            :key="i"
                                            class="me-2 mb-2"
                                            close
                                            color="#caf0f8"
                                            text-color="#023E7D"
                                            @click:close="removeTime(availableTime, item)"
                                            >
                                            <b>{{item.from | resetTime}} - {{item.to | resetTime}}</b>
                                            </v-chip>
                                            <v-btn
                                            class="mb-2"
                                            @click="selectDay('Random')"
                                            icon
                                            >
                                            <v-icon
                                            >
                                            mdi-plus
                                            </v-icon>
                                            </v-btn>
                                        </div>
                                        <div class="d-flex justify-end">
                                            <v-btn
                                            color="#023E7D"
                                            elevation="0"
                                            dark
                                            :loading="timeRandomLoader"
                                            @click="BlockTime('random')"
                                            >
                                            Save selection
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header expand-icon="mdi-plus">
                                    <h4>Block date-time by range</h4>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                <v-row>
                                    <v-col lg="6">
                                        <v-date-picker
                                            color="#023E7D"
                                            v-model="dateTimeRange"
                                            full-width
                                            range
                                            :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                        ></v-date-picker>
                                    </v-col>
                                    <v-col cols="6">
                                        <h4>Add time phase</h4>
                                        <span>Set time(s) for the date range picked on the calendar</span>
                                        <div class="py-2">
                                            <v-chip
                                            v-show="item.day === 'Range'"
                                            v-for="(item, i) in availableTime"
                                            :key="i"
                                            class="me-2 mb-2"
                                            close
                                            color="#caf0f8"
                                            text-color="#023E7D"
                                            @click:close="removeTime(availableTime, item)"
                                            >
                                            <b>{{item.from | resetTime}} - {{item.to | resetTime}}</b>
                                            </v-chip>
                                            <v-btn
                                            class="mb-2"
                                            @click="selectDay('Range')"
                                            icon
                                            >
                                            <v-icon
                                            >
                                            mdi-plus
                                            </v-icon>
                                            </v-btn>
                                        </div>
                                        <div class="d-flex justify-end">
                                            <v-btn
                                            color="#023E7D"
                                            elevation="0"
                                            dark
                                            :loading="timeRangeLoader"
                                            @click="BlockTime('range')"
                                            >
                                            Save Range
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            </v-expansion-panels>
                            <div class="mt-4 text-start">
                                <v-btn
                                color="#023E7D"
                                dark
                                text
                                @click="e1 = 2"
                                >
                                <v-icon
                                    left
                                    dark
                                >
                                    mdi-arrow-left
                                </v-icon>
                                Edit Blocked Date
                                </v-btn>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <v-dialog
        v-model="addTimeDialog"
        max-width="400"
        >
        <v-card>
            <v-card-title>
            Add Time Range
            </v-card-title>
            <v-card-text class="mt-4">
                <v-form
                ref="form"
                >
                <v-row>
                    <v-col lg="12">
                        <div class="d-flex justify-space-between align-center">
                            <div>
                                <span v-show="time4 != null" class="mini_title">From</span>
                                <div class="time_data">{{ time4 === null ? "From" : `${time4}` }}</div>
                            </div>
                            <v-btn
                            icon
                            color="gray"
                            @click="menuTime4 = true"
                            >
                            <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                        <v-dialog
                        v-model="menuTime4"
                        max-width="290"
                        >
                        <v-card>
                            <v-card-title>
                            </v-card-title>
                            <v-card-text
                            class="d-flex justify-start align-center"
                            >
                                <flat-pickr
                                v-model="time4"
                                :config="config4"
                                ref="flatpickr4"
                                class="form-control4"
                                placeholder="From"
                                name="date4"
                                />
                                <!-- <v-btn
                                icon
                                class="ms-2"
                                color="#023E7D"
                                @click="menuTime4 = false"
                                >
                                <v-icon>mdi-check</v-icon>
                                </v-btn> -->
                            </v-card-text>
                        </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col lg="12">
                        <div class="d-flex justify-space-between align-center">
                            <div>
                                <span v-show="time5 != null" class="mini_title">To</span>
                                <div class="time_data">{{ time5 === null ? "To" : `${time5}` }}</div>
                            </div>
                            <v-btn
                            icon
                            color="gray"
                            @click="menuTime5 = true"
                            >
                            <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                        <v-dialog
                        v-model="menuTime5"
                        max-width="290"
                        >
                        <v-card>
                            <v-card-title>
                            </v-card-title>
                            <v-card-text
                            class="d-flex justify-start align-center"
                            >
                                <flat-pickr
                                v-model="time5"
                                :config="config5"
                                ref="flatpickr5"
                                class="form-control5"
                                placeholder="To"
                                name="date5"
                                />
                                <!-- <v-btn
                                icon
                                class="ms-2"
                                color="#023E7D"
                                @click="menuTime5 = false"
                                >
                                <v-icon>mdi-check</v-icon>
                                </v-btn> -->
                            </v-card-text>
                        </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="setTime"
            >
                Add Selection
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- snackbar -->
        <v-snackbar
        v-model="snackbar"
        :color="bgColor"
        top
        right
        >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment'

  export default {

    components: {
        FlatPickr,
    },

    created: function () {
        this.getAvailability()
        // console.log(this.availableTime)
    },

    data () {
      return {
        date: null,
        setCalenDialog: false,
        checkbox: false,
        loading: false,
        snackbar: false,
        addTimeDialog: false,
        e1: 1,
        message: '',
        bgColor: '',
        dates: [],
        dateRange: [],
        dateTime: [],
        dateTimeRange: [],
        date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu1: false,
        date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu2: false,
        date3: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu3: false,
        date4: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu4: false,
        date5: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu5: false,
        time4: null,
        menuTime4: false,
        time5: null,
        menuTime5: false,
        selectedDay: '',
        weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
        Random: [],
        Range: [],
        availableTime: [],
        pickedDay: '',
        duration: '',
        durations: [30, 45, 60],
        availableTimeCount: 0,
        config4: {
            wrap: true,
            enableTime: true,
            noCalendar: true,
            dateFormat: "h:i K",
            defaultDate: null,
            minuteIncrement: "1",
            // time_24hr: true
        },
        config5: {
            wrap: true,
            enableTime: true,
            noCalendar: true,
            dateFormat: "h:i K",
            defaultDate: null,
            minuteIncrement: "1",
            // time_24hr: true
        },
        setCalendarLoader: false,
        dateRandomLoader: false,
        dateRangeLoader: false,
        timeRandomLoader: false,
        timeRangeLoader: false,
      }
    },

    methods: {

        selectDay(data) {
            this.pickedDay = data
            this.addTimeDialog = true
        },

        setTime() {
            if (!Array.isArray(this.availableTime)) {
                this.availableTime = [];
            }
            
            this.availableTime.push({
                day: this.pickedDay,
                from: moment(this.time4, ["h:mm A"]).format("HH:mm"),
                to: moment(this.time5, ["h:mm A"]).format("HH:mm")
            })
            console.log(this.availableTime)
            this.addTimeDialog = false
            this.$refs.form.reset()
        },

        getAvailability() {
            this.$store.dispatch('getAvailability')
            .then((response) => {
                this.availableTime = response.data.data
                this.availableTimeCount = response.data.count
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        removeTime(availableTime, item) {
            console.log(item);
            console.log(availableTime);
            var index = availableTime.indexOf(item);
            if (index > -1) {
                availableTime.splice(index, 1);
            }
            return availableTime;
        },

        setCalendar() {
            for (let i = 0; i < this.availableTime.length; i++) {
                if(this.availableTime[i].day == 'Monday') {
                    this.Monday.push({
                        from: this.availableTime[i].from,
                        to: this.availableTime[i].to
                    })
                }
                if(this.availableTime[i].day == 'Tuesday') {
                    this.Tuesday.push({
                        from: this.availableTime[i].from,
                        to: this.availableTime[i].to
                    })
                }
                if(this.availableTime[i].day == 'Wednesday') {
                    this.Wednesday.push({
                        from: this.availableTime[i].from,
                        to: this.availableTime[i].to
                    })
                } 
                if(this.availableTime[i].day == 'Thursday') {
                    this.Thursday.push({
                        from: this.availableTime[i].from,
                        to: this.availableTime[i].to
                    })
                }
                if(this.availableTime[i].day == 'Friday') {
                    this.Friday.push({
                        from: this.availableTime[i].from,
                        to: this.availableTime[i].to
                    })
                }
                if(this.availableTime[i].day == 'Saturday') {
                    this.Saturday.push({
                        from: this.availableTime[i].from,
                        to: this.availableTime[i].to
                    })
                }
                if(this.availableTime[i].day == 'Sunday') {
                    this.Sunday.push({
                        from: this.availableTime[i].from,
                        to: this.availableTime[i].to
                    })
                }
                
            }

            this.setCalendarLoader = true
            this.$store.dispatch('setCalendar', {
            consultation_duration: this.duration,
            Monday: this.Monday,
            Tuesday: this.Tuesday,
            Wednesday: this.Wednesday,
            Thursday: this.Thursday,
            Friday: this.Friday,
            Saturday: this.Saturday,
            Sunday: this.Sunday
            })
            .then((response) => {
                this.setCalendarLoader = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.e1 = 2
            })
            .catch((error) => {
                this.setCalendarLoader = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        BlockDate(v) {
            if(v == 'random') {
                let finalDate = this.dates.map(date => moment(date, "YYYY-MM-DD").format("MM-DD-YYYY"));
                this.dateRandomLoader = true
                this.$store.dispatch('BlockDate', {
                random_dates: finalDate,
                })
                .then((response) => {
                    this.dateRandomLoader = false
                    this.snackbar = true
                    this.message = response.data.message
                    this.bgColor = 'success'
                })
                .catch((error) => {
                    this.dateRandomLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            } else {
                let finalDate = this.dateRange.map(date => moment(date, "YYYY-MM-DD").format("MM-DD-YYYY"));
                this.dateRangeLoader = true
                this.$store.dispatch('BlockDate', {
                    date_range: [
                        {start_date: finalDate[0], end_date: finalDate[1]}
                    ]
                })
                .then((response) => {
                    this.dateRangeLoader = false
                    this.snackbar = true
                    this.message = response.data.message
                    this.bgColor = 'success'
                })
                .catch((error) => {
                    this.dateRangeLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            }
        },

        BlockTime(v) {
            for (let i = 0; i < this.availableTime.length; i++) {
                if(this.availableTime[i].day == 'Random') {
                    this.Random.push({
                        from: this.availableTime[i].from,
                        to: this.availableTime[i].to
                    })
                }
                if(this.availableTime[i].day == 'Range') {
                    this.Range.push({
                        from: this.availableTime[i].from,
                        to: this.availableTime[i].to
                    })
                }
            }

            if(v == 'random') {
                let finalDate = this.dateTime.map(date => moment(date, "YYYY-MM-DD").format("MM-DD-YYYY"));
                this.timeRandomLoader = true
                this.$store.dispatch('BlockDateTime', {
                random_date_time: {
                    date_range: finalDate,
                    period: this.Random,
                }
                })
                .then((response) => {
                    this.timeRandomLoader = false
                    this.snackbar = true
                    this.message = response.data.message
                    this.bgColor = 'success'
                })
                .catch((error) => {
                    this.timeRandomLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            } else {
                let finalDate = this.dateTimeRange.map(date => moment(date, "YYYY-MM-DD").format("MM-DD-YYYY"));
                this.timeRangeLoader = true
                this.$store.dispatch('BlockDateTime', {
                date_time_range: {
                    date_range: [
                        {start_date: finalDate[0], end_date: finalDate[1]}
                    ],
                    period: this.Range,
                }
                })
                .then((response) => {
                    this.timeRangeLoader = false
                    this.snackbar = true
                    this.message = response.data.message
                    this.bgColor = 'success'
                })
                .catch((error) => {
                    this.timeRangeLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            }
        },
    },

    computed: {
      
    }

  }
</script>

<style scoped>
.some-style >>> .v-input__slot::before {
    border-style: none !important;
}
.form-control4, .form-control5 {
    font-size: 20px;
    padding: 20px 10px;
    width: 100%;
    background-color: #F3F3F3;
}
.mini_title {
    font-size: 12px;
}
.time_data {
    font-size: 20px;
}

</style>