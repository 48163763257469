<template>
    <div></div>
</template>

<script>
    export default {
        created() {
            this.$store.dispatch('destroyToken')
            // this.$store.dispatch('clearDetails')
            setTimeout(() => {
                this.$router.push('/')
            }, 2000);
        }
    }
</script>