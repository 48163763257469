<template>
    <div>
        <v-card 
        class="pa-2 mt-6"
        flat
        color="#F5F5F5"
        >
            <v-card-title>
                <router-link to="/attorney/cases" class="body_llink">
                    Recent Case
                    <v-icon
                        right
                        small
                        color="#023E7D"
                    >
                        mdi-chevron-right
                    </v-icon>
                </router-link>
            </v-card-title>
            <div v-if="bothCases.length > 0">
                <v-card-text 
                v-for="(item, i) in bothCases.slice(0,3)"
                :key="i"
                class="py-1 d-flex justify-start"
                >
                    <v-avatar 
                    color="#E0E0E0"
                    rounded
                    class="me-4"
                    >
                    <v-icon color="#023E7D">
                        mdi-calendar-blank-outline
                    </v-icon>
                    </v-avatar>
                    <div>
                        <h4 class="card_lheading text-capitalize">{{item.case_name}}</h4>
                        <span class="card_lsubheading">
                            <span v-show="item.status == 'matched'">{{item.match_requests.length}} Matched</span>
                            <span class="text-capitalize" v-show="item.status == 'retained'">{{item.status}}</span>
                        </span>
                    </div>
                </v-card-text>
            </div>
            <div v-else>
                <v-card-text class="py-0">
                    <v-icon size="30" color="#D4D4D4">
                        mdi-briefcase
                    </v-icon>
                    <p class="placeholder_text">There's no recent case available, to create one click the recent case button above.</p>
                </v-card-text>
            </div>
        </v-card>
    </div>
</template>

<script>
  export default {

    components: {

    },

    data () {
      return {
        
      }
    },

    created: function () {
      this.$store.dispatch('getAttorneyCases')
    },

    methods: {
        
    },

    computed: {
        bothCases() {
            return this.$store.state.bothCases;
        },
    }

  }
</script>

<style scoped>
    .card_lheading {
        color: #023E7D;
    }

    .card_lsubheading {
        color: #585F6C;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }

    .card_dsubheading {
        color: #FFFFFF;
        font-size: 12px;
    }

    .body_llink {
        color: #023E7D;
        font-size: 14px;
        text-decoration: none;
    }

    .body_dlink {
        color: #FFFFFF;
        font-size: 14px;
        text-decoration: none;
    }

    .placeholder_text {
        color: #A7A7A7;
        font-size: 12px;
    }
</style>