<template>
  <!-- <hello-world /> -->
  <div class="px-2">
    <v-app-bar
      color="white"
      light
      flat
    >

      <div>
        <v-img class="logo_img"
          src="../assets/legallync-logo-dark.png"
        ></v-img>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-container class="auth_container">
        
        <v-row class="auth_row">
            <v-col cols="12" lg="2"></v-col>
            <v-col cols="12" lg="8">
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="auth_form"
                >
                    <v-row>
                        <v-col cols="12">
                            <div>
                                
                                <v-alert
                                border="left"
                                colored-border
                                color="#023E7D"
                                elevation="0"
                                dense
                                >
                                <h2 class="auth_title">Join Our Waitlist</h2>
                                Legal Lync will be live soon please fill the information below to join our waitlist. Early users will enjoy benefits.
                                </v-alert>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <p class="font-weight-medium">What category do you belong to?</p>
                            <v-select
                                v-model="category"
                                :items="categories"
                                label="Register As"
                                filled
                                dense
                                class="some-style"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="text-capitalize">{{ item }}</span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" lg="6" class="d-flex align-end">
                            <v-text-field
                            v-model="email"
                            label="Email"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" v-show="category === 'future-attorney'">
                            <p class="font-weight-medium">What is your name?</p>
                            <v-text-field
                            v-model="firstName"
                            label="First Name"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" v-show="category === 'future-attorney'">
                            <p class="font-weight-medium">&nbsp;</p>
                            <v-text-field
                            v-model="lastName"
                            label="Last Name"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" v-show="category === 'future-attorney'">
                            <p class="font-weight-medium">What date is your Bar Exam?</p>
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFormatted"
                                    label="MM/DD/YYYY"
                                    append-icon="mdi-calendar-blank-outline"
                                    filled
                                    dense
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    class="some-style"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="date"
                                no-title
                                scrollable
                                :min="new Date().toISOString().substr(0, 10)"
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" lg="6" v-show="category === 'future-attorney'">
                            <p class="font-weight-medium">What school did you attend?</p>
                            <v-text-field
                            v-model="school"
                            label="School Attended"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="12">
                            <v-btn
                                color="#023E7D"
                                :loading="loading"
                                :disabled="loading"
                                large
                                block
                                class="mt-5 reg_btn white--text"
                                elevation="0"
                                @click="createUser"
                            >
                                Join Waitlist
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <v-col lg="2"></v-col>
        </v-row>
    </v-container>

    <!-- waitlist success notifier -->
    <v-dialog
      v-model="waitlistDialog"
      persistent
      max-width="800"
    >
      <v-card
      color="#023E7D"
      >
        <v-card-text class="pa-6 text-center">
            <h3 class="card_title">You're in!</h3>
            <h3 class="card_title">Welcome to Our Waitlist.</h3>
            <p class="card_subtitle">Congratulations! You're now officially part of our exclusive waitlist for the launch of LegalLync! We're thrilled to have you on board and can't wait to bring our innovative platform to you.</p>
            <a href="https://legallync.com/">
                <v-btn
                    color="white"
                    outlined
                    large
                    class="pl-2 mt-5"
                    elevation="0"
                >
                <v-icon
                    right
                    class="me-2"
                >
                    mdi-arrow-left
                </v-icon>
                    Back to site
                </v-btn>
            </a>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :color="bgColor"
      bottom
      center
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
              Close
          </v-btn>
      </template>
    </v-snackbar>
    
  </div>
</template>

<script>

  export default {

    created: function () {

    },

    components: {
    },
    data: vm => ({
        menu: false,
        valid: false,
        loading: false,
        waitlistDialog: false,
        category: 'future-attorney',
        firstName: '',
        lastName: '',
        email: '',
        school: '',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        categories: ['future-attorney', 'attorney', 'client'],

        snackbar: false,
        message: '',
        bgColor: '',
    }),

    watch: {
      date () {
        this.dateFormatted = this.formatDate(this.date)
      },
    },

    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },

        createUser() {
            console.log(this.date)
            console.log(this.dateFormatted)
            this.loading = true
            this.$store.dispatch('addUserToWaitlist', {
            role: this.category,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            school: this.school,
            dob: new Date(this.date),
            })
            .then(() => {
            this.loading = false
            this.snackbar = true
            this.waitlistDialog = true
            this.message = 'You have been successfully added to our waitlist'
            this.bgColor = 'success'
            })
            .catch((error) => {
            this.loading = false
            this.snackbar = true
            this.message = error.response.data.message
            this.bgColor = 'error'
            })
        },

    },

    computed: {

    }

  }
</script>

<style scoped>
    .card_note {
        border: 1px solid #FFFFFF;
        border-radius: 3px;
    }
    .card_title {
        font-size: 35px;
        font-weight: 900;
        margin-top: 20px;
        margin-bottom: 20px;
        color: #FFFFFF;
    }
    .card_subtitle {
        font-size: 16px;
        color: #FFFFFF;
    }
    .card_text {
        font-size: 13px;
        color: #FFFFFF;
    }
    .logo_img {
        width: 160px;
    }

    .auth_container {
        margin-top: 5vh;
    }

    .auth_title {
        color: #023E7D;
    }

    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }

    .auth_subtitle {
        color: #585F6C;
    }

    .auth_row {
        margin-top: 40px;
    }

    .check_container {
        border: solid 1px #023E7D;
        border-radius: 3px;
        background-color: #F6FAFF;
        padding: 5px 10px;
        margin-right: 20px;
        width: 150px;
        height: 52px;
    }

    .check_text {
        color: #023E7D;
    }

    .reg_btn {
        margin-bottom: 40px;
    }

    .avatar_tag {
        cursor: pointer; 
    }

</style>