<template>
    <!-- <hello-world /> -->
    <div class="px-2">
      <v-app-bar
        color="white"
        light
        flat
      >
  
        <div>
          <v-img class="logo_img"
            src="../assets/legallync-logo-dark.png"
          ></v-img>
        </div>
  
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-container class="auth_container">
          
          <v-row class="auth_row">
              <v-col cols="12" lg="2"></v-col>
              <v-col cols="12" lg="8">
                  <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="auth_form"
                  >
                      <v-row>
                          <v-col cols="12">
                              <div>
                                  
                                  <v-alert
                                  border="left"
                                  colored-border
                                  color="#023E7D"
                                  elevation="0"
                                  dense
                                  >
                                  <h2 class="auth_title">Attorney Bar Information</h2>
                                  Elevate your legal game! Share your attorney bar information in the form below. Let's build a network of legal excellence!.
                                  </v-alert>
                              </div>
                          </v-col>
                          <v-col cols="12" lg="6">
                                <v-text-field
                                v-model="name"
                                label="Fullname"
                                filled
                                dense
                                class="some-style"
                                ></v-text-field>
                            </v-col>
                          <v-col cols="12" lg="6">
                              <v-select
                                  v-model="status"
                                  :items="categories"
                                  label="Register As"
                                  filled
                                  dense
                                  class="some-style"
                              >
                                  <template v-slot:selection="{ item }">
                                      <span class="text-capitalize">{{ item }}</span>
                                  </template>
                              </v-select>
                          </v-col>
                          <v-col cols="12" lg="6" class="d-flex align-end">
                              <v-text-field
                              v-model="email"
                              label="Email"
                              filled
                              dense
                              class="some-style"
                              ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                              <v-text-field
                              v-model="barId"
                              label="Bar Number"
                              filled
                              dense
                              class="some-style"
                              ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="12">
                            <v-btn
                                color="#023E7D"
                                :loading="loading"
                                :disabled="loading"
                                large
                                block
                                class="mt-5 reg_btn white--text"
                                elevation="0"
                                @click="createUser"
                            >
                                Add Details
                            </v-btn>
                        </v-col>
                        <!-- <v-col cols="12" lg="12">
                            <v-btn
                                color="#023E7D"
                                text
                                block
                                class="mt-5"
                                @click="createUser"
                            >
                                View Attorneys
                            </v-btn>
                        </v-col> -->
                      </v-row>
                  </v-form>
              </v-col>
              <v-col lg="2"></v-col>
          </v-row>
      </v-container>
  
      <!-- waitlist success notifier -->
      <v-dialog
        v-model="waitlistDialog"
        persistent
        max-width="800"
      >
        <v-card
        color="#023E7D"
        >
          <v-card-text class="pa-6 text-center">
              <h3 class="card_title">You're in!</h3>
              <h3 class="card_title">Welcome to Our Waitlist.</h3>
              <p class="card_subtitle">Congratulations! You're now officially part of our exclusive waitlist for the launch of LegalLync! We're thrilled to have you on board and can't wait to bring our innovative platform to you.</p>
              <a href="https://legallync.com/">
                  <v-btn
                      color="white"
                      outlined
                      large
                      class="pl-2 mt-5"
                      elevation="0"
                  >
                  <v-icon
                      right
                      class="me-2"
                  >
                      mdi-arrow-left
                  </v-icon>
                      Back to site
                  </v-btn>
              </a>
          </v-card-text>
        </v-card>
      </v-dialog>
  
      <!-- snackbar -->
      <v-snackbar
        v-model="snackbar"
        :color="bgColor"
        bottom
        center
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
      </v-snackbar>
      
    </div>
</template>
  
<script>
  
    export default {
  
      created: function () {
  
      },
  
      components: {
      },
      data () {
      return {
          menu: false,
          valid: false,
          loading: false,
          waitlistDialog: false,
          barId: '',
          status: 'active',
          name: '',
          email: '',
          categories: ['active', 'inactive'],
          snackbar: false,
          message: '',
          bgColor: '',
      }
    },
  
      methods: {
  
          createUser() {
              this.loading = true
              this.$store.dispatch('addAttorneyBarInfo', {
                status: this.status,
                bar_id: this.barId,
                name: this.name,
                email: this.email,
              })
              .then(() => {
              this.loading = false
              this.snackbar = true
            //   this.waitlistDialog = true
              this.message = 'Your bar details has been successfully added'
              this.bgColor = 'success'
              })
              .catch((error) => {
              this.loading = false
              this.snackbar = true
              this.message = error.response.data.message
              this.bgColor = 'error'
              })
          },
  
      },
  
      computed: {
  
      }
  
    }
  </script>
  
  <style scoped>
      .card_note {
          border: 1px solid #FFFFFF;
          border-radius: 3px;
      }
      .card_title {
          font-size: 35px;
          font-weight: 900;
          margin-top: 20px;
          margin-bottom: 20px;
          color: #FFFFFF;
      }
      .card_subtitle {
          font-size: 16px;
          color: #FFFFFF;
      }
      .card_text {
          font-size: 13px;
          color: #FFFFFF;
      }
      .logo_img {
          width: 160px;
      }
  
      .auth_container {
          margin-top: 5vh;
      }
  
      .auth_title {
          color: #023E7D;
      }
  
      .some-style >>> .v-input__slot::before {
          border-style: none !important;
      }
  
      .auth_subtitle {
          color: #585F6C;
      }
  
      .auth_row {
          margin-top: 40px;
      }
  
      .check_container {
          border: solid 1px #023E7D;
          border-radius: 3px;
          background-color: #F6FAFF;
          padding: 5px 10px;
          margin-right: 20px;
          width: 150px;
          height: 52px;
      }
  
      .check_text {
          color: #023E7D;
      }
  
      .reg_btn {
          margin-bottom: 40px;
      }
  
      .avatar_tag {
          cursor: pointer; 
      }
  
  </style>