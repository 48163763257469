<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12" md="8" lg="8">
                    <h3 class="topic">Appointment</h3>
                    
                    
                    <!-- <h4 class="topic mb-3">Recent Appointment</h4> -->
                    
                    <v-row class="my-3">
                        <v-col cols="12">
                            <Calendar />
                        </v-col>

                        <!-- <v-col cols="12">
                            <div
                            v-for="(item, i) in attorneyAppointments"
                            :key="i"
                            class="mb-2"
                            >
                                <div class="client_card d-flex justify-space-between">
                                    <div class="client_card-box d-flex justify-start">
                                        <v-avatar size="34" color="#DCE8F8" class="me-4">
                                            <v-icon color="#C3D6EF" dark>
                                            mdi-calendar-check
                                            </v-icon>
                                        </v-avatar>
                                        <div class="pt-1">
                                            <span class="font-weight-medium text-capitalize">{{item.first_name}} {{item.last_name}}</span>
                                            <h5 class="subtitle font-weight-medium">Appointment at - <span class="figure">{{currentDate + item.from | formatTime}} {{item.date | formatDate}}</span></h5>
                                            <v-btn
                                            small
                                            dark
                                            color="#023E7D"
                                            class="mt-2"
                                            elevation="0"
                                            @click="initialMeeting(item)"
                                            >
                                            Start Meeting
                                            <v-icon
                                                right
                                                small
                                                dark
                                            >
                                                mdi-open-in-new
                                            </v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div class="client_card-box d-flex align-center">
                                        <v-btn
                                        icon
                                        color="#023E7D"
                                        >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-col> -->
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="mt-7 right_sidebar">
                    <div class="mb-4 text-end">
                        <SetCalendar />
                    </div>
                    <v-card
                    color="#023E7D"
                    flat
                    >
                        <v-card-text class="d-flex justify-start">
                            <v-avatar 
                            color="#FFFDF1"
                            rounded
                            class="me-4"
                            >
                            <v-icon color="#023E7D">
                                mdi-calendar-month-outline
                            </v-icon>
                            </v-avatar>
                            <div>
                                <h4 class="card_lheading">{{attorneyAppointments.length}}</h4>
                                <span class="card_lsubheading">Appointments</span>
                            </div>
                        </v-card-text>
                    </v-card>
                    <TranCard />
                </v-col>
                
            </v-row>
        </v-container>
    </div>
</template>

<script>
import TranCard from '../../components/attorney/TransactionCardAttorney.vue'
import SetCalendar from '../../components/attorney/SetCalendar.vue'
import Sidebar from '../../components/attorney/SidebarAttorney.vue'
import Navbar from '../../components/attorney/NavbarAttorney.vue'
import Calendar from '../../components/attorney/CalendarEvent.vue'

  export default {

    components: {
        TranCard, 
        SetCalendar, Sidebar, Navbar, Calendar
    },

    created: function () {
        this.$store.dispatch('getAttorneyAppointments')
    },

    data () {
      return {
          
      }
    },

    methods: {
        initialMeeting(item) {
            let chat_object = item.chat_object[0]
            this.$router.push({
                name: 'AttorneyMeet',
                params: {
                displayName: chat_object.display_name,
                roomName: chat_object.room_name,
                email: chat_object.email,
                password: chat_object.password,
                }
            });
        }
    },

    computed: {
        attorneyAppointments() {
            return this.$store.state.attorneyAppointments;
        },

        currentDate() {
            var date = (new Date(Date.now())).toISOString()
            var newDate = `${date.split("T")[0]}T`
            return newDate
        },
    }

  }
</script>

<style scoped>
    .topic {
        color: #023E7D;
    }

    .subtopic {
        color: #7E889A;
    }

    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }

    .card_figure-text {
        color: #023E7D;
    }
    .client_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }
    .subtitle {
        color: #023047;
    }
    .figure {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }
    .right_sidebar {
        border-left: 1px solid #e9ecef;
    }
    .card_lheading {
        font-size: 25px;
        color: #FFF;
    }
    .card_lsubheading {
        font-size: 12px;
        color: #FFF;
    }
</style>