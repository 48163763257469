<template>
    <!-- <hello-world /> -->
    <div class="px-2 main">
      <v-app-bar
        color="#B40101"
        flat
      >
  
        <div>
          <v-img class="logo_img"
            src="../assets/legallync-logo-light.png"
          ></v-img>
        </div>
  
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-container class="auth_container">
        <v-row>
          <v-col cols="12" lg="8">
            <p class="status_heading">Payment Canceled! Something occurred please try again.</p>
          </v-col>
          <v-col cols="12" lg="4" class="d-flex align-center justify-center">
            <v-icon
            color="white"
            size="200"
            >
            mdi-close-octagon
            </v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="8">
            <p class="status_subheading">Us female our own years Whales upon upon brought can't above isn't two that darkness appear seed shall over you're first. Was shall god.</p>
            <router-link to="/"><span class="status_link">Sign In To Try Again</span></router-link>
          </v-col>
          <v-col cols="12" lg="4"></v-col>
        </v-row>
      </v-container>
    </div>
</template>
  
<script>

export default {

    components: {

    },

    data () {
    return {

    }
    },

    methods: {

    },

}
</script>
  
<style scoped>

    .main {
        background-color: #B40101;
        height: 100vh;
    }

    .auth_container {
        padding-top: 20vh;
    }

    .logo_img {
        width: 160px;
    }

    .status_heading {
        font-size: 4.5rem;
        font-weight: 900;
        color: #FFF;
        line-height: 5rem;
    }

    .status_subheading {
        font-size: 1.2rem;
        font-weight: 600;
        color: #FFF;
    }

    .status_link {
        font-size: 1.2rem;
        font-weight: 600;
        color: #FFF;
        text-decoration-line: underline;
        text-decoration-color: #FFF;
    }

    @media (max-width: 768px) {

    }

</style>