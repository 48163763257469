<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12" md="8" lg="8">
                    <h3 class="topic text-capitalize">Hello! {{userData.last_name}}</h3>
                    <p class="subtopic">Let’s find you an attorney</p>
                    <v-row class="my-4">
                        <v-col cols="12" md="4" lg="4">
                            <v-card
                            color="#FFF7CC"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{clientAppointments.length}}</h2>
                                    <span class="card_figure-text text-caption">Appointments</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                            <v-card
                            color="#B7EBF1"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{matchedCount}}</h2>
                                    <span class="card_figure-text text-caption">Matched Attorneys</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                            <v-card
                            color="#FAEDD9"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{openCount}}</h2>
                                    <span class="card_figure-text text-caption">Prospective  Attorneys</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <h4 class="topic">Attorneys</h4>
                    <div class="my-3">
                        <v-text-field
                            filled
                            label="Find an attorney"
                            dense
                            append-icon="mdi-magnify"
                            class="some-style"
                        ></v-text-field>
                    </div>
                    <v-chip
                        color="#023E7D"
                        dark
                        class="mr-2"
                    >
                        All
                    </v-chip>
                    <v-chip
                        class="mr-2"
                        color="#DCE8F8"
                    >
                        Civil
                    </v-chip>
                    <v-chip
                        class="mr-2"
                        color="#DCE8F8"
                    >
                        Criminal
                    </v-chip>
                    <v-chip
                        class="mr-2"
                        color="#DCE8F8"
                    >
                        Transactional
                    </v-chip>
                    <v-row class="my-1">
                        <v-col cols="12">
                        <v-responsive
                        class="overflow-y-auto"
                        max-height="400"
                        >

                            <v-responsive>
                            <div
                            v-if="attorneysLoader === false"
                            >
                            <v-list
                            v-for="(item, i) in attorneys"
                            :key="i"
                            >
                                <v-list-item class="px-0" @click="attProfile(item)">
                                    <v-list-item-content class="py-0">
                                        <div class="attorney_card d-flex justify-space-between">
                                            <div class="attorney_card-box d-flex align-center">
                                                <v-avatar size="34" class="me-4">
                                                    <img
                                                        :src="imgPlaceholder"
                                                        alt="profile image"
                                                    >
                                                </v-avatar>
                                                <span>{{item.user_id.last_name}} {{item.user_id.first_name}}</span>
                                            </div>
                                            <div class="attorney_card-box d-flex align-center">
                                                <span class="d-none d-sm-none d-md-flex">{{item.attorney_type[0]}}</span>
                                                <span class="ms-1 me-4" v-show="item.attorney_type.length > 1">& More</span>
                                                <span class="ms-4 me-4 d-none d-sm-none d-md-flex">{{item.user_id.state}}</span>
                                            </div>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            </div>
                            <div v-else>
                                <v-skeleton-loader
                                type="list-item-avatar-two-line"
                                ></v-skeleton-loader>
                            </div>
                            </v-responsive>
                            </v-responsive>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="right_sidebar">
                    <ApptCard />
                    <ProgCard />
                    <CaseCard />
                </v-col>
                
            </v-row>
        </v-container>

        <v-dialog
        v-model="attProDialog"
        max-width="550"
        >
            <v-card flat>
                <v-img
                    :aspect-ratio="16/5"
                    :src="imgPlaceholder"
                >
                </v-img>

                <v-card-text v-show="attorneyProfile != {}">
                    <div class="d-flex justify-start">
                        <h4 class="mt-6 mb-2 me-2 profile_title">{{attorneyProfile?.user_id?.first_name}} {{attorneyProfile?.user_id?.last_name}}</h4>
                        <v-icon
                        v-show="attorneyProfile?.is_profile_verified"
                        color="#023E7D"
                        >
                        mdi-check-decagram
                        </v-icon>
                    </div>
                    <span 
                    v-for="(item, i) in attorneyProfile?.attorney_type"
                    :key="i"
                    class="profile_subtitle text-capitalize"
                    >
                    {{ item }}, 
                    </span>
                    <!-- <v-chip
                        class="mr-2 profile_pill"
                        color="#DCE8F8"
                    >
                        Defense
                    </v-chip> -->
                    <div class="mt-4 mb-8 d-flex justify-start align-center">
                        <div>
                            <v-icon class="me-2 profile_icon" color="#7E889A">
                                mdi-briefcase-check-outline
                            </v-icon>
                            <span class="me-3 profile_icon-text">0</span>
                        </div>
                        <div>
                            <v-icon class="me-2 profile_icon" color="#7E889A">
                                mdi-briefcase-clock-outline
                            </v-icon>
                            <span class="me-3 profile_icon-text">0</span>
                        </div>
                        <div>
                            <v-icon class="me-2 profile_icon" color="#7E889A">
                                mdi-briefcase-remove-outline
                            </v-icon>
                            <span class="me-3 profile_icon-text">0</span>
                        </div>
                        <div class="me-2 line"></div>
                        <div>
                            <v-icon class="me-2 profile_icon" color="#7E889A">
                                mdi-star-outline
                            </v-icon>
                            <span class="me-3 profile_icon-text">0</span>
                        </div>
                    </div>
                    <v-row>
                        <v-col cols="6" lg="4">
                            <h5>Attorney Type</h5>
                            <div 
                            class="profile_text"
                            >
                            <span 
                            v-for="(item, i) in attorneyProfile?.attorney_type"
                            :key="i"
                            class="text-capitalize"
                            >
                            {{ item }}, 
                            </span>
                            </div>
                        </v-col>
                        <v-col cols="6" lg="4">
                            <h5>Language(s)</h5>
                            <div class="profile_text">
                                <span 
                                v-for="(item, i) in attorneyProfile?.user_id?.spoken_language"
                                :key="i"
                                class="text-capitalize"
                                >
                                {{ item }}, 
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="6" lg="4">
                            <h5>State Licensed</h5>
                            <div class="profile_text">
                                <span 
                                v-for="(item, i) in attorneyProfile?.licensed_areas"
                                :key="i"
                                >
                                {{item.state | formatState}}, 
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <h5>Practice Area</h5>
                            <div class="profile_text">
                                <span 
                                v-for="(item, i) in attorneyProfile?.practice_area"
                                :key="i"
                                >
                                {{item | formatState}}, 
                                </span>
                            </div>
                        </v-col>
                        <v-col lg="12">
                            <h5>About</h5>
                            <div 
                            v-if="'contact_info' in attorneyProfile"
                            class="profile_text"
                            >
                                {{ attorneyProfile?.contact_info.about }}
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- open case -->
        <v-dialog
        v-model="openCaseDialog"
        max-width="420"
        >
        <v-card class="py-4" color="#023E7D">
            <v-card-title class="mb-4">
                <v-avatar size="40" color="#DCE8F8">
                    <v-icon color="#023E7D" dark>
                        mdi-briefcase-outline
                    </v-icon>
                </v-avatar>
            </v-card-title>
            <v-card-text>
                <h3 class="dialog_title">Welcome! open a case.</h3>
                <p class="dialog_subtitle">Sed egestas, dui a accumsan sagittis, sapien mauris placerat  eu bi purus eros felis viverra nisi nec tincidunt ull amcorper. Donec ac blandit felis.</p>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="white"
                text
                @click="openCaseDialog = false"
            >
                Later
            </v-btn>
            <v-btn
                color="white"
                dark
                depressed
                route to="/client/cases"
                class="dialog_btn"
            >
                Open Now
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<script>
import ApptCard from '../../components/client/AppointmentCardClient.vue'
import ProgCard from '../../components/client/ProgressCardClient.vue'
import CaseCard from '../../components/client/CaseCardClient.vue'
import Sidebar from '../../components/client/SidebarClient.vue'
import Navbar from '../../components/client/NavbarClient.vue'

  export default {

    components: {
        ApptCard,
        ProgCard,
        CaseCard,
        Sidebar, 
        Navbar
    },

    data () {
      return {
        attProDialog: false,
        openCaseDialog: false,
        attorneysLoader: false,
        attorneys: [],
        attorneyProfile: {},
        imgPlaceholder: 'https://res.cloudinary.com/dzsshr0iz/image/upload/v1652961985/Ilerah/avatar-ph_pce6l3.jpg',
      }
    },

    created: function () {
      setTimeout(() => {
          this.openCaseDialog = true
      }, 5000);

      this.$store.dispatch('getClientCases')
      this.allAttorneys()
    },

    methods: {
        attProfile(item) {
            this.attorneyProfile  = item;
            this.attProDialog = true
        },

        randomize() {
            for (let i = this.attorneys.length - 1; i > 0; i--) {
                let randomIndex = Math.floor(Math.random() * i);
                let temp = this.attorneys[i];
                this.$set(this.attorneys, i, this.attorneys[randomIndex]);
                this.$set(this.attorneys, randomIndex, temp);
            }

        },

        async allAttorneys() {
            this.loading = true;
            this.attorneysLoader = true
            await this.$store.dispatch('attorneysAds')
            .then((response) => {
                this.attorneys = response.data.data
                this.attorneysLoader = false
                this.randomize()
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },
    },

    computed: {
        userData() {
            return this.$store.state.userData;
        },

        clientAppointments() {
            return this.$store.state.clientAppointments;
        },

        openCount() {
            return this.$store.state.openCount;
        },

        matchedCount() {
            return this.$store.state.matchedCount;
        },
    }

  }
</script>

<style scoped>
    .topic {
        color: #023E7D;
    }

    .subtopic {
        color: #7E889A;
    }

    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }

    .card_figure-text {
        color: #023E7D;
    }

    .attorney_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }

    .attorney_card-box {
        height: 34px;
    }

    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }

    .dialog_title {
        font-size: 25px;
        color: #FFF;
        margin-bottom: 20px;
    }

    .dialog_subtitle {
        color: #FFF;
    }

    .dialog_btn {
        color: #023E7D;
    }

    .profile_title {
        font-size: 25px;
        color: #023047;
    }

    .profile_pill {
        color: #023E7D;
        font-weight: 600;
    }

    .profile_icon {
        margin-top: -4px;
    }

    .profile_icon-text {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #023E7D;
    }

    .line {
        width: 1px;
        height: 25px;
        background-color: #C4C4C4;
    }

    .profile_text {
        color: #2F2E41;
    }
    .right_sidebar {
        border-left: 1px solid #e9ecef;
    }
</style>