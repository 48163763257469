import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import moment from 'moment';
import WebSocketPlugin from './WebSocketPlugin';


axios.defaults.baseURL= `${process.env.VUE_APP_BASE_URL}`;

Vue.config.productionTip = false

Vue.use(WebSocketPlugin, {
  websocketUrl: "wss://dev.legallync.com",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        path: '/loading',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM D, YYYY')
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('hh:mm A')
  }
});

Vue.filter('resetTime', function(value) {
  if (value) {
    return moment(value, ["HH:mm"]).format("h:mm A")
  }
});

Vue.filter('formatState', function(value) {
  if (value) {
    return value.replace(/\b[A-Z]{2}\b/g, '')
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
