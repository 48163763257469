<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12" md="8" lg="8">
                    <h3 class="topic">Appointment</h3>
                    <div class="text-end">
                        <CreateAppt />
                    </div>
                    <v-row class="mb-4 mt-2">
                        <v-col cols="12" md="6" lg="6">
                            <v-card
                            color="#FFF7CC"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{clientAppointments.length}}</h2>
                                    <span class="card_figure-text text-caption">Appointments</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" lg="6">
                            <v-card
                            color="#FAEDD9"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">0</h2>
                                    <span class="card_figure-text text-caption">Declined Appointment</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <h4 class="topic mb-3">Recent Appointment</h4>
                    
                    <v-row class="my-3">
                        <v-col cols="12">
                            <div v-if="clientAppointments.length > 0">
                                <div
                                v-for="(item, i) in clientAppointments"
                                :key="i"
                                class="mb-2"
                                >
                                    <div class="client_card d-flex justify-space-between">
                                        <div class="client_card-box d-flex justify-start align-center">
                                            <v-avatar size="34" color="#DCE8F8" class="me-4">
                                                <v-icon color="#C3D6EF" dark>
                                                mdi-calendar-check
                                                </v-icon>
                                            </v-avatar>
                                            <div class="pt-1">
                                                <span class="font-weight-medium text-capitalize">{{item.case_name}}</span>
                                                <h5 class="subtitle font-weight-medium">
                                                    <span>Appointment with <b class="text-capitalize">{{item.first_name}} {{item.last_name}}</b> - <span class="figure">{{currentDate + item.from | resetTime}} {{item.date | formatDate}}</span></span>
                                                    
                                                </h5>
                                                <div class="d-flex align-end">
                                                    <span class="meetingNote">Meeting has started</span>
                                                    <v-btn
                                                    small
                                                    dark
                                                    color="#023E7D"
                                                    class="mt-2 ms-2"
                                                    elevation="0"
                                                    @click="initialMeeting(item)"
                                                    > 
                                                    Join Meeting
                                                    <v-icon
                                                        right
                                                        small
                                                        dark
                                                    >
                                                        mdi-open-in-new
                                                    </v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="client_card-box d-flex align-center">
                                            <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                color="#023E7D"
                                                >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <!-- <v-list-item>
                                                <v-list-item-title>Edit</v-list-item-title>
                                                </v-list-item> -->
                                                <v-list-item @click="cancelItem(item)">
                                                <v-list-item-title>Cancel Appointment</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="text-center d-flex justify-center">
                                <v-card flat width="400">
                                    <v-icon
                                    size="100"
                                    color="#D4D4D4"
                                    >
                                    mdi-package-variant
                                    </v-icon>
                                    <p class="placeholder_text">Nothing to see here! You don't have any retained case, if you retain a case it will show up on this list.</p>
                                </v-card>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="mt-7 right_sidebar">
                    <ProgCard />
                    <CaseCard />
                </v-col>
                
            </v-row>
        </v-container>

        <!-- cancel appointment -->
        <v-dialog
        v-model="cancelDialog"
        persistent
        max-width="310"
        >
        <v-card>
            <v-card-title class="font-weight-regular">
            Do you want to cancel this appointment?
            </v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="#DCE8F8"
                light
                class="me-2 optionCloseBtn"
                elevation="0"
                @click="cancelDialog = false"
            >
                <b>No, i don’t</b>
            </v-btn>
            <v-btn
                color="#053669"
                dark
                elevation="0"
                :loading="loading"
                @click="cancelAppointment"
            >
                <b>Yes, i do</b>
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- snackbar -->
        <v-snackbar
        v-model="snackbar"
        :color="bgColor"
        top
        center
        >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import ProgCard from '../../components/client/ProgressCardClient.vue'
import CaseCard from '../../components/client/CaseCardClient.vue'
import CreateAppt from '../../components/client/NewAppointment.vue'
import Sidebar from '../../components/client/SidebarClient.vue'
import Navbar from '../../components/client/NavbarClient.vue'

  export default {

    components: {
        ProgCard,
        CaseCard,
        CreateAppt,
        Sidebar,
        Navbar
    },

    created: function () {
        this.$store.dispatch('getClientAppointments')
    },

    data () {
      return {
          cancelDialog: false,
          loading: false,
          selectedAppointment: {},
          snackbar: false,
          message: '',
          bgColor: ''
      }
    },

    methods: {
        cancelItem(item) {
            this.selectedAppointment = item
            this.cancelDialog = true
        },

        cancelAppointment() {
            this.loading = true;
            this.$store.dispatch('cancelAppointment', this.selectedAppointment._id)
            .then((response) => {
                this.loading = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.bookDialog = false
                this.$store.dispatch('getClientAppointments')
                this.cancelDialog = false
            })
            .catch((error) => {
                this.loading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        initialMeeting(item) {
            let chat_object = item.chat_object[0]
            this.$router.push({
                name: 'ClientMeet',
                params: {
                displayName: chat_object.display_name,
                roomName: chat_object.room_name,
                email: chat_object.email,
                password: chat_object.password,
                }
            });
        }

        
    },

    computed: {
        currentDate() {
            var date = (new Date(Date.now())).toISOString()
            var newDate = `${date.split("T")[0]}T`
            return newDate
        },

        clientAppointments() {
            return this.$store.state.clientAppointments;
        },
    }

  }
</script>

<style scoped>
    .topic {
        color: #023E7D;
    }

    .subtopic {
        color: #7E889A;
    }

    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }

    .card_figure-text {
        color: #023E7D;
    }

    .client_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }
    .subtitle {
        color: #023047;
    }
    .placeholder_text {
        color: #A7A7A7;
    }
    .figure {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }
    .meetingNote {
        font-size: 12px;
    }
    .right_sidebar {
        border-left: 1px solid #e9ecef;
    }
</style>