<template>
    <div>
        <vue-jitsi-meet
            ref="jitsiRef"
            admin
            domain="meet.jit.si"
            :options="jitsiOptions"
            style="width: 100%; height: 100vh"
        ></vue-jitsi-meet>

        <v-dialog
        v-model="dialogLoader"
        hide-overlay
        persistent
        width="300"
        >
        <v-card
            color="#023E7D"
            dark
        >
            <v-card-text>
            Loading, please wait...
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
            </v-card-text>
        </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import { JitsiMeet } from '@mycure/vue-jitsi-meet';
    export default {
        props: ['displayName', 'roomName', 'email', 'password'],
        
        created: function () {
            setTimeout(() => (this.meetDetails), 5000)
        },
    
        components: {
            VueJitsiMeet: JitsiMeet
        },

        data () {
            return {
                dialogLoader: false,
            }
        },
    
        watch: {
            dialogLoader (val) {
                if (!val) return

                setTimeout(() => (this.dialogLoader = false), 4000)
            },
        },
    
        methods: {
            onIFrameLoad () {
                // This will load the 'The display name' value using the `displayName` command.
                // this.$refs.jitsiRef.executeCommand('displayName', `${}`);
            },
        },
    
        computed: {
            jitsiOptions () {
                return {
                    roomName: `${this.meetDetails.roomName} attorney_client_meeting`,
                    noSSL: false,
                    userInfo: {
                    email: this.meetDetails.email,
                    // displayName: this.meetDetails.displayName,
                    displayName: 'Moderator',
                    },
                    configOverwrite: {
                    enableNoisyMicDetection: false,
                    defaultLanguage: 'en',
                    disableDeepLinking: true,
                    hideParticipantsStats: true,
                    },
                    interfaceConfigOverwrite: {
                        HIDE_INVITE_MORE_HEADER: true,
                        DEFAULT_LOGO_URL: 'https://res.cloudinary.com/dwkagby2h/image/upload/v1675628319/general/down-logo_mibovw.png',
                    SHOW_JITSI_WATERMARK: false,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    MOBILE_APP_PROMO: false,
                    LANG_DETECTION: true,
                    SETTINGS_SECTIONS: [ 'devices', 'moderator' ],
                    // TOOLBAR_BUTTONS: [
                    //     'microphone', 'camera', 'hangup', 'chat', 'videobackgroundblur',
                    // ],
                    },
                    // onload: this.onIFrameLoad

                    
                };
            },

            userData() {
                return this.$store.state.userData;
            },

            meetDetails() {
                return {
                    displayName: this.displayName,
                    roomName: this.roomName,
                    email: this.email,
                    password: this.password,
                }
            },



            
        }
  
    }
</script>

<style scoped>

</style>