<template>
    <div>Hi we are here</div>
</template>
   
<script>
import { eventBus } from '../../EventBus';

export default {
  created() {
    // Listen for the event emitted by the WebSocket plugin
    eventBus.$on("websocketMessage", (data) => {
      // Call your method with the received data
      this.methodToCall(data);
    });
  },
  methods: {
    methodToCall(data) {
        console.log(data);
      // Do something with the received data
    },
  },
};
</script>
   
<style scoped>
</style>