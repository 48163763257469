<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12" md="8" lg="8">
                    <h3 class="topic">Cases</h3>
                    <div class="text-end">
                        <CreateCase />
                    </div>
                    <v-row class="mb-4 mt-2">
                        <v-col cols="12" md="4" lg="4">
                            <v-card
                            color="#FFF7CC"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{openCount}}</h2>
                                    <span class="card_figure-text text-caption">Open Cases</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                            <v-card
                            color="#B7EBF1"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{matchedCount}}</h2>
                                    <span class="card_figure-text text-caption">Matched Cases</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                            <v-card
                            color="#FAEDD9"
                            flat
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{retainedCount}}</h2>
                                    <span class="card_figure-text text-caption">Retained  Cases</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <h4 class="topic mb-3">Recent Cases</h4>
                    <v-chip
                        dark
                        class="mr-2"
                        :color="tab === 0 ? '#023E7D' : '#DCE8F8'"
                        :text-color="tab === 0 ? 'white' : 'grey darken-2'"
                        @click="tab = 0"
                    >
                        All
                    </v-chip>
                    <v-chip
                        class="mr-2"
                        :color="tab === 1 ? '#023E7D' : '#DCE8F8'"
                        :text-color="tab === 1 ? 'white' : 'grey darken-2'"
                        @click="tab = 1"
                    >
                        Open
                    </v-chip>
                    <v-chip
                        class="mr-2"
                        :color="tab === 2 ? '#023E7D' : '#DCE8F8'"
                        :text-color="tab === 2 ? 'white' : 'grey darken-2'"
                        @click="tab = 2"
                    >
                        Matched
                    </v-chip>
                    <v-chip
                        class="mr-2"
                        :color="tab === 3 ? '#023E7D' : '#DCE8F8'"
                        :text-color="tab === 3 ? 'white' : 'grey darken-2'"
                        @click="tab = 3"
                    >
                        Retained
                    </v-chip>
                    <v-row class="mt-1 mb-3">
                        <v-col cols="12">
                            <v-responsive
                            class="overflow-y-auto"
                            max-height="400"
                            >
                                <v-responsive>
                                    <v-tabs-items v-model="tab">
                                        <v-tab-item>
                                            <div v-if="allCase.length > 0">
                                                <div class="py-0 my-1" cols="12" v-for="(item, i) in allCase" :key="i">
                                                    <div class="attorney_card d-flex justify-space-between">
                                                        <div class="attorney_card-box d-flex align-center me-auto">
                                                            <v-avatar size="34" color="#DCE8F8" class="me-4">
                                                                <v-icon color="#C3D6EF" dark>
                                                                mdi-briefcase
                                                                </v-icon>
                                                            </v-avatar>
                                                            <span>
                                                                <a class="case_link" @click="viewCase(item)"><span>{{item.case_name}}</span></a>
                                                                <div class="d-flex d-sm-flex d-md-none">
                                                                    <div v-show="item.status == 'retained'" class="subtext attorney_list-text">Retained</div>
                                                                    <div v-show="item.status == 'open'" class="subtext attorney_list-text"><span></span>{{item.potential_attorneys_id.length}} Prospective Attorney<span v-show="item.potential_attorneys_id.length > 1">s</span></div>
                                                                    <div v-show="item.status == 'matched'" class="subtext attorney_list-text">
                                                                        <!-- {{item.first_attorney}} + -->
                                                                        {{item.match_requests.length}} Attorney<span v-show="item.match_requests.length > 1">s</span> Matched</div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="d-none d-sm-none d-md-flex attorney_card-box d-flex align-center">
                                                            <span v-show="item.status == 'retained'" class="me-4 attorney_list-text">Retained</span>
                                                            <span v-show="item.status == 'closed'" class="me-4 attorney_list-text closed">Closed</span>
                                                            <span v-show="item.status == 'open'" class="me-4 attorney_list-text">{{item.potential_attorneys_id.length}} Prospective Attorney<span v-show="item.potential_attorneys_id.length > 1">s</span></span>
                                                            <span v-show="item.status == 'matched'" class="me-4 attorney_list-text">{{item.match_requests.length}} Attorney<span v-show="item.match_requests.length > 1">s</span> Matched</span>
                                                        </div>
                                                        <v-btn
                                                        v-show="item.status == 'open' || item.status == 'closed'"
                                                        icon
                                                        color="#023E7D"
                                                        @click="openCase(item)"
                                                        >
                                                        <v-icon>mdi-chevron-right</v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                        v-show="item.status == 'matched' || item.status == 'retained'"
                                                        icon
                                                        color="#023E7D"
                                                        @click="selectMatchRetain(item)"
                                                        >
                                                        <v-icon>mdi-chevron-right</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="text-center d-flex justify-center">
                                                <v-card flat width="400">
                                                    <v-icon
                                                    size="100"
                                                    color="#D4D4D4"
                                                    >
                                                    mdi-package-variant
                                                    </v-icon>
                                                    <p class="placeholder_text">Nothing to see here! You don't have any case, create a case and it will show up on this list.</p>
                                                </v-card>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div v-if="openCases.length > 0">
                                                <div class="py-0 my-1" cols="12" v-for="(item, i) in openCases" :key="i">
                                                    <div class="attorney_card d-flex justify-space-between">
                                                        <div class="attorney_card-box d-flex align-center me-auto">
                                                            <v-avatar size="34" color="#DCE8F8" class="me-4">
                                                                <v-icon color="#C3D6EF" dark>
                                                                mdi-briefcase
                                                                </v-icon>
                                                            </v-avatar>
                                                            <span>
                                                                <span>{{item.case_name}}</span>
                                                                <div class="d-flex d-sm-flex d-md-none">
                                                                    <div v-show="item.status == 'open'" class="subtext attorney_list-text"><span></span>{{item.potential_attorneys_id.length}} Prospective Attorney<span v-show="item.potential_attorneys_id.length > 1">s</span></div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="d-none d-sm-none d-md-flex attorney_card-box d-flex align-center">
                                                            <span v-show="item.status == 'open'" class="me-4 attorney_list-text">{{item.potential_attorneys_id.length}} Prospective Attorney<span v-show="item.potential_attorneys_id.length > 1">s</span></span>
                                                        </div>
                                                        <v-btn
                                                        icon
                                                        color="#023E7D"
                                                        @click="openCase(item)"
                                                        >
                                                        <v-icon>mdi-chevron-right</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="text-center d-flex justify-center">
                                                <v-card flat width="400">
                                                    <v-icon
                                                    size="100"
                                                    color="#D4D4D4"
                                                    >
                                                    mdi-package-variant
                                                    </v-icon>
                                                    <p class="placeholder_text">Nothing to see here! You don't have any open case, create a case it will show up on this list.</p>
                                                </v-card>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div v-if="matchedCases.length > 0">
                                                <div class="py-0 my-1" cols="12" v-for="(item, i) in matchedCases" :key="i">
                                                    <div class="attorney_card d-flex justify-space-between">
                                                        <div class="attorney_card-box d-flex align-center me-auto">
                                                            <v-avatar size="34" color="#DCE8F8" class="me-4">
                                                                <v-icon color="#C3D6EF" dark>
                                                                mdi-briefcase
                                                                </v-icon>
                                                            </v-avatar>
                                                            <span>
                                                                <span>{{item.case_name}}</span>
                                                                <div class="d-flex d-sm-flex d-md-none">
                                                                    <div class="subtext attorney_list-text">
                                                                        <!-- {{item.first_attorney}} + -->
                                                                        {{item.match_requests.length}} Attorney<span v-show="item.match_requests.length > 1">s</span> Matched</div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="d-none d-sm-none d-md-flex attorney_card-box d-flex align-center">
                                                            <span class="me-4 attorney_list-text">{{item.match_requests.length}} Attorney<span v-show="item.match_requests.length > 1">s</span> Matched</span>
                                                        </div>
                                                        <v-btn
                                                        icon
                                                        color="#023E7D"
                                                        @click="selectMatchRetain(item)"
                                                        >
                                                        <v-icon>mdi-chevron-right</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="text-center d-flex justify-center">
                                                <v-card flat width="400">
                                                    <v-icon
                                                    size="100"
                                                    color="#D4D4D4"
                                                    >
                                                    mdi-package-variant
                                                    </v-icon>
                                                    <p class="placeholder_text">Nothing to see here! You don't have any matched case, if you match with a case it will show up on this list.</p>
                                                </v-card>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div v-if="retainedCases.length > 0">
                                                <div class="py-0 my-1" cols="12" v-for="(item, i) in retainedCases" :key="i">
                                                    <div class="attorney_card d-flex justify-space-between">
                                                        <div class="attorney_card-box d-flex align-center me-auto">
                                                            <v-avatar size="34" color="#DCE8F8" class="me-4">
                                                                <v-icon color="#C3D6EF" dark>
                                                                mdi-briefcase
                                                                </v-icon>
                                                            </v-avatar>
                                                            <span>
                                                                <span>{{item.case_name}}</span>
                                                                <div class="d-flex d-sm-flex d-md-none">
                                                                    <div class="subtext attorney_list-text">Retained</div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="d-none d-sm-none d-md-flex attorney_card-box d-flex align-center">
                                                            <span class="me-4 attorney_list-text">Retained</span>
                                                        </div>
                                                        <v-btn
                                                        icon
                                                        color="#023E7D"
                                                        @click="selectMatchRetain(item)"
                                                        >
                                                        <v-icon>mdi-chevron-right</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="text-center d-flex justify-center">
                                                <v-card flat width="400">
                                                    <v-icon
                                                    size="100"
                                                    color="#D4D4D4"
                                                    >
                                                    mdi-package-variant
                                                    </v-icon>
                                                    <p class="placeholder_text">Nothing to see here! You don't have any retained case, if you retain a case it will show up on this list.</p>
                                                </v-card>
                                            </div>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-responsive>
                            </v-responsive>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="right_sidebar">
                    <ApptCard />
                    <ProgCard />
                </v-col>
                
            </v-row>
        </v-container>

        <!-- prospective dialog -->
        <v-dialog
        v-model="prospectiveDialog"
        max-width="600"
        >
        <v-card>
            <v-card-title class="card_title font-weight-bold">
            Your case prospective attorneys.
            </v-card-title>
            <v-card-text class="pt-4">
                <v-row>
                    <v-col cols="12">
                        <v-skeleton-loader
                        v-if="prospLoader"
                        class="skeleton_card"
                        type="image, list-item-two-line, actions"
                        ></v-skeleton-loader>
                        <v-carousel v-else hide-delimiters show-arrows-on-hover v-model="attorneySlide">
                            <v-carousel-item
                            v-for="(item, i) in potentialAttorneysList"
                            :key="i"
                            >
                            <v-sheet
                                color="white"
                                height="100%"
                                tile
                            >
                                <v-row
                                class="fill-height"
                                align="center"
                                justify="center"
                                >
                                <v-card
                                    class="mx-auto"
                                    max-width="400"
                                    flat
                                >
                                    <v-img
                                    class="white--text align-end"
                                    height="250px"
                                    :src="imgPlaceholder"
                                    >
                                    <div class="py-4 d-flex justify-end">
                                        <!-- <span v-show="item.attorney.is_verified === true" class="verified_tag">
                                            <v-icon
                                            small
                                            color="white"
                                            class="me-1"
                                            >
                                            mdi-octagram
                                            </v-icon>
                                            <span class="verified_tag-text">verified</span>
                                        </span> -->
                                    </div>
                                    </v-img>

                                    <v-card-text>
                                    
                                    <div class="d-flex justify-space-between align-center">
                                        <h4 class="mt-3 mb-2 profile_title">{{item.first_name}} {{item.last_name}}</h4>
                                        <div class="d-flex justify-end">
                                            <v-btn
                                            icon
                                            color="#023E7D"
                                            @click="viewMore(item)"
                                            >
                                            <v-icon>mdi-information-outline</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <p class="profile_subtitle">
                                        <span
                                        v-for="(item, i) in item.practice_area"
                                        :key="i"
                                        >
                                            {{item}} .
                                        </span>
                                    </p>
                                    
                                    <div class="mt-4 d-flex justify-start align-center">
                                        <div class="d-flex align-center">
                                            <v-icon class="me-2 profile_icon" color="#7E889A">
                                                mdi-briefcase-check-outline
                                            </v-icon>
                                            <span class="me-3 profile_icon-text">0</span>
                                        </div>
                                        <div class="d-flex align-center">
                                            <v-icon class="me-2 profile_icon" color="#7E889A">
                                                mdi-briefcase-clock-outline
                                            </v-icon>
                                            <span class="me-3 profile_icon-text">0</span>
                                        </div>
                                        <div class="d-flex align-center">
                                            <v-icon class="me-2 profile_icon" color="#7E889A">
                                                mdi-briefcase-remove-outline
                                            </v-icon>
                                            <span class="me-3 profile_icon-text">0</span>
                                        </div>
                                        <div class="me-2 line"></div>
                                        <div class="d-flex align-center">
                                            <v-icon class="me-2 profile_icon" color="#7E889A">
                                                mdi-star-outline
                                            </v-icon>
                                            <span class="me-3 profile_icon-text">0</span>
                                        </div>
                                    </div>
                                    </v-card-text>
                                    <v-card-actions class="pa-4">
                                    <div>
                                        <div class="mb-1 d-flex justify-start align-center">
                                            <div v-show="item.geocoder.road_travel_time_hours != 'you may need to swim....'" class="d-flex align-center">
                                                <v-icon class="me-2 profile_icon" color="#7E889A">
                                                    mdi-car
                                                </v-icon>
                                                <span v-if="item.geocoder.road_travel_time_hours != 0" class="me-3 profile_icon-text">{{ item.geocoder.road_travel_time_hours }}hrs</span>
                                                <span v-else class="me-3 profile_icon-text">{{ item.geocoder.road_travel_time_minutes }}mins</span>
                                            </div>
                                            <div class="d-flex align-center">
                                                <v-icon class="me-2 profile_icon" color="#7E889A">
                                                    mdi-airplane
                                                </v-icon>
                                                <span v-if="item.geocoder.flight_travel_time_hours != 0" class="me-3 profile_icon-text">{{ item.geocoder.flight_travel_time_hours }}hrs</span>
                                                <span v-else class="me-3 profile_icon-text">{{ item.geocoder.flight_travel_time_minutes }}mins</span>
                                            </div>
                                        </div>
                                        <div>Distance away</div>
                                        <span class="profile_icon-text">{{ item.geocoder.distance }} miles</span>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <span v-if="item.has_received_request === true">
                                        <v-chip
                                        outlined
                                        pill
                                        >
                                        Request Sent
                                        </v-chip>
                                    </span>
                                    <span v-else>
                                        <v-btn
                                            v-if="item.has_sent_request === true"
                                            color="#29bf12"
                                            dark
                                            depressed
                                            :loading="loading"
                                            @click="sendMatch(item)"
                                        >
                                            Click to match
                                        </v-btn>
                                        <v-btn
                                            v-else
                                            color="#023E7D"
                                            dark
                                            depressed
                                            :loading="loading"
                                            @click="sendRequest(item)"
                                        >
                                            Send request
                                        </v-btn>
                                        <v-btn
                                            color="#023E7D"
                                            dark
                                            depressed
                                            @click="wscall"
                                        >
                                            Send ws
                                        </v-btn>
                                    </span>
                                    </v-card-actions>
                                </v-card>
                                </v-row>
                            </v-sheet>
                            
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                </v-row>
                
                
            </v-card-text>
            <v-card-actions>
            
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- matched and retained dialog -->
        <v-dialog
        v-model="viewCaseDialog"
        max-width="700"
        >
        <v-card>
            <v-card-title class="card_title font-weight-bold">
            <span
            class="text-capitalize"
            >
            {{ selectedCase.case_type }}
            </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-6">
                <div class="pb-4">
                    <v-chip
                        class="mr-2"
                        color="#DCE8F8"
                    >
                        {{selectedCase.why_need_attorney}}
                    </v-chip>
                </div>
                <v-row>
                    <v-col lg="4">
                        <h5>Case Name</h5>
                        <div class="case_text text-capitalize">
                            {{selectedCase.case_name}}
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Practice Area</h5>
                        <div class="case_text text-capitalize">
                            <span
                            v-for="(item, i) in selectedCase.case_practice_area"
                            :key="i"
                            >{{item}}, </span>
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Date of incident</h5>
                        <div class="case_text">{{selectedCase.date_of_incident | formatDate}}</div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Location of incident</h5>
                        <div class="case_text">
                            {{selectedCase.location_incident}}
                        </div>
                    </v-col>
                    <v-col lg="8">
                        <h5>Case Details</h5>
                        <div class="case_text">
                            {{selectedCase.case_description}}
                        </div>
                    </v-col>
                    
                </v-row>
                <div class="progressTab">
                    <v-chip
                        class="mr-2"
                        color="#FFFFFF"
                        text-color="grey darken-3"
                        @click="tabProgress = 0"
                    >
                        <v-icon left size="15">mdi-help-circle-outline</v-icon>
                        View suggested workflow
                    </v-chip>
                </div>
                <v-responsive
                    class="overflow-y-auto px-4"
                    max-height="600"
                    >
                        <v-responsive>
                            <v-tabs-items v-model="tabProgress">
                                <v-tab-item>
                                    <p class="mb-8 mt-4">Dive into your case with confidence, learn about the step-by-step process below to understand every aspect.</p>
                                    <v-row>
                                        <v-col lg="12">
                                            <v-timeline
                                            align-top
                                            dense
                                            >
                                                <v-timeline-item
                                                v-for="(item, i) in defaultSteps"
                                                :key="i"
                                                color="#023E7D"
                                                fill-dot
                                                small
                                                >
                                                <template v-slot:icon>
                                                    <span class="timeline_text">
                                                        <b>{{ item.step }}</b>
                                                    </span>
                                                </template>
                                                <div class="py-4">
                                                    <h3>
                                                    {{item.title}}
                                                    </h3>
                                                    <div>
                                                    {{item.description}}
                                                    </div>
                                                </div>
                                                </v-timeline-item>
                                            </v-timeline>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-responsive>
                </v-responsive>
            </v-card-text>
            <v-card-actions>
            
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- matched and retained dialog -->
        <v-dialog
        v-model="matchretainDialog"
        max-width="700"
        >
        <v-card>
            <v-card-title class="card_title font-weight-bold d-flex justify-space-between align-center">
            <span
            class="text-capitalize"
            >
            {{ matchretainInfo.case_type }}
            </span>
            <v-badge
                v-show="potentialAttorneysList.length > 0 && matchretainInfo.status === 'matched'"
                bordered
                color="error"
                :content="potentialAttorneysList.length"
                overlap
            >
                <v-btn
                color="#023E7D"
                small
                text
                @click="openCase(matchretainInfo)"
                >
                Prospective Attorneys
                </v-btn>
            </v-badge>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-6">
                <div class="pb-4">
                    <v-chip
                        class="mr-2"
                        color="#DCE8F8"
                    >
                        {{matchretainInfo.why_need_attorney}}
                    </v-chip>
                    <v-chip
                        class="mr-2"
                        color="#DCE8F8"
                    >
                        Case Status - {{matchretainInfo.case_progress_state}}
                    </v-chip>
                </div>
                <v-row>
                    <v-col lg="4">
                        <h5>Case Name</h5>
                        <div class="case_text text-capitalize">
                            {{matchretainInfo.case_name}}
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Practice Area</h5>
                        <div class="case_text text-capitalize">
                            <span
                            v-for="(item, i) in matchretainInfo.case_practice_area"
                            :key="i"
                            >{{item}}, </span>
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Date of incident</h5>
                        <div class="case_text">{{matchretainInfo.date_of_incident | formatDate}}</div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Location of incident</h5>
                        <div class="case_text">
                            {{matchretainInfo.location_incident}}
                        </div>
                    </v-col>
                    <v-col lg="8">
                        <h5>Case Details</h5>
                        <div class="case_text">
                            {{matchretainInfo.case_description}}
                        </div>
                    </v-col>
                    
                </v-row>
                <div class="pt-8" v-show="matchretainInfo.status === 'retained'">
                    <h5 class="topic">RETAINED ATTORNEY</h5>
                    <v-skeleton-loader
                    v-if="matchRetainLoader === true"
                    type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                    <v-list v-else>
                        <v-list-item 
                        v-for="(item, i) in retainAttorneysList"
                        :key="i"
                        class="px-0" 
                        >
                            <v-list-item-content class="py-0">
                                <div class="attorney_card d-flex justify-space-between align-center">
                                    <div class="attorney_card-box d-flex align-center">
                                        <v-avatar rounded size="45" class="me-4">
                                            <img
                                                :src="imgPlaceholder"
                                                alt="profile image"
                                            >
                                        </v-avatar>
                                        <div>
                                            <h4 class="attorney_list-title">{{item.attorney.first_name}} {{item.attorney.last_name}}</h4>
                                            <span class="attorney_list-subtitle">
                                                <span
                                                class="text-capitalize"
                                                v-for="(item, i) in item.attorney_profile.attorney_type"
                                                :key="i"
                                                >{{item}}, </span>
                                            </span>
                                        </div>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="#023E7D"
                                        >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item link @click="selectAttorneyAppointment(item)">
                                        <v-list-item-title>Set Appointment</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link @click="progressDialog = true">
                                        <v-list-item-title>View Case Progress</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link @click="dismissAttorney(item)">
                                        <v-list-item-title>Dismiss Attorney</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
                <div class="pt-8" v-show="matchretainInfo.status === 'matched'">
                    <h5 class="topic">MATCHED ATTORNEY</h5>
                    <v-skeleton-loader
                    v-if="matchRetainLoader === true"
                    type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                    <v-list v-else>
                        <v-list-item 
                        v-for="(item, i) in matchAttorneysList"
                        :key="i"
                        class="px-0" 
                        >
                            <v-list-item-content class="py-0">
                                <div class="attorney_card d-flex justify-space-between align-center">
                                    <div class="attorney_card-box d-flex align-center">
                                        <v-avatar rounded size="45" class="me-4">
                                            <img
                                                :src="imgPlaceholder"
                                                alt="profile image"
                                            >
                                        </v-avatar>
                                        <div>
                                            <h4 class="attorney_list-title">{{item.attorney.first_name}} {{item.attorney.last_name}}</h4>
                                            <span class="attorney_list-subtitle">
                                                <span
                                                v-for="(item, i) in item.attorney_profile.attorney_type"
                                                :key="i"
                                                >{{item}}, </span>
                                            </span>
                                        </div>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="#023E7D"
                                        >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item link @click="selectAttorneyAppointment(item)">
                                        <v-list-item-title>Set Appointment</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link @click="selectRetainAttorney(item)">
                                        <v-list-item-title>Retain Attorney</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                                    
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </v-card-text>
            <v-card-actions>
            
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- more info dialog -->
        <v-dialog
        v-model="moreInfo"
        max-width="700"
        >
        <v-card>
            <v-card-title class="card_title font-weight-bold">
            Attorney Information
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-8">
                <v-row>
                    <v-col lg="6">
                        <h5>Payment Rate</h5>
                        <div>
                            <ul class="profile_list">
                                <li class="profile_list-text">Contigency ${{attorneyMoreInfo.contigency}}/hr</li>
                                <li class="profile_list-text">Fixed Rate ${{attorneyMoreInfo.fixed_rate}}</li>
                                <li class="profile_list-text">Hourly Rate {{attorneyMoreInfo.hourly_rate}}%</li>
                            </ul>
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Practice Area</h5>
                        <div>
                            <ul class="profile_list">
                                <li 
                                v-for="(item, i) in attorneyMoreInfo.practice_area"
                                :key="i"
                                class="profile_list-text"
                                >
                                {{item}}
                                </li>
                            </ul>
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Contact</h5>
                        <div>
                            <ul class="profile_list">
                                <li class="profile_list-text">{{attorneyMoreInfo.phone}}</li>
                                <li class="profile_list-text">{{attorneyMoreInfo.state}}, {{attorneyMoreInfo.country}}</li>
                                <li class="profile_list-text">{{attorneyMoreInfo.website}}</li>
                            </ul>
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Payment method</h5>
                        <div class="profile_text">
                            <v-chip
                                v-for="(item, i) in attorneyMoreInfo.payment_method"
                                :key="i"
                                class="mt-2 mb-2 mr-2 text-capitalize"
                                small
                                color="#DCE8F8"
                            >
                                {{item}}
                            </v-chip>
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Licensed States</h5>
                        <div
                        v-for="(item, i) in attorneyMoreInfo.licensed_areas"
                        :key="i"
                        >
                            <span class="profile_text">{{item.state}}</span>
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>About</h5>
                        <div class="profile_text">{{attorneyMoreInfo.about}}</div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
            
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- book appointments -->
        <v-dialog
        v-model="bookDialog"
        max-width="650"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Book Appointment
                </v-card-title>
            </div>
            <v-card-text class="mt-8">
                <div class="mb-2 attorney_card-box d-flex align-center">
                    <v-avatar rounded size="45" class="me-4">
                        <img
                            :src="imgPlaceholder"
                            alt="profile"
                        >
                    </v-avatar>
                    <div>
                        <h4 class="attorney_list-title">{{appointAttorney.first_name}} {{appointAttorney.last_name}}</h4>
                        <span class="attorney_list-subtitle">
                            <span
                            v-for="(item, i) in appointAttorney.attorney_type"
                            :key="i"
                            >{{item}}, </span>
                        </span>
                    </div>
                </div>
                <p class="mb-8 mt-4">Please pick any of the available days on the calender, note the days set by the attorney.</p>
                <v-row>
                    <v-col lg="6">
                        <v-date-picker
                        v-if="allowedLoader"
                        color="#023E7D"
                        v-model="availableDate"
                        :allowed-dates="allowedDates"
                        :to-allowed-dates="(val) => toAllowedDates(val, index)"
                        :picker-date.sync="pickerDateNext"
                        full-width
                        :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        @change="getAvailableTimes"
                        ></v-date-picker>
                        <v-card
                        v-else
                        flat
                        height="300"
                        class="d-flex justify-center align-center"
                        >
                            <v-progress-circular
                            indeterminate
                            color="primary"
                            ></v-progress-circular>
                        </v-card>
                    </v-col>
                    <v-col lg="6">
                        <h4>Pick a time</h4>
                        <span>Set time(s) for the selected date(s) on the calendar</span>
                        <div class="py-2">
                            <v-select
                            v-model="selectedTime"
                            :items="availableTimes"
                            :item-text="item => item.from+' - '+ item.to"
                            filled
                            label="Available Times"
                            return-object
                            dense
                            :loading="loading"
                            :disabled="availableDate != '' ? false : true"
                            class="some-style"
                            ></v-select>
                        </div>
                        <div class="text-end">
                            <v-btn
                            color="#023E7D"
                            class="stepper_btn"
                            depressed
                            :loading="bookAppointmentloader"
                            @click="bookAppointment"
                            >
                            Book Appointment
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- case progress -->
        <v-dialog
        v-model="progressDialog"
        max-width="750"
        >
        <v-card>
            <v-card-title class="card_title font-weight-bold">
            Case Progress
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-8 pb-4">
                <div class="mb-2 attorney_card-box d-flex align-center">
                    <v-avatar rounded size="45" class="me-4">
                        <img
                            :src="imgPlaceholder"
                            alt="profile"
                        >
                    </v-avatar>
                    <div
                    v-for="(item, i) in retainAttorneysList"
                    :key="i"
                    >
                        <h4 class="attorney_list-title">{{ item.attorney.first_name }} {{ item.attorney.last_name }}</h4>
                        <span class="attorney_list-subtitle">
                            <span
                            v-for="(item, i) in item.attorney_profile.attorney_type"
                            :key="i"
                            >{{item}}, </span>
                        </span>
                    </div>
                </div>

                <div class="progressTab">
                    <v-chip
                        class="mr-2"
                        color="#FFFFFF"
                        text-color="grey darken-3"
                        @click="tabProgress = 0"
                    >
                        <v-icon left size="15">mdi-help-circle-outline</v-icon>
                        View suggested workflow
                    </v-chip>
                    <v-chip
                        class="mr-2"
                        :color="tabProgress === 1 ? '#099FB2' : '#B5E2E8'"
                        :text-color="tabProgress === 1 ? 'white' : 'grey darken-2'"
                        @click="tabProgress = 1"
                    >
                        Progress tracking
                    </v-chip>
                </div>
                <v-responsive
                    class="overflow-y-auto px-4"
                    max-height="600"
                    >
                        <v-responsive>
                            <v-tabs-items v-model="tabProgress">
                                <v-tab-item>
                                    <p class="mb-8 mt-4">Please pick any of the available days on the calender, note the days set by the attorney.</p>
                                    <v-row>
                                        <v-col lg="12">
                                            <v-timeline
                                            align-top
                                            dense
                                            >
                                                <v-timeline-item
                                                v-for="(item, i) in defaultSteps"
                                                :key="i"
                                                color="#023E7D"
                                                fill-dot
                                                small
                                                >
                                                <template v-slot:icon>
                                                    <span class="timeline_text">
                                                        <b>{{ item.step }}</b>
                                                    </span>
                                                </template>
                                                <div class="py-4">
                                                    <h3>
                                                    {{item.title}}
                                                    </h3>
                                                    <div>
                                                    {{item.description}}
                                                    </div>
                                                </div>
                                                </v-timeline-item>
                                            </v-timeline>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <p class="mb-8 mt-4">Please pick any of the available days on the calender, note the days set by the attorney.</p>
                                    <v-row>
                                        <v-col lg="12">
                                            <v-timeline v-if="'case_progress' in matchretainInfo">
                                                <v-timeline-item
                                                v-for="(item, i) in matchretainInfo.case_progress"
                                                :key="i"
                                                :icon="item.is_completed === true ? 'mdi-check' : 'mdi-dot'"
                                                color="#023E7D"
                                                small
                                                >
                                                <template v-slot:opposite>
                                                    <span>{{item.date_completed | formatDate}}</span>
                                                </template>
                                                <div class="py-4">
                                                    <v-chip
                                                    color="#023E7D"
                                                    class="mb-2"
                                                    dark
                                                    small
                                                    >
                                                        Step {{ item.step }}
                                                    </v-chip>
                                                    <h2 class="mb-4">
                                                    {{item.title}}
                                                    </h2>
                                                    <div class="mb-2">
                                                    {{item.notes}}
                                                    </div>
                                                </div>
                                                </v-timeline-item>
                                            </v-timeline>
                                            <div v-else class="text-center d-flex justify-center">
                                                <v-card flat width="400">
                                                    <v-icon
                                                    size="100"
                                                    color="#D4D4D4"
                                                    >
                                                    mdi-package-variant
                                                    </v-icon>
                                                    <p class="placeholder_text">Nothing to see here! There's no case progress, click the add progress button above to add progress.</p>
                                                </v-card>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-responsive>
                </v-responsive>
            </v-card-text>
        </v-card>
        </v-dialog>

        
        <v-dialog
        v-model="retainAttorneyOption"
        persistent
        max-width="340"
        >
        <v-card>
            <v-card-title class="font-weight-regular">
            Do you want to retain attorney for your case?
            </v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="#DCE8F8"
                light
                class="me-2 optionCloseBtn"
                elevation="0"
                @click="retainAttorneyOption = false"
            >
                <b>Cancel</b>
            </v-btn>
            <v-btn
                color="#053669"
                dark
                elevation="0"
                :loading="loading"
                @click="retainAttorney"
            >
                <b>Retain</b>
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- snackbar -->
        <v-snackbar
        v-model="snackbar"
        :color="bgColor"
        bottom
        right
        >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>



<script>
import ApptCard from '../../components/client/AppointmentCardClient.vue'
import ProgCard from '../../components/client/ProgressCardClient.vue'
import CreateCase from '../../components/client/NewCase.vue'
import Sidebar from '../../components/client/SidebarClient.vue'
import Navbar from '../../components/client/NavbarClient.vue'

import moment from 'moment'
import { eventBus } from '../../EventBus';

  export default {

    components: {
        ApptCard,
        ProgCard,
        CreateCase,
        Sidebar,
        Navbar
    },

    created: function () {
        
        eventBus.$on("clientMatchMessage", (data) => {
            this.updateClientRequest(data);
        });
    },

    data () {
        return {
            prospectiveDialog: false,
            matchretainDialog: false,
            retainAttorneyOption: false,
            loading: false,
            tab: 0,

            menu: false,
            selectedTime: {},
            daysAvailable: [],
            availability: [],
            date: '',
            availableDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

            snackbar: false,
            message: '',
            bgColor: '',

            attorneySlide: 0,
            colors: [
                'primary',
                'secondary',
                'yellow darken-2',
                'red',
                'orange',
            ],

            moreInfo: false,
            bookDialog: false,
            progressDialog: false,
            potentialAttorneysList: [],
            attorneyMoreInfo: {},
            matchretainInfo: {},
            matchAttorneysList: [],
            retainAttorneysList: [],
            appointAttorney: {},
            selectedRetainAttorney: {},
            matchRetainLoader: false,
            prospLoader: false,

            selectedCase: {},
            viewCaseDialog: false,
            defaultSteps: [],
            tabProgress: 1,
            pickerDateNext: null,
            availableTimes: [],
            bookAppointmentloader: false,
            allowedLoader: true,
            allowedDateList: [],
            imgPlaceholder: 'https://res.cloudinary.com/dzsshr0iz/image/upload/v1652961985/Ilerah/avatar-ph_pce6l3.jpg',
        }
    },

    watch: {
        async pickerDateNext (newval) {
            console.log(this.allowedDates)
            this.allowedLoader = false
            console.log('allowed dates start')
            let separatedItem = newval.split('-');
            let selectedYear = separatedItem[0]
            let selectedMonth = separatedItem[1]
            this.loading = true;
            await this.$store.dispatch('getBookableDates', {
                year: selectedYear,
                month: selectedMonth,
                attorneyId: this.appointAttorney.attorneyId
            })
            .then((response) => {
                this.loading = false
                this.allowedDateList = response.data.data
                console.log('allowed dates')
                this.allowedLoader = true
                this.getAvailableTimes()
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },
    },

    methods: {
        updateClientRequest(data) {
            console.log(data);
            this.getPotentialAttorneysById(this.selectedCase._id);
        },
        
        wscall() {
            // this.$ws.send(JSON.stringify({ type: "client_match_request", data: this.newMessage }));
            this.newMessage = "";
        },

        async getAvailableTimes() {
            let formattedDate = moment(this.availableDate, "YYYY-MM-DD").format("MM-DD-YYYY")
            this.loading = true;
            await this.$store.dispatch('getAvailableTimes', {
                date: formattedDate,
                attorneyId: this.appointAttorney.attorneyId
            })
            .then((response) => {
                this.loading = false
                this.availableTimes = response.data.data
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        // allowedDates: val => {
        //     let finalDate = ['2023-12-1', '2023-12-2', '2023-12-3', '2023-12-4', '2023-12-5', '2023-12-6'];
        //     // let finalDate = this.allowedDateList.map(date => moment(date, "MM-DD-YYYY").format("YYYY-MM-DD"));
        //     return finalDate.includes(val);
        // },

        // allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0,
        // allowedDates: val => parseInt(val.split('-')[2], 10) % 2 !== 0,
        // allowedDates: val => 

        allowedDates(val) {
            // console.log('allowedDates calendar');
            // console.log(val);
            const dDates = this.allowedDateList.map(date => moment(date, "MM-DD-YYYY").format("YYYY-MM-DD"));
            return dDates.includes(val) ? false : true
        },

        // allowedDates: val => {
        //     let finalDate = ['2023-12-1', '2023-12-2', '2023-12-3', '2023-12-4', '2023-12-5', '2023-12-6'];
        //     // let finalDate = this.allowedDateList.map(date => moment(date, "MM-DD-YYYY").format("YYYY-MM-DD"));
        //     return finalDate.indexOf(val) !== -1
        // },

        viewCase(item) {
            this.viewCaseDialog = true
            this.selectedCase = item

            this.$store.dispatch('getCaseById', item._id)
            .then((response) => {
                this.defaultSteps = response.data.data.default_steps
            })
            .catch(() => {

            })
        },

        dismissAttorney(item) {
            console.log(item);
            this.loading = true;
            this.$store.dispatch('dismissAttorney', item.case_id)
            .then((response) => {
                this.loading = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.bookDialog = false
                this.matchretainDialog = false
                this.$store.dispatch('getClientCases')
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        selectRetainAttorney(item) {
            console.log(item);
            this.selectedRetainAttorney = item;
            this.retainAttorneyOption = true
        },

        retainAttorney() {
            this.loading = true;
            this.$store.dispatch('retainAttorney', {
                attorney_id: this.selectedRetainAttorney.attorney._id,
                case_id: this.selectedRetainAttorney.case_id
            })
            .then((response) => {
                this.loading = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.bookDialog = false
                this.retainAttorneyOption = false
                this.matchretainDialog = false
                this.$store.dispatch('getClientCases')
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        bookAppointment() {
            this.bookAppointmentloader = true;
            this.$store.dispatch('bookAppointment', {
                date: new Date(this.availableDate),
                from: moment(this.selectedTime.from, ["h:mm A"]).format("HH:mm"),
                to: moment(this.selectedTime.to, ["h:mm A"]).format("HH:mm"),
                attorney_id: this.appointAttorney.attorneyId,
                case_id: this.matchretainInfo._id,
                status: 'booked'
            })
            .then((response) => {
                this.bookAppointmentloader = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.bookDialog = false
                this.$store.dispatch('getClientAppointments')
            })
            .catch((error) => {
                this.bookAppointmentloader = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        selectAttorneyAppointment(item) {
            this.appointAttorney = {
                attorneyId: item.attorney._id,
                first_name: item.attorney.first_name,
                last_name: item.attorney.last_name,
                attorney_type: item.attorney_profile.attorney_type
            }
            this.bookDialog = true
            console.log(item.attorney._id);
            this.$store.dispatch('getAttorneyAvailability', item.attorney._id)
            .then((response) => {
                console.log(response)
                console.log(response.data.data)
                this.availability = response.data.data
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        checkDay() {
            var date = new Date(this.date)
            var dayIndex = date.getDay()
            if(dayIndex == 0) {
                this.daysAvailable = this.availability.filter( i => 'Sunday'.includes( i.day ) )
            } else if(dayIndex == 1) {
                this.daysAvailable = this.availability.filter( i => 'Monday'.includes( i.day ) )
            } else if(dayIndex == 2) {
                this.daysAvailable = this.availability.filter( i => 'Tuesday'.includes( i.day ) )
            } else if(dayIndex == 3) {
                this.daysAvailable = this.availability.filter( i => 'Wednesday'.includes( i.day ) )
            } else if(dayIndex == 4) {
                this.daysAvailable = this.availability.filter( i => 'Thursday'.includes( i.day ) )
            } else if(dayIndex == 5) {
                this.daysAvailable = this.availability.filter( i => 'Friday'.includes( i.day ) )
            } else if(dayIndex == 6) {
                this.daysAvailable = this.availability.filter( i => 'Saturday'.includes( i.day ) )
            }
        },

        selectMatchRetain(item) {
            console.log(item)
            this.$store.dispatch('getCaseById', item._id)
            .then((response) => {
                this.defaultSteps = response.data.data.default_steps
                this.potentialAttorneysList = response.data.data.potential_attorneys
            })
            .catch(() => {

            })

            if(item.status == 'retained') {
                this.matchRetainLoader = true
                this.retainAttorneysList = []
                this.$store.dispatch('getAttorneyById', item.retained_attorney_id)
                .then((response) => {
                    this.retainAttorneysList.push({
                        attorney: response.data.attorney,
                        attorney_profile: response.data.attorney_profile,
                        case_id: item._id,
                        client_id: item.client_id
                    })
                    this.matchRetainLoader = false
                    
                })
                .catch(() => {

                })
            }
            if(item.status == 'matched') {
                this.matchRetainLoader = true
                this.matchAttorneysList = []
                for (let i = 0; i < item.matched_attorneys_id.length; i++) {
                    this.$store.dispatch('getAttorneyById', item.matched_attorneys_id[i])
                    .then((response) => {
                        this.matchAttorneysList.push({
                            attorney: response.data.attorney,
                            attorney_profile: response.data.attorney_profile,
                            case_id: item._id,
                            client_id: item.client_id
                        })
                        this.matchRetainLoader = false
                    })
                    .catch(() => {

                    })
                }
            }
            
            this.matchretainInfo = item
            this.matchretainDialog = true
            console.log(this.matchAttorneysList)
        },

        viewMore(item) {
            console.log(item)
            this.attorneyMoreInfo = {
                country: item.country,
                phone: item.phone,
                state: item.state,
                website: item.contact_info.website,
                about: item.contact_info.about,
                practice_area: item.practice_area,
                payment_method: item.payment_plan.payment_method,
                contigency: item.payment_plan.contigency,
                fixed_rate: item.payment_plan.fixed_rate,
                hourly_rate: item.payment_plan.hourly_rate,
                licensed_areas: item.licensed_areas
            }
            this.moreInfo = true;
        },

        openCase(item) {
            let _id = item._id
            this.getPotentialAttorneysById(_id)
            
            // this.prospLoader = true
            // for (let i = 0; i < item.potential_attorneys_id.length; i++) {
            //     this.$store.dispatch('getAttorneyById', item.potential_attorneys_id[i])
            //     .then((response) => {
            //         this.potentialAttorneysList.push({
            //             attorney: response.data.attorney,
            //             attorney_profile: response.data.attorney_profile,
            //             match_requests: item.match_requests,
            //             case_id: item._id,
            //             client_id: item.client_id
            //         })
            //         this.prospLoader = false
            //     })
            //     .catch(() => {

            //     })
            // }
            // this.prospectiveDialog = true
            // console.log(this.potentialAttorneysList)
        },

        getPotentialAttorneysById(item) {
            this.prospectiveDialog = true
            this.prospLoader = true
            this.$store.dispatch('getCaseById', item)
            .then((response) => {
                console.log(response.data.data.potential_attorneys)
                this.prospLoader = false
                this.potentialAttorneysList = response.data.data.potential_attorneys
                this.selectedCase = response.data.data.case
            })
            .catch((error) => {
                this.prospLoader = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        sendRequest(item) {
            this.loading = true
            this.$store.dispatch('sendClientRequest', {
            caseId: this.selectedCase._id,
            attorneyId: item.user_id
            })
            .then((response) => {
                this.loading = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.getPotentialAttorneysById(this.selectedCase._id)
                this.wscall()
            })
            .catch((error) => {
                this.loading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        sendMatch(item) {
            this.loading = true
            this.$store.dispatch('acceptAttorneyRequest', {
            caseId: this.selectedCase._id,
            attorneyId: item.user_id
            })
            .then((response) => {
                this.loading = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.getPotentialAttorneysById(this.selectedCase._id)
                // this.$store.dispatch('getCaseById', this.selectedCase._id)
                if(this.potentialAttorneysList.length == 0) {
                    this.prospectiveDialog = false
                }
                this.wscall()
            })
            .catch((error) => {
                this.loading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        }
    },

    computed: {
        allCase() {
            return this.$store.state.allCase;
        },

        openCount() {
            return this.$store.state.openCount;
        },

        matchedCount() {
            return this.$store.state.matchedCount;
        },

        retainedCount() {
            return this.$store.state.retainedCount;
        },

        openCases() {
            return this.$store.state.openCases;
        },

        matchedCases() {
            return this.$store.state.matchedCases;
        },

        retainedCases() {
            return this.$store.state.retainedCases;
        },
      
    }

  }
</script>

<style scoped>
    .topic {
        color: #023E7D;
    }

    .subtopic {
        color: #7E889A;
    }
    .figure_text {
        font-family: 'Arvo', sans-serif;
        font-size: 16px;
    }
    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }

    .card_figure-text {
        color: #023E7D;
    }

    .attorney_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }

    .attorney_card-box {
        height: 34px;
    }

    .subtext {
        font-size: 12px;
    }

    .attorney_list-text {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    .verified_tag {
        background-color: rgba(2, 48, 71, 0.6);
        padding: 5px 10px 5px 15px;
        border-radius: 25px 0px 0px 25px;
    }

    .verified_tag-text {
        font-weight: 600;
    }

    .profile_title {
        font-size: 25px;
        color: #023047;
    }

    .profile_icon {
        margin-top: -4px;
    }

    .profile_icon-text {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #023E7D;
    }

    .line {
        width: 1px;
        height: 25px;
        background-color: #C4C4C4;
    }

    .profile_list {
        list-style-type: none;
        padding-left: 0;
    }

    .profile_list-text {
        color: #2F2E41;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    .profile_text {
        color: #2F2E41;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    .attorney_list-title {
        color: #023047;
        margin-bottom: 4px;
    }

    .attorney_list-subtitle {
        font-size: 12px;
    }

    .topic {
        color: #023047;
    }

    .title_bg {
        background-color: #023E7D;
        color: #fff;
    }

    .stepper_btn {
        color: #fff;
    }
    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }
    .optionCloseBtn {
        color: #023E7D;
    }
    .placeholder_text {
        color: #A7A7A7;
    }
    .skeleton_card {
        max-width: 400px;
        margin: auto;
    }
    .closed {
        color: red;
    }
    .right_sidebar {
        border-left: 1px solid #e9ecef;
    }
    .progressTab {
        margin-top: 40px;
    }
    .timeline_text {
        color: #FFF;
    }
    .case_link {
        color: #000;
        font-weight: 400;
    }
</style>