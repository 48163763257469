<template>
  <div class="main_body pa-4 d-flex align-center justify-center">
      <div class="d-flex justify-center">
        <v-img class="brand_logo"
          :src="currentSrc"
        ></v-img>
        <!-- <div class="loader_box">
            <v-progress-linear
            indeterminate
            color="primary darken-2"
            striped
            ></v-progress-linear>
        </div> -->
      </div>
  </div>
</template>

<script>


export default {
    created: function () {
        this.loadingImg();
        this.$store.dispatch('getUser')
    },

    beforeDestroy() {
        clearInterval(this.interval)
    },

    components: {

    },

    data () {
        return {
        loadingText: false,
        interval: null,
        srcs: ['https://res.cloudinary.com/dwkagby2h/image/upload/v1675628319/general/down-logo_mibovw.png', 'https://res.cloudinary.com/dwkagby2h/image/upload/v1675628309/general/up-logo_qn9sc3.png'],
        currentSrc: 'https://res.cloudinary.com/dwkagby2h/image/upload/v1675628319/general/down-logo_mibovw.png'
        }
    },

    methods: {
        loadingImg() {
        let i = 0;
        this.interval = setInterval(() => {
            this.currentSrc = this.srcs[i];
            if(++i === this.srcs.length) i = 0;
        }, 300);
        }

    }

}
</script>

<style scoped>

.main_body {
    height: 100vh;
}

.brand_logo {
    width: 40px;
    margin-bottom: 20px;
}

.loader_box {
    width: 60px;
    margin-left: 5px;
}

</style>
